:root {
  --primary-color: #bc923f;
  --secondary-color: #757575;
  --dark-color: #1e1e1e;
  --black-color: #000;
  --stroke-color: #d9d9d9;
  --red-color: #ff2c48;
  --green-color: #046d15;
  --light-color: #f8f9fa;
  --linear-gradient: linear-gradient(#9f712d, #c8a25b) 40;
  --white-color: #fff;
  --page-color: #f1f1f1;
  --radius-btn: 0.313rem;
  --radius-border: 0.313rem;
  --z-index: 1;
  --z-index-header: 100;
  --z-index-popup: 1000;
  --primary-font: "Inter", sans-serif;
  --secondry-font: "Nunito Sans", sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-smedium: 600;
  --font-weight-bold: 700;
  --h1: 4.25rem;
  --h2: 3.5rem;
  --h3: 2.875rem;
  --h4: 2rem;
  --h5: 1.625rem;
  --h6: 1.125rem;
  --body30: 1.875rem;
  --body24: 1.5rem;
  --body20: 1.25rem;
  --body18: 1.125rem;
  --body16: 1rem;
  --body14: 0.875rem;
  --body12: 0.75rem;

}

@media (max-width: 1199px) {
  :root {
    --h1: 3.5rem;
    --h2: 2.5rem;
    --h3: 2rem;
    --h4: 1.5rem;
    --h5: 1.375rem;
    --h6: 1.125rem;
    --body20: 1.25rem;
    --body16: 0.875rem;
    --body18: 1rem;
    --body14: 0.875rem;
    --body12: 0.75rem;
  }
}

@media (max-width: 768px) {
  :root {
    --h1: 3rem;
    --h2: 2.25rem;
    --h3: 1.625rem;
    --h4: 1.5rem;
    --h5: 1.12rem;
    --h6: 1.125rem;
    --body20: 1rem;
    --body18: 1rem;
    --body16: 0.875rem;
    --body14: 0.875rem;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

a {
  text-decoration: none !important;
}

body {
  font-family: var(--primary-font) !important;
  /* background-color: #f1f1f1 !important; */
  letter-spacing: 0 !important;
  background-color: var(--page-color) !important;
  color: var(--black-color) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
  font-family: var(--secondry-font) !important;
}

p {
  margin-bottom: 0 !important;
}

.card {
  border: none !important;
}

.select {
  display: flex;
  gap: 1.25rem;
}
.form-select:focus,
.form-control:focus {
  outline: 0 !important;
  box-shadow: none !important;
  border: 1px solid var(--primary-color) !important;
}

.form-select {
  width: 100%;
}
.dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* img{
  max-width: 100%;
  width: 100%;
} */

/* placeholder */

::placeholder {
  /* color: var(--white-color) !important; */
  opacity: 1;
  font-size: var(--body16);
  line-height: 1.4;
}

::-ms-input-placeholder {
  color: var(--white-color);
}

/* scroollbar custom */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--stroke-color);
  border-radius: 8px;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 0.625rem;
  cursor: pointer;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: var(--page-color); 
} */


.navbar-toggler:focus {
  outline: none;
  box-shadow: none !important;
}

.fill_btn {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  padding: 0.75rem;
  border-radius: var(--radius-border);
  display: inline-block;
  border: 1px solid transparent !important;
  text-align: center;
}
.fill_btn1 {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  padding: 0.75rem;
  border-radius: var(--radius-border);
  border: 1px solid transparent !important;
  height: 39px;
  display: flex;
  align-items: center;
  gap: 0.313rem;
  text-align: center;
}

.btn_frame{
  border: none;
  padding: 0;
  margin: 0;
}
.fill_btn:hover{
  background-color: var(--white-color) !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.cancel_btn{
  background-color: var(--red-color);
  color: var(--white-color);
  padding: 0.625rem;
  border-radius: var(--radius-border);
  display: inline-block;
  text-align: center;
}

.outline_btn {
  background-color: var(--white-color) !important;
  border: 1px solid var(--primary-color) !important;
  padding: 0.75rem;
  border-radius: var(--radius-border);
  color: var(--primary-color) !important;
  text-align: center;
}
.outline_btn:hover{
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}


.nav-link:focus-visible {
  box-shadow: none !important;
}

/* calc popup overlay */
.drag_calc .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  display: none !important;
}

ul {
  list-style: none;
  /* padding: 0 !important; */
}

input[type="checkbox"] {
  padding: 0.313rem;
  /* width: 20px; */
  border: 1.5px solid var(--primary-color) !important;
  /* height: 20px; */
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: var(--primary-color);
}

.form-check-input:checked {
  background-color: var(--primary-color) !important;
}

/* checkbox parent class */
.check1 {
  display: flex;
  gap: 5px;
  color: var(--dark-color);
  align-items: center;
}

.accordion-button:focus,
.accordion-item:focus-visible {
  box-shadow: none !important;
}

.accordion-item {
  border: 1px solid var(--page-color) !important;
  margin-bottom: 1.875rem;
}

/* .form-check-input {
  background-color: var(--page-color) !important;
} */

/* select2 dropdown */

.select2-container .select2-selection--single {
  height: 46px !important;
  border-radius: var(--radius-border) !important;
  border: 1px solid var(--stroke-color) !important;
}

.select2-container .select2-selection--single:focus-visible {
  box-shadow: none;
  outline: 0;
}

.select2-container:focus-visible {
  outline: 1px solid transparent;
}

.select2-search__field {
  outline: 0;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 2.688 !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border: none !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b:after {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
  content: "";
  display: inline-block;
  height: 8px;
  left: -5px;
  position: absolute;
  top: 4px;
  width: 8px;
  transform: rotate(45deg);
  border-color: #8b8989;
}

span.select2-dropdown {
  border: 0;
  border-radius: var(--radius-border) !important;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  margin-top: 10px;
  /* width: 200px !important; */
}

.select2-container {
  width: 190px !important;
  /* border: 1px solid var(--stroke-color);  */
  border-radius: var(--radius-border);
  transition: all 0.5s ease-in-out;
}

.select2-container--open {
  /* border: 1px solid var(--primary-color); */
  border-radius: var(--radius-border);
}

/* react select */
.css-b62m3t-container {
  height: 36px !important;
  margin: 0 !important;
  padding: 0 !important;
}
.css-b62m3t-container:focus-visible {
  outline: 0;
}

.css-13cymwt-control {
  border-radius: var(--radius-border) !important;
  border: 1px solid var(--stroke-color) !important;
}

.css-13cymwt-control:hover {
  border: 1px solid var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 1px var(--primary-color) !important;
}

.css-t3ipsp-control:hover {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 1px var(--primary-color) !important;
}

input:hover {
  border: 1px solid var(--primary-color);
}

.css-qbdosj-Input:focus-visible {
  outline: 0;
}

/* 31-5-2024 */

.modal-header{
  justify-content: center;
}

.modal-footer{
  flex-wrap: nowrap !important;
}

/* 3-6-2024 */

.label {
  display: flex;
  /* margin-top: 1.875rem; */
  gap: 1px;
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
}

 .label_text {
  color: var(--black-color);
  font-size: var(--body16);
  line-height: 1.4;
}
 .mand_icon {
  color: var(--red-color);
  margin: auto 0;
  font-size: var(--body16);
  line-height: 1.4;
}

/* 5-6-2024 */

.offcanvas-header .btn-close:focus-visible, .offcanvas-header .btn-close:focus{
  outline: 0;
  box-shadow: none;
}

.offcanvas-header .btn-close:hover{
  color: var(--primary-color) !important;
}

.offcanvas.offcanvas-end{
  border-radius: 2rem 0 0 2rem !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.css-1u9des2-indicatorSeparator{
  width: 0 !important;
}

/* 6-6-2024 */

.css-qbdosj-Input{
  padding: 0 !important;
  margin: 0 !important;
}

.swiper-button-prev:after, .swiper-button-next:after{
  color: var(--white-color);
  font-size: var(--body16) !important;
}


/* 7-6-2024 */

/* toggle switch css */

.cl-toggle-switch {
  position: relative;
 }
 
 .tr_switch {
  position: relative;
  display: inline-block;
 }
 /* Input */
 .tr_switch > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  right: 6px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgb(0, 0, 0, 0.38);
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
 }
 /* Track */
 .tr_switch > span::before {
  content: "";
  float: right;
  display: inline-block;
  margin: 5px 0 2px 0px;
  border-radius: 7px;
  width: 36px;
  height: 14px;
  background-color: rgb(0, 0, 0, 0.38);
  vertical-align: top;
  transition: background-color 0.2s, opacity 0.2s;
 }
 /* Thumb */
 .tr_switch > span::after {
  content: "";
  position: absolute;
  top: 2px;
  right: 16px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, transform 0.2s;
 }
 /* Checked */
 .tr_switch > input:checked {
  right: -10px;
  background-color: var(--primary-color);
 }
 
 .tr_switch > input:checked + span::before {
  /* background-color: var(--primary-color); */
  background-color: #bc923f4f;
 }
 
 .tr_switch > input:checked + span::after {
  background-color: var(--primary-color);
  transform: translateX(16px);
 }
 /* Hover, Focus */
 .tr_switch:hover > input {
  opacity: 0.04;
 }
 
 .tr_switch > input:focus {
  opacity: 0.12;
 }
 
 .tr_switch:hover > input:focus {
  opacity: 0.16;
 }
 /* Active */
 .tr_switch > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
 }
 
 .tr_switch > input:active + span::before {
  background-color: #8f8f8f;
 }
 
 .tr_switch > input:checked:active + span::before {
  background-color: var(--white-color);
 }
 /* Disabled */
 .tr_switch > input:disabled {
  opacity: 0;
 }
 
 .tr_switch > input:disabled + span::before {
  background-color: #ddd;
 }
 
 .tr_switch > input:checked:disabled + span::before {
  background-color: #bfdbda;
 }
 
 .tr_switch > input:checked:disabled + span::after {
  background-color: var(--primary-color);
 }

 /* end toggle switch */

 /* date picker */

.buyRequest_type_item .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 0.45rem 0.75rem !important;
  background-color: var(--white-color) !important;
 }

.buyRequest_type_item .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input:hover{
  border: 1px solid transparent !important;
 }

.buyRequest_type_item .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  top: -8px !important;
 }


 /* 10-6-2024 */

 .css-z3c6am-MuiFormControl-root-MuiTextField-root:focus-visible{
  outline: o;
 }

 .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:focus-visible{
  outline: 0;
 }
 .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color: var(--primary-color) !important;
 }


 /* 18-6-2024 */

 .css-de9k3v-MuiDataGrid-selectedRowCount{
  display: none !important; 
 }

 .css-1bqmvoj{
  width: 100% !important;
 }

 /* 19-6-2024 */

 .form-check-input{
  margin-top: 0 !important;
 }

 /* 21-6-2024 */

 
 .css-u7lbac-MuiDataGrid-root .MuiDataGrid-filler{
  height: 1px !important;
 }

 .css-wjh20t-MuiPagination-ul{
  flex-wrap: nowrap !important;
 }

 @media (max-width: 768px) {
  .css-1bqmvoj {
    align-items: end !important;
  }
}

/* 24-6-2024 */

.css-1jqq78o-placeholder{
  white-space: nowrap;
}

/* 25-6-2024 */
.css-10he2sy-MuiDataGrid-root .MuiDataGrid-row.Mui-selected, .css-u7lbac-MuiDataGrid-root .MuiDataGrid-row.Mui-selected77, .css-u7lbac-MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: rgba(188, 146, 63, 0.052) !important;
}


.css-u7lbac-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-u7lbac-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.btn:focus-visible{
  box-shadow: none !important;
}

/* 2-7-2024 */

.accordion-button:not(.collapsed){
  background-color: rgba(188, 146, 63, 0.2) !important;
  color: var(--primary-color) !important;
}

.MuiDataGrid-cell:focus-within {
  outline: none !important;
}
.MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within {
  outline: none !important;
}
.MuiDataGrid-cell:focus{
  outline: none !important;
}
.MuiDataGrid-columnHeader:focus{
  outline: none !important;
}
.MuiDataGrid-columnHeader:focus-within{
  outline: none !important;
}
.bgPrimary{
  background-color: #BC923F !important;
  color: white;
}

/* 9-7-2024 */

select option{
  border: 0;
  color: var(--black-color);
  background: transparent;
  font-size: var(--body14);
  padding: 0.125rem 0.625rem;
  background: rgba(188, 146, 63, 0.3)!important;
  -webkit-appearance: none;
}

select:focus > option:checked { 
  background: var(--primary-color) !important;
  color: var(--white-color);
}

/* .btn:first-child:active{
  background-color: var(--red-color) !important;
} */

@media (max-width: 450px) {
  .modal-footer {
    flex-direction: column;
  }
}

.navbar{
  background-color: var(--white-color) !important;
}

.drag_calc .css-uhb5lp {
  border-radius: 0.75rem !important;
}

.drag_calc .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  border-radius: 0.75rem !important;
}

.drag_calc .css-ypiqx9-MuiDialogContent-root{
  padding: 1.25rem !important;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  color: var(--black-color);
}

.css-7bz52g-MuiDataGrid-root .MuiDataGrid-cell {
  color: var(--black-color);
}


.css-7bz52g-MuiDataGrid-root .MuiOutlinedInput-input:focus-visible{
  outline: none;
}

.css-3sis8q-MuiTypography-root{
  color: var(--black-color);
}

.form-control:disabled{
  background-color: transparent !important;
}

