


.form_sec {
  background-image: url(../images/bg_pattern.png);
  background-repeat: repeat;
  /* background-position: left top; */
  background-size: auto;
  height: 100%;
  padding: 1.875rem;
  position: relative;
  min-height: 100vh;
}

.form_sec::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(127deg,
      rgba(17, 64, 24, 1) 0%,
      rgba(0, 124, 20, 1) 47%,
      rgba(64, 64, 64, 1) 100%);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.form_sec .login_wrap {
  /* display: flex; */
  max-width: 551px;
  flex-direction: column;
  align-items: center;
  /* padding: 0 1.25rem; */
  margin: 0 auto;
  width: 100%;
}

.logo_wrap {
  display: flex;
  justify-content: center;
}

.form_sec .img {
  object-fit: auto;
  object-position: center;
  max-width: 100%;
  height: auto;
  /* width: 400px; */
}

.form_wrap {
  background: linear-gradient(#9f712d, #c8a25b);
  padding: 0.313rem;
  border-radius: 2.5rem;
  margin-top: 1.12rem;
}

.form_sec .form {
  justify-content: center;
  border-radius: 2.5rem;
  box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.2);
  background-color: var(--white-color);
  /* align-self: stretch; */
  display: flex;
  width: 100%;
  flex-direction: column;
  font-weight: var(--font-weight-regular);
  padding: 1.813rem 2.375rem;
}

.form_sec .title {
  color: var(--primary-color);
  text-align: center;
  font-size: var(--h4);
  white-space: nowrap;
  line-height: 1.4;
  font-weight: var(--font-weight-bold);
  /* margin-bottom: 1.875rem; */
}

.form_sec .det {
  color: var(--dark-color);
  text-align: center;
  font-size: var(--body18);
  margin-bottom: 1.875rem;
  line-height: 1.5;
  font-weight: var(--font-weight-regular);
  /* padding: 1.5rem; */
}

.label {
  display: flex;
  /* margin-top: 1.875rem; */
  gap: 1px;
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
}

.form_sec .label_text,
.form_sec .mand_icon {
  color: var(--black-color);
  font-size: var(--body16);
  line-height: 1.5;
}

.form_sec .mand_icon {
  color: var(--red-color);
  margin: auto 0;
}

.form_sec .inputs input,
.form_sec .form-select {
  justify-content: space-between;
  border-radius: var(--radius-border);
  box-shadow: 0px 1px 2px 0px rgba(30, 30, 30, 0.05);
  border: 1px solid var(--stroke-color) !important;
  background-color: var(--white-color);
  display: flex;
  margin-top: 0.375rem;
  gap: 0px;
  font-size: var(--body16);
  color: var(--secondary-color);
  padding: 0.875rem 1rem !important;
  width: 100%;
  margin-top: 0 !important;
  outline: none;
  transition: all ease-in-out 0.5s;
}

.form_sec .inputs input:hover,
.form_sec .form-select:hover {
  border: 1px solid var(--primary-color) !important;
}

.form_sec .inputs input:focus {
  border: 1px solid var(--primary-color) !important;
}

.inputs {
  margin-top: 0.313rem;
  position: relative;
  margin-bottom: 1.875rem;
}

.form_sec .input_field {
  flex: 1;
  text-align: left;
  margin: auto 0;
}

.form_sec .img-2 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  position: absolute;
  top: 15px;
  right: 10px;
  /* z-index: 5555; */
}

.form_sec .img-3 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
}

.form_sec .reme_wrap {
  justify-content: space-between;
  display: flex;
  /* margin-top: 1.875rem; */
  width: 100%;
  gap: 1.25rem;
  font-size: var(--body18);
  line-height: 1.4;
}

.form_sec .check1 {
  display: flex;
  gap: 0.938rem;
  color: var(--dark-color);
  align-items: end;
}

.form_sec .check_text,
.form_sec .forgot_pass {
  margin: 0 0 0.125rem;
  font-size: var(--body18);
  line-height: 1.75;
}

.form_sec .forgot_pass {
  color: var(--primary-color);
  margin: auto 0;
}

.form_sec .login_btn {
  border-radius: var(--radius-border);
  background-color: var(--primary-color);
  margin-top: 1.875rem;
  color: var(--white-color);
  white-space: nowrap;
  padding: 1rem 2.125rem;
  text-align: center;
  border: 1px solid transparent;
  transition: all ease-in-out 0.5s;
}

.form_sec .login_btn:hover {
  border: 1px solid var(--primary-color);
  background-color: var(--white-color);
  color: var(--primary-color);
}

.form_sec span.indicator-label {
  font-size: var(--body18);
  line-height: 1.313;
  font-weight: var(--font-weight-regular);
}

.form_sec .creat_ac,
.form_sec .creat_ac span a {
  color: var(--dark-color);
  align-self: center;
  margin-top: 1.875rem;
  font-size: var(--body18);
  font-weight: var(--font-weight-regular);
  text-align: center;
}

.form_sec .creat_ac span a {
  color: var(--primary-color);
}

.form_sec input[type="checkbox"] {
  padding: 0.313rem;
  width: 20px;
  border: 1.5px solid var(--primary-color) !important;
  height: 20px;
}

.form_top_btn {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 30px;
  margin-bottom: 0.938rem;
}

.choose_btn {
  padding: 0.6rem 1.688rem;
  background-color: var(--primary-color);
  border-radius: var(--radius-border);
  color: var(--white-color);
  text-decoration: none;
  display: inline-block;
  border: 1px solid transparent;
  transition: all ease-in-out 0.5s;
}

.choose_btn:hover {
  background-color: var(--white-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.con_drop {
  display: flex !important;
  gap: 0.938rem;
  color: #000 !important;
  background-color: var(--white-color) !important;
  border-radius: var(--radius-border);
  padding: 0.313rem 0.625rem;
  max-width: 200px;
  width: 100%;
  border: none !important;
  justify-content: center;
  align-items: center;
}

.dropdown-toggle::after {
  display: none !important;
}

.form_sec .reg_wrap {
  /* display: flex; */
  max-width: 760px;
  flex-direction: column;
  align-items: center;
  /* padding: 0 1.25rem; */
  margin: 0 auto;
  width: 100%;
}

.pass_wrap {
  display: flex;
  gap: 1.875rem;
  justify-content: space-between;
}

.pass_wrap .pass {
  flex: 1;
}

.btn_wrap {
  display: flex;
  gap: 1.875rem;
}

.btn_wrap .c_btn {
  flex: 1;
}

.reset_btn {
  border-radius: var(--radius-border);
  border: 1px solid var(--primary-color) !important;
  margin-top: 1.875rem;
  color: var(--primary-color);
  white-space: nowrap;
  padding: 1rem 2.125rem;
  font-weight: var(--font-weight-medium);
  text-align: center;
  background-color: transparent;
  transition: all ease-in-out 0.5s;
  font-size: var(--body18);
}

.reset_btn:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.radio_wrap {
  display: flex;
  gap: 1.875rem;
  /* flex-wrap: wrap; */
}

.form_sec input[type="radio"]:checked+label,
.form_sec input[type="checkbox"]:checked+label {
  color: var(--primary-color) !important;
}

.form_sec input[type="radio"],
.form_sec input[type="checkbox"] {
  accent-color: var(--primary-color);
}

.form_sec .form-check-input:checked {
  background-color: var(--primary-color) !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

input[type="radio"] {
  border: 2px solid var(--primary-color) !important;
}

input[type="radio"]:checked {
  box-shadow: inset 0px 0px 0px 3px var(--white-color) !important;
  background: var(--primary-color) !important;
}

input[type="radio"]:checked+.label_custom {
  color: var(--primary-color) !important;
}

.radio_grp label {
  cursor: pointer;
  font-size: var(--body14);
  line-height: 1.4;
}

.form-check-input {
  width: 1.125rem !important;
  height: 1.125rem !important;
  cursor: pointer;
}

.com_wrap {
  /* display: flex; */
  max-width: 760px;
  flex-direction: column;
  align-items: center;
  /* padding: 0 1.25rem; */
  margin: 0 auto;
  width: 100%;
}

.com_wrap .title {
  margin-bottom: 1.875rem;
}

.input_wrap {
  display: flex;
  gap: 1.875rem;
}

.input_wrap .wrap {
  flex: 1;
}

.custom_Select {
  border: none !important;
  padding: 0 !important;
}

.custom_Select:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid var(--primary-color) !important;
}

.check_text a {
  color: var(--primary-color);
}

.for_wrap {
  /* display: flex; */
  max-width: 550px;
  flex-direction: column;
  align-items: center;
  /* padding: 0 1.25rem 6.063rem; */
  margin: 0 auto;
  width: 100%;
}

.for_Form {
  background-color: var(--white-color);
}

.reg_link {
  font-size: var(--body18);
  line-height: 1.75;
}

.reg_link span a {
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
}

.con_name {
  max-width: 150px;
  width: 100%;
  /* white-space: nowrap; */
  white-space: normal;
  font-size: var(--body14);
  line-height: 1.4;
}

.form_sec .dropdown-menu {
  transform: translate(0px, 45px) !important;
  max-width: 200px;
  width: 100%;
}

.dropdown-menu .con_drop {
  gap: 0.313rem;
}

@media (max-width: 768px) {
  .con_drop {
    padding: 5px 10px;
    width: auto !important;
    justify-content: center;
  }

  .form_sec .dropdown-menu {
    max-width: 100% !important;
    min-width: 100%;
  }

  .con_name,
  .select-arrow {
    display: none;
  }

  /* .choose_btn {
    padding: 0.75rem 0.938rem;
  } */

  .form_top_btn {
    margin-bottom: 1.875rem;
    justify-content: center;
    gap: 10px;
  }

  .form_sec .login_btn {
    max-width: 100%;
    white-space: initial;
    padding: 0.688rem;
    margin-top: 0.938rem;
  }

  .form_sec .reme_wrap {
    max-width: 100%;
    flex-wrap: wrap;
  }

  .form_sec .inputs {
    flex-wrap: wrap;
    margin-bottom: 1.563rem;
  }

  .form_sec .mand_icon {
    max-width: 100%;
  }

  .form_sec .label {
    flex-wrap: wrap;
    white-space: initial;
  }

  .form_sec .det {
    max-width: 100%;
    font-size: var(--body18);
  }

  .form_sec .title {
    max-width: 100%;
    white-space: normal;
  }

  .form_sec .form {
    max-width: 100%;
    padding: 0.938rem;
  }

  .form_sec .inputs input,
  .form_sec .form-select {
    padding: 0.625rem 1rem !important;
  }

  .reset_btn {
    padding: 0.625rem 2.125rem;
  }

  .btn_wrap {
    flex-direction: column;
    gap: 0;
  }

  .pass_wrap {
    flex-direction: column;
    gap: 0;
  }

  .input_wrap {
    flex-direction: column;
    gap: 0;
  }

  .com_wrap {
    padding: 0;
  }
}

/* .checked_label {
  color: var(--primary-color);
} */

/* menu start */

#Tr__menuwrapper {
  padding-left: 0;
  transition: all 0.5s ease;
  position: relative;
}

.Tr__menuwrapper {
  padding-right: 0;
  transition: all 0.5s ease;
  position: relative;
}

.tr__sidebar_wrap {
  z-index: 11;
  position: fixed;
  /* left: 250px; */
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: hidden;
  overflow-x: hidden;
  background: var(--white-color);
  transition: all 0.5s ease;
  padding: 1.25rem;
}
.ar_tr__sidebar_wrap {
  /* margin-right: 0 !important; */
  /* margin-left: 100% !important; */
  right: 0;
}

.toggled .tr__sidebar_wrap {
  width: 270px;
}

.toggled #Tr__menuwrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1111;
}

.tr__logo img {
  max-width: 100%;
  width: 100%;
}

.tr__sidebar_menu {
  list-style: none;
  padding: 0;
  margin-top: 2.5rem;
  overflow-y: auto;
  max-height: calc(100vh - 250px);
  /* min-height: 650px; */
  padding-right: 0.313rem;
  background-color: var(--white-color);
}

.tr__sidebar_menu>li {
  margin-bottom: 0.938rem;
}

.tr__sidebar_menu>li a {
  display: block;
  text-decoration: none;
  color: var(--dark-color);
  font-weight: var(--font-weight-regular);
  font-size: var(--body16);
  display: flex;
  gap: 0.75rem;
  padding: 0.625rem;
}

.tr__sidebar_menu li.active svg {
  fill: var(--white-color) !important;
}

.tr__sidebar_menu .svg-icon.active,
.tr__sidebar_menu>li:hover .svg-icon,
.tr__sidebar_bottom .svg-icon.active {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7482%) hue-rotate(179deg) brightness(105%) contrast(108%);
}

/* .fixed_navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  max-width: 100%;
  z-index: 1000;
} */

.content_wrap {
  padding-top: 3.75rem;
}

.tr__sidebar_menu>li>a:hover,
.tr__sidebar_menu>li.active>a {
  text-decoration: none;
  border-radius: var(--radius-border);
  color: var(--white-color);
  background: var(--primary-color);
  display: flex;
  gap: 0.625rem;
  padding: 0.625rem;
  box-shadow: 0px 4px 15px 1px rgba(188, 146, 63, 0.32);
  -webkit-box-shadow: 0px 4px 15px 1px rgba(188, 146, 63, 0.32);
  -moz-box-shadow: 0px 4px 15px 1px rgba(188, 146, 63, 0.32);
  /* transition: all ease-in-out 0.3s; */
}

.tr__sidebar_menu>li>a img {
  width: 1.5rem;
  height: 1.5rem;
}

.tr__sidebar_bottom li {
  background-color: rgba(188, 146, 63, 0.2);
  border-radius: var(--radius-border);
}

ul.tr__sidebar_bottom {
  margin-top: 7.813rem;
  display: flex;
  /* height: 100%; */
  align-items: end;
  padding-top: 0;
  margin-bottom: 0;
  position: sticky;
  bottom: 0;
  left: 0;
}

ul.tr__sidebar_bottom.tr__sidebar_menu li {
  width: 100%;
  margin-bottom: 0;
}

.toggle_btn {
  display: none;
}

.top_menus,
.proile_menus {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 2.813rem;
}

.top_menus li a {
  font-size: var(--body14);
}

.topmenu_wrapper {
  width: 100%;
  position: absolute;
  z-index: 2;
}

.toggled .topmenu_wrapper {
  position: absolute;
  margin-right: -250px;
}

.topmenu_wrapper .navbar {
  border-width: 0 0 0 0;
  background-color: var(--white-color);
  font-size: var(--body24);
  margin-bottom: 0;
  border-radius: 0;
}

.topmenu_wrapper .navbar a {
  color: var(--dark-color);
  font-size: var(--body14);
}

.topmenu_wrapper .navbar a:hover {
  color: var(--primary-color);
}

.profile img {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.375rem;
}

.top_drop {
  border: 1px solid var(--stroke-color) !important;
  padding: 0.313rem 0.625rem !important;
}

.top_dropmenu {
  transform: translate(0px, 10px) !important;
  max-width: 100px !important;
  width: 100%;
  min-width: auto !important;
  padding: 0px;
  border: none;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.top_dropmenu a {
  padding: 0.625rem;
}

.top_dropmenu a:hover {
  background-color: var(--white-color);
  border-radius: 0.375rem;
}

/* .tr__topmenus {
  padding: 10px 9px;
  background-color: var(--primary-color);
} */
@media (max-width: 1650px) {
  #Tr__menuwrapper .right_menubar {
    padding-right: 0 !important;
  }

  .tr__topmenus {
    padding: 0.625rem;
    background-color: var(--primary-color);
    position: fixed;
    top: auto;
    bottom: 0px;
    z-index: 999;
    right: 0px;
    border-radius: var(--radius-border) 0 0 var(--radius-border);
    color: var(--white-color);
    cursor: pointer;
  }
}

#Tr__menuwrapper .tr__topmenubar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0.625rem; */
  background-color: var(--white-color);
  /* padding: 1.5rem; */
}

#Tr__menuwrapper .right_menubar {
  /* padding-right: 1.875rem; */
  padding-right: 4.375rem;
}

.leftmenu_toggle {
  display: none;
}

.right_menulist {
  justify-content: end;
}

.navbar-nav-scroll {
  align-items: center;
}

.left_menulist li,
.right_menulist .nav-link {
  color: var(--black-color);
  padding: 0 0.625rem !important;
  border-right: 1px solid var(--stroke-color) !important;
  transition: all ease-in-out 0.3s;
  font-size: var(--body14);
}

.right_menulist .nav-link {
  padding: 0 1.25rem !important;
}

.tr__topmenus .left_menulist a:hover {
  color: var(--primary-color);
}

.tr__topmenus .left_menulist {
  display: flex;
  margin-bottom: 0;
  max-width: 1054px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  padding-left: 0;
  padding: 1.5rem 0;
  /* padding-bottom: 0.313rem; */
}

.tr__topmenus .left_menulist li a {
  color: var(--dark-color);
  white-space: nowrap;
  font-weight: var(--font-weight-medium);
  transition: all ease-in-out 0.5s;
  padding: 1.5rem 0;
  position: relative;
  /* font-size: var(--body16); */
}

.tr__topmenus .left_menulist li a:hover {
  color: var(--primary-color);
}

.tr__topmenus .left_menulist li:last-child,
.right_menulist .nav-link:last-child {
  padding-right: 0 !important;
  border-right: none !important;
}

.tr__topmenus .left_menulist li:first-child {
  padding-left: 0 !important;
}

.right_menulist .nav-link {
  position: relative;
}

.right_menulist .profile_badge {
  position: absolute;
  bottom: 0 !important;
  left: 22px;
  width: 0.625rem;
  height: 0.625rem;
  top: auto;
  background-color: var(--green-color);
  border: 1px solid var(--white-color);
  border-radius: 50%;
  padding: 0;
}

.right_menulist .bell_badge {
  position: absolute;
  right: 22px;
  width: 0.625rem !important;
  height: 0.625rem;
  top: 2px;
  background-color: var(--primary-color) !important;
  /* border: 1px solid var(--white-color); */
  border-radius: 50%;
  padding: 0;
}

.right_menulist .badge:empty {
  display: block !important;
}

.right_menulist .con_drop {
  padding: 0;
}

@media (max-width: 1650px) {

  /* .tr__topmenulist {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */
  .leftmenu_toggle {
    display: block;
  }

  .leftmenu_hidden {
    display: none !important;
  }

  .tr__topmenus .left_menulist {
    margin-bottom: 0;
    position: fixed;
    transition: all 0.5s ease-in-out;
    bottom: 47px;
    top: 0;
    z-index: 11;
    background: var(--white-color);
    width: 250px;
    right: 0;
    flex-direction: column;
    border-radius: var(--radius-border) 0 0 var(--radius-border);
    gap: 1.25rem;
    padding: 1.875rem;
    transform: translateX(0);
  }

  .tr__topmenus .left_menulist.leftmenu_hidden {
    right: -120%;
    /* transition: all 0.5s ease-in-out; */
    display: flex !important;
    transform: translateX(-100%);
  }

  .leftmenu_toggle svg {
    transition: transform 0.3s ease;
  }

  .leftmenu_toggle.rotate svg {
    transform: rotate(180deg);
  }

  .left_menulist li {
    border: 0 !important;
  }

  .tr__topmenus .left_menulist li:first-child {
    padding-left: 0.625rem !important;
  }

  .tr__topmenus .left_menulist li a {
    padding: 1rem 0 0.313rem;
  }

  #Tr__menuwrapper .tr__topmenubar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: end;
    padding: 1rem;
  }

  .tr__topmenus {
    order: 2;
    padding-left: 0.625rem !important;
  }
}

.content_wrap {
  width: 100%;
  position: absolute;
  padding: 1.875rem;
  top: 100px;
  background-color: var(--page-color);
  height: 100%;
}

.toggled .content_wrap {
  position: absolute;
  margin-right: -250px;
}

.tr__topmenus_small {
  display: none;
}

.profile_drop {
  width: 260px;
  /* transform: translate(-110px, 15px) !important; */
  transform: translate(-155px, 15px) !important;
  text-align: center;
  padding: 1.25rem 0.625rem;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: none;
}

.profile_drop p {
  margin-bottom: 0.625rem !important;
}

.profile .logout img {
  width: 1.875rem;
  height: 1.438rem;
}

.profile {
  position: relative;
}

/* .profile_drop::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 22;
} */

.profile_drop h5 {
  margin: 0.625rem 0;
}

.profile_drop a {
  color: var(--primary-color);
  word-break: break-all;
}

.profile_drop .drop_item {
  display: flex;
  /* justify-content: space-between; */
  gap: 0.625rem;
  margin-bottom: 0.625rem;
  border-bottom: 1px solid var(--stroke-color);
}

.drop_item p:hover {
  color: var(--primary-color);
}

.profile_drop .drop_item:last-child {
  border-bottom: none;
}

.close_toggle {
  display: none;
}

@media (min-width: 992px) {
  #Tr__menuwrapper {
    padding-left: 250px;
  }

  .Tr__menuwrapper {
    padding-right: 250px;
    padding-left: 0 !important;
  }

  .toggled {
    padding-left: 30px;
  }

  .tr__sidebar_wrap {
    width: 250px;
  }

  .toggled .tr__sidebar_wrap {
    width: 60px;
  }

  .toggled .topmenu_wrapper {
    position: absolute;
    margin-right: -190px;
  }

  .toggled .content_wrap {
    position: absolute;
    margin-right: -190px;
  }

  .topmenu_wrapper {
    position: relative;
  }

  /* 
  .toggled {
    padding-left: 60px;
  } */

  .content_wrap {
    position: relative;
    top: 0;
  }

  .toggled .topmenu_wrapper,
  .toggled .content_wrap {
    position: relative;
    margin-right: 60px;
  }
}

.toggle_btn img {
  /* font-size: 15px; */
  width: 1.25rem;
  height: 1.25rem;
}

@media (max-width: 768px) {
  .content_wrap {
    padding: 0.625rem;
  }
}

@media (max-width: 1440px) {
  ul.tr__sidebar_bottom.tr__sidebar_menu {
    margin-top: 1.875rem;
  }
}

@media (max-width: 1350px) {
  .tr__topmenulist {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white-color);
  }

  #Tr__menuwrapper {
    padding-left: 0px;
    overflow: hidden;
  }

  .Tr__menuwrapper {
    padding-right: 0px;
    overflow: hidden;
  }

  .close_toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    cursor: pointer;
  }

  .close_toggle img {
    transition: all ease-in-out 0.5s;
  }

  .close_toggle img:hover {
    filter: brightness(0) saturate(100%) invert(65%) sepia(16%) saturate(1473%) hue-rotate(1deg) brightness(89%) contrast(85%);
  }

  .toggle_btn {
    display: block;
    padding-left: 1.875rem;
  }

  .tr__sidebar_wrap {
    width: 250px;
  }

  .toggled .topmenu_wrapper {
    position: relative;
  }

  .toggled .content_wrap {
    position: absolute;
    margin-right: -250px;
  }

  .topmenu_wrapper {
    position: relative;
  }

  .toggled {
    /* padding-left: 250px;  */
    padding: 1rem;
    margin-left: 0;
  }
  .ar_tr__sidebar_wrap{
    margin-right: -250px;
  }
  .ar_tr__sidebar_wrap.toggled {
    margin: 0;
}

  .content_wrap {
    position: relative;
    top: 0;
  }

  .toggled .topmenu_wrapper,
  .toggled .content_wrap {
    margin-right: 60px;
  }
}

.toggle_btn.ar_toggle_btn {
  margin-right: 30px;
}
/* menu end */

/* dashboad start */

.tr__dashboard .tr__heading {
  display: flex;
  gap: 0.625rem;
  padding-bottom: 0.938rem;
  border-bottom: 1px solid var(--stroke-color);
  flex-wrap: wrap;
}

.tr__heading h1 {
  font-size: var(--h5);
  font-weight: var(--font-weight-bold);
  line-height: 1.5;
  color: var(--black-color);
  font-family: var(--secondry-font);
}

.tr__statastic {
  margin: 1.875rem 0;
}

.tr__statastic .card {
  border: 1px solid var(--stroke-color) !important;
}

.item_wrap {
  padding: 1.25rem;
}

.profile_wrap p {
  font-size: var(--body16);
  line-height: 1.625;
  color: var(--black-color);
  font-weight: var(--font-weight-regular);
}

.item_det {
  display: flex;
  gap: 0.938rem;
}

.item_det .img_Wrap {
  background-color: rgba(188, 146, 63, 0.2);
  padding: 0.813rem;
  border-radius: var(--radius-border);
  width: 60px;
  height: 60px;
}

.item_wrap h5 {
  font-weight: var(--font-weight-bold);
  font-size: var(--h5);
  line-height: 1.4;
}

.item_wrap p {
  font-size: var(--body14);
  line-height: 1.4;
}

.tr__search_tables {
  margin-bottom: 1.875rem;
}

.table_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
}

.table_title p,
.table_title a {
  font-size: var(--body16);
  font-weight: var(--font-weight-regular);
  line-height: 1.625;
}

.table_title a {
  color: var(--primary-color);
}

.table_search .date {
  display: flex;
  gap: 0.438rem;
  align-items: center;
}

.table_search .date p,
.table_search td {
  font-size: var(--body14);
  line-height: 2.25;
}

.table_arrow img {
  /* max-width: 100%; */
  /* width: 1.25rem; */
  width: 1.5rem;
  margin-left: 5px;
}

.table_search table {
  border-radius: var(--radius-border) !important;
  overflow: hidden;
}

.profile_wrap .title {
  margin-bottom: 1.25rem;
}

.profile_wrap .profile_det {
  display: flex;
  gap: 0.938rem;
  align-items: center;
}

.profile_wrap .heading {
  margin-bottom: 1.25rem !important;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid var(--stroke-color);
  display: flex;
  gap: 0.625rem;
}

.profile_wrap .heading span {
  font-size: var(--body20);
  color: var(--black-color);
  font-weight: var(--font-weight-regular);
  line-height: 1.5;
}

.social_list ul {
  padding: 0;
  list-style: none;
  display: flex;
  gap: 0.938rem;
  flex-wrap: wrap;
}

.social_list ul li {
  padding: 0.5rem;
  background-color: var(--light-color);
  border-radius: 50%;
  transition: all ease-in-out 0.5s;
}

.profile_wrap .profile_mail {
  margin-bottom: 0.938rem !important;
}

.profile_wrap .profile_mail a {
  color: var(--secondary-color);
  font-size: var(--body14);
  line-height: 1.25;
}

.profile_wrap .profile_img {
  border-radius: var(--radius-border);
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile_wrap .profile_img img {
  max-width: 130px;
  height: 130px;
  min-width: 130px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .profile_wrap .profile_det {
    flex-direction: column;
  }
}

/* slide */
.uplode_item {
  display: flex;
  gap: 0.938rem;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 1.563rem;
  border-bottom: 1px solid var(--stroke-color);
}

.uplode_item .cartlist {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.uplode_item .cart {
  padding: 0;
  list-style: none;
  display: flex;
  gap: 1.25rem;
  margin-bottom: 0;
}

.uplode_item .cart li {
  border-radius: 50%;
  background-color: rgba(188, 146, 63, 0.2);
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uplode_item .cart img {
  width: 0.813rem;
  height: 0.813rem;
}

.uplode_item .slide_title {
  font-size: 1.25rem !important;
  color: var(--black-color);
  font-weight: var(--font-weight-regular);
  line-height: 1.5;
  margin-bottom: 0.5rem !important;
}

.uplode_item .slide_con p {
  font-size: var(--body14);
  color: var(--black-color);
  font-weight: var(--font-weight-regular);
  margin-bottom: 0.5rem !important;
}

.uplode_item .upload_view {
  color: var(--primary-color);
  padding-right: 0.625rem;
}

.uplode_item .slide_img {
  flex: 1;
  margin-top: 0.625rem;
}

.uplode_item .slide_con {
  flex: 2;
}

.uplode_item .imgwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0.813rem; */
  border: 1px solid var(--stroke-color);
  border-radius: var(--radius-border);
  background: var(--light-color);
  max-width: 131px;
  min-height: 131px;
  min-width: 131px;
  max-height: 131px;
  border-radius: var(--radius-border);
  overflow: hidden;
}

.uplode_item .imgwrap img {
  max-width: 131px;
  min-height: 131px;
  min-width: 131px;
  max-height: 131px;
  object-fit: cover;
}

.uplode_item .price,
.uplode_item .dis_price {
  display: flex;
  gap: 0.938rem;
  font-size: var(--body14);
}

.uplode_item .price {
  color: var(--black-color);
  margin-bottom: 0.5rem;
}

.uplode_item .dis_price {
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
}

.table_wrap .card {
  border: 1px solid var(--stroke-color) !important;
}

.table_wrap .card-body {
  padding: 0.938rem 0.938rem 1.563rem;
}

.uplode_slide .swiper-pagination {
  bottom: -0.313rem !important;
}

.uplode_slide .swiper-pagination-bullet-active {
  background-color: var(--primary-color);
  width: 0.938rem;
  height: 0.5rem;
  border-radius: 1.438rem;
}

.uplode_slide .swiper-pagination-bullet {
  background-color: var(--primary-color);
  opacity: 1;
}

@media (max-width: 768px) {
  .uplode_item {
    flex-direction: column;
    align-items: center;
  }
}

/* slide */

/* top select */

.calc_drop {
  width: 550px;
  transform: translate(-350px, 15px) !important;
  text-align: center;
  padding: 1.25rem 0.625rem;
}

.calc_title h6 {
  text-align: left;
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  line-height: 1.5;
  font-size: var(--h6);
  /* margin-bottom: 1.25rem !important; */
  padding: 1.25rem 1.5rem 0;
}

.css-hz1bth-MuiDialog-container {
  height: 100%;
  outline: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-box-pack: end;
  -ms-flex-pack: center;
  -webkit-justify-content: end;
  justify-content: end !important;
  -webkit-align-items: start;
  -webkit-box-align: start;
  -ms-flex-align: center;
  align-items: start !important;
  margin-top: 40px;
}

.select_item {
  flex: 1;
  text-align: left;
}

.select_item label {
  margin-bottom: 0.375rem;
  font-size: var(--body14);
  line-height: 1.4;
  color: var(--black-color);
}

.calc_wrap .dropdown-toggle {
  padding: 0;
}

.calc_price {
  display: flex;
  gap: 0.313rem;
  /* justify-content: space-between; */
  margin: 1.25rem 0;
}

.calc_price .price_title {
  text-align: left;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid var(--stroke-color);
  margin-bottom: 0.625rem !important;
  color: var(--black-color);
}

.price_list {
  /* flex: 1; */
  flex: 0 0 230px;
  max-width: 230px;
  background: var(--light-color);
  padding: 0.813rem 0.563rem;
  text-align: left;
  color: var(--black-color);
}

.price_list .price_list_wrap {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.price_list .price_list_wrap p {
  margin-bottom: 0.75rem !important;
  font-size: var(--body14);
  line-height: 1.063;
  font-weight: var(--font-weight-regular);
}

.cp_price {
  font-size: var(--body16) !important;
  margin-bottom: 0 !important;
}

.price_your {
  padding: 0.813rem 0.563rem;
  max-width: 310px;
  flex: 0 0 310px;
}

.right_list {
  display: flex;
  gap: 1.25rem;
}

.price_plus_minus {
  border: 1px solid var(--stroke-color);
  border-radius: var(--radius-border) !important;
  min-width: 120px;
  width: 100%;
}

.price_plus_minus p {
  padding: 0.36rem 3.063rem;
  font-size: var(--body14);
  text-align: center;
}

.price_plus_minus button {
  text-align: center;
  background-color: var(--green-color);
  /* border-radius: 0; */
  width: 100%;
  border: none;
  padding: 0.3rem 3.063rem;
  color: var(--white-color);
  /* font-size: var(--body20); */
}

.plus_btn {
  border-radius: 0.313rem 0.313rem 0 0;
}

.minus_btn {
  border-radius: 0 0 0.313rem 0.313rem;
}

.right_list .head {
  text-align: left;
  margin-bottom: 0.438rem !important;
  font-size: var(--body14);
  line-height: 1.4;
  color: var(--black-color);
}

/* .right_list .first{
  flex: 1;
}

.right_list .second{
  flex: 1;
} */

.cal_btn {
  display: flex;
  gap: 1.25rem;
  text-align: center;
}

.cancel_btn,
.save_btn {
  flex: 1;
  color: var(--white-color);
  padding: 0.5rem;
  border-radius: var(--radius-border);
  /* min-width: 240px; */
  font-size: var(--body14);
  line-height: 1.4;
  border: 1px solid transparent;
}

.cancel_btn {
  background-color: var(--red-color);
  width: 100%;
}

.cancel_btn:hover {
  background-color: #bb2d3b;
  border: 1px solid var(--red-color);
}

/* calculator
 */

/* .my-modal-custom-class {
  position: relative;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  background-color: white;
  overflow: hidden;
  padding: 20px;
} */

/* mui datatable */

.custom_header {
  background-color: #f4f4f4;
  border: 1px solid var(--stroke-color);
  text-align: left;
  font-weight: var(--font-weight-bold);
  color: var(--black-color);
}

.even-row {
  background-color: var(--white-color);
}

.odd-row {
  background-color: var(--light-color);
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-size: var(--body16);
  line-height: 1.5;
}

.custom_image {
  width: 1.5rem;
  height: 1.5rem;
}

/* navigation bar */
.nav_top .con_drop {
  width: auto;
  /* border: 1px solid var(--stroke-color) !important; */
  max-width: fit-content;
}

.nav_top .con_drop_btn {
  padding: 0.25rem;
}

.nav_top .login_btn {
  background: none;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 0.56rem 0.66rem;
  display: inline-block;
  border-radius: var(--radius-border);
}

.nav_top .login_btn:hover {
  background-color: var(--primary-color);
  border: 1px solid transparent;
  color: var(--white-color);
}

.nav_top .nav_logo img {
  max-width: 100%;
  height: auto;
}

.nav_top .nav_center {
  justify-content: end;
}

.nav_center .nav_center_menu {
  gap: 2.813rem;
}

.nav_end .nav_center_menu {
  gap: 1.25rem;
}

.nav_center .nav_center_menu a {
  color: var(--black-color);
  font-weight: var(--font-weight-regular);
}

.nav_center .nav_center_menu a:hover {
  color: var(--primary-color);
}

.nav_top .nav_end {
  gap: 1.25rem;
  justify-content: end;
}

@media (max-width: 991px) {
  .collapse:not(.show)+.nav_top .nav_end {
    gap: 1rem;
    justify-content: center;
    display: flex;
  }

  .nav_center .nav_center_menu,
  .nav_end .nav_center_menu {
    gap: 1rem;
    flex-direction: row;
    justify-content: center;
  }

  /* .nav_top .nav_end{
    gap: 1rem;
    justify-content: center;
    display: flex;
  } */
}

/* navbar end */

/* pricing  */

.plan_package .btn-check:checked+.btn,
.plan_package .btn.active,
.plan_package .btn.show,
.plan_package .btn:first-child:active,
.plan_package :not(.btn-check)+.btn:active {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.pricing {
  background-color: var(--page-color);
}

.pricing .pricing_title {
  text-align: center;
  padding: 5rem 0;
  max-width: 40.625rem;
  margin: 0 auto;
}

.pricing .pricing_title h1 {
  color: var(--black-color);
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
  margin-bottom: 1.875rem !important;
  font-size: var(--h1);
}

.pricing .pricing_title p {
  font-weight: var(--font-weight-regular);
  font-size: var(--body18);
  line-height: 1.4;
}

.pricing_plan {
  padding: 5rem 0;
  background-color: var(--white-color);
}

.plan_package {
  display: flex;
  justify-content: center;
  margin-bottom: 1.875rem;
}

.pricing_plan .package_btn {
  background-color: var(--white-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-size: var(--body14);
  line-height: 1.4;
}

.package_btn {
  padding: 0.625rem 1rem;
  cursor: pointer;
}

.package_btn:focus {
  outline: none;
}

.plan_heading_wrap {
  display: flex;
  margin-top: 1.875rem;
}

.plan_details .plans_wrap {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  height: 500px;
  border: 1px solid var(--stroke-color);
}

.plan_details .plans {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: space-between; */
  height: 100%;
  /* border: 1px solid var(--stroke-color); */
  max-width: 283.5px;
  flex: 0 0 283.5px;
}

.pricing_head {
  text-align: center;
  padding: 1.25rem;
  flex: 1;
}

.plan_heading_img {
  display: flex;
  justify-content: center;
  min-width: 162px;
  width: 100%;
  height: 500px;
}

.plan_heading_img img {
  /* max-width: 100%; */
  max-width: 162px;
  min-width: 162px;
  width: 100%;
  object-fit: cover;
}

.pricing_head p,
.pricing_head h5 {
  color: var(--primary-color);
}

.pricing_head h5 {
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
  font-size: var(--h6);
  margin-bottom: 0.75rem !important;
}

.pricing_head h3 {
  font-weight: var(--font-weight-bold);
  line-height: 1;
  font-size: var(--h5);
  word-break: break-all;
  min-height: 95px;
  margin-bottom: 0.75rem !important;
}

.dis_offer {
  background-color: rgba(4, 109, 21, 0.2);
  color: var(--green-color);
  border-radius: var(--radius-border);
  font-size: var(--body16);
  line-height: 1.5;
  padding: 0.313rem 0.625rem;
  display: inline-block;
}

.pricing_con {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  flex: 2;
}

/* .pricing_con p {
  color: var(--secondary-color);
  font-size: var(--h6);
  line-height: 1.4;
  font-weight: var(--font-weight-regular);
  max-width: 220px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
} */

.pricing_con p {
  color: var(--secondary-color);
  font-size: var(--h6);
  line-height: 1.4;
  font-weight: var(--font-weight-regular);
  /* max-width: 220px; */
  display: -webkit
}


/* .pricing_con p {
  color: var(--secondary-color);
  font-size: var(--h6);
  line-height: 1.5;
  font-weight: var(--font-weight-regular);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
} */

.pricing_con span {
  color: var(--black-color);
  font-weight: var(--font-weight-bold);
}

.apply_btn {
  color: var(--white-color);
  background-color: var(--primary-color);
}

.plan_features {
  color: var(--red-color) !important;
  font-size: var(--body14) !important;
  line-height: 1.25 !important;
  font-weight: var(--font-weight-regular);
}

.plan_details table {
  border: 1px solid var(--stroke-color);
  width: 100%;
}

.plan_details table tr {
  vertical-align: middle;
}

.plan_details table td:nth-child(1) {
  min-width: 160px;
  max-width: 160px;
}

/* 
.plan_details table td:nth-child(2),
.plan_details table td:nth-child(3),
.plan_details table td:nth-child(4),
.plan_details table td:nth-child(5) {
  min-width: 280px;
  max-width: 280px;
} */


.plan_details table td:nth-child(2),
.plan_details table td:nth-child(3),
.plan_details table td:nth-child(4),
.plan_details table td:nth-child(5) {
  min-width: 29.2%;
  max-width: 100%;
}

@media (max-width: 992px) {
  .pricing_plan .container {
    max-width: 100%;
    padding: 0 0.938rem;
  }

  .plan_heading_img {
    flex: 0 0 167px;
    max-width: 167px;
  }

  /* .pricingplans {
    overflow-x: scroll;
  } */
}

.checked_label {
  color: var(--primary-color);
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: var(--white-color);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* search stock page */

/* 22-5-2024 */

.tr__searchStock .searchStock_title {
  display: flex;
  gap: 0.625rem;
}

.tr__searchStock .searchStock_title h1 {
  font-size: var(--h5);
}

.tr__searchStock .searchStock_btns {
  display: flex;
  gap: 0.938rem;
  justify-content: end;
  align-items: flex-start;
}

/* .tr__searchStock .loadSearch,
.tr__searchStock .Rapspec {
  width: 100%;
  padding: 0.625rem;
  font-size: var(--body16);
} */
.tr__searchStock .loadSearch {
  max-width: 190px;
}

.tr__searchStock .Rapspec {
  max-width: 125px;
}

.tr__searchStock .saveSearch_btn,
.tr__searchStock .search_btn {
  background: var(--black-color);
  color: var(--white-color);
  /* padding: 0.656rem 1rem; */
  padding: 0.33rem 1rem;
  border-radius: var(--radius-border);
  /* display: inline-block; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.313rem;
  border: 1px solid transparent;
}

/* .tr__searchStock .search_btn {
  padding: 0.41rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.313rem;
} */

.tr__searchStock .tr__searchStock_top,
.tr__buyRequest .tr__buyRequest_top,
.tr__saveSearch .tr__saveSearch_top,
.tr__trackDiamond .tr__trackDiamond_top,
.tr__trackeDimond_list .tr__trackDiamondList_top,
.tr__rapaPort .tr__rapaPort_top,
.tr__cart .tr__cart_top,
.tr__address_selection .tr__address_selWrap,
/* .tr__watchlist, */
.tr__watchlist_top,
.tr__pair .tr__pair_top {
  padding-bottom: 0.625rem;
  border-bottom: 1px solid var(--stroke-color);
  margin-bottom: 1.25rem;
}

.shape_stone_title {
  display: flex;
  justify-content: space-between;
  gap: 1.875rem;
  margin-bottom: 0.5rem;
}

.shape_stone_title h6,
.fancy_det h6 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  line-height: 1.5;
}

.viewmore a {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
  line-height: 1.5;
  cursor: pointer;
  white-space: nowrap;
}

.tr__searchStock input[type="checkbox"][id^="myCheckbox"],
.filter_modal input[type="checkbox"][id^="myCheckbox"] {
  display: none;
}

.tr__searchStock label img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

.tr__searchStock input[type="checkbox"]+label svg {
  fill: var(--black-color);
  /* Default color */
}

.tr__searchStock input[type="checkbox"]:checked+label svg {
  fill: var(--white-color);
  /* Color when checked */
}

ul.shape_stone_Check {
  display: flex;
  gap: 0.938rem;
  padding: 0;
  flex-wrap: wrap;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid var(--stroke-color);
}

ul.shape_stone_Check li img {
  /* max-width: 100%; */
  width: 30px;
  height: 30px;
}

.shape_stone_Check li,
.buyRequest_modal .shape_stone_Check li {
  background: var(--white-color);
  padding: 1.094rem 1.656rem;
  border-radius: var(--radius-border);
  width: 90px;
  transition: all ease-in-out 0.3s;
  height: 90px;
}

.shape_stone_Check li:hover {
  background-color: var(--primary-color);
}

.shape_stone_Check label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
}

.shape_stone_Check label p {
  font-size: var(--body12);
  text-align: center;
  line-height: 1;
  /* transition: all ease-in-out 0.3s; */
  /* min-height: 30px; */
  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.shape_stone_Check .label-wrapper.active {
  background-color: var(--primary-color);
  color: var(--white-color);
  box-shadow: 0 0 15px rgba(188, 146, 63, 0.32);
}

.shape_stone_Check .label-wrapper:hover p {
  color: var(--white-color);
}

.shape_stone_Check .svg-icon.active,
.shape_stone_Check>li:hover .svg-icon,
.shape_stone_Check .svg-icon.active,
.media_list_item .svg-icon.active,
.media_list_item>li:hover .svg-icon,
.media_list_item .svg-icon.active {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7482%) hue-rotate(179deg) brightness(105%) contrast(108%);
}

/* 23-5-2024 */

.from_to {
  display: flex;
  gap: 1.25rem;
}

.carat_weight .from_to input {
  max-width: 140px;
  width: 100%;
}

.carat_weight .from_to_range_item,
.color_con .fancy_det_list,
.search_clarity .search_clarity_list,
.filter_modal .search_clarity_list,
.search_finish .search_finish_list,
.search_Fluorescence .search_Fluorescence_list,
.grading_report .grading_report_list,
.Gridle_culet .Gridle_culet_list,
.treatment .treatment_list,
.advance_Search .advance_Search_list {
  display: flex;
  padding: 0;
  margin: 0;
  gap: 0.625rem;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.advance_Search .advance_Search_list {
  margin-bottom: 0;
}

.carat_weight .from_to_range_item li {
  background-color: var(--white-color);
  padding: 0.5rem 0.375rem;
  border-radius: var(--radius-border);
  max-width: 71px;
  width: 100%;
  text-align: center;
  border: 1px solid var(--stroke-color);
}

.carat_weight .from_to_range_item li p,
.color_con .fancy_det_list li p,
.search_clarity .search_clarity_list li p,
.filter_modal .search_clarity_list li p,
.search_finish .search_finish_list li p,
.search_Fluorescence .search_Fluorescence_list li p,
.grading_report .grading_report_list li p,
.measure_list_item li p,
.Gridle_culet .Gridle_culet_list li p,
.treatment .treatment_list li p,
.advance_Search .advance_Search_list li p {
  font-size: var(--body12);
  line-height: 0.938;
  font-weight: var(--font-weight-regular);
  color: var(--black-color);
}

.carat_weight .from_to_range_item li:hover,
.color_con .fancy_det_list li:hover,
.search_finish .search_finish_list li:hover,
.search_clarity .search_clarity_list li:hover,
.filter_modal .search_clarity_list li:hover,
.search_Fluorescence .search_Fluorescence_list li:hover,
.grading_report .grading_report_list li:hover,
.search_media .search_media_list li:hover,
.measure_list_item li:hover,
.Gridle_culet .Gridle_culet_list li:hover,
.treatment .treatment_list li:hover,
.advance_Search .advance_Search_list li:hover,
.tr__uploadDiamond_single .uploadDiamond_list li:hover {
  color: var(--white-color) !important;
  background-color: var(--primary-color);
}

.from_to_range_item li.active,
.fancy_det_list li.active,
.search_clarity_list li.active,
.filter_modal .search_clarity_list li.active,
.search_finish_list li.active,
.search_Fluorescence_list li.active,
.grading_report_list li.active,
.search_media_list li.active,
.measure_list_item li.active,
.Gridle_culet_list li.active,
.treatment_list li.active,
.advance_Search_list li.active,
.buyRequest_modal .measure_list_item li.active,
.tr__uploadDiamond_single .measure_list_item li.active {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
  color: var(--white-color) !important;
}

.from_to_range .from_to_range_item li label,
.color_con .fancy_det_list li label,
.search_clarity .search_clarity_list li label,
.filter_modal .search_clarity_list li label,
.search_finish .search_finish_list li label,
.search_Fluorescence .search_Fluorescence_list li label,
.grading_report .grading_report_list li label,
.search_media .search_media_list li label,
.measure_list_item li label,
.Gridle_culet .Gridle_culet_list li label,
.treatment .treatment_list li label,
.advance_Search .advance_Search_list li label {
  padding: 0.5rem 0.375rem !important;
}

.carat_weight_list {
  padding-bottom: 1.25rem;
  border-bottom: 1px solid var(--stroke-color);
  margin-bottom: 1.25rem;
}

.from_to_range .from_to_range_item li,
.color_con .fancy_det_list li,
.search_clarity .search_clarity_list li,
.filter_modal .search_clarity_list li,
.search_finish .search_finish_list li,
.search_Fluorescence .search_Fluorescence_list li,
.grading_report .grading_report_list li,
.search_media .search_media_list li,
.measure_list_item li,
.Gridle_culet .Gridle_culet_list li,
.treatment .treatment_list li,
.advance_Search .advance_Search_list li {
  background-color: var(--white-color);
  padding: 0 !important;
  border-radius: var(--radius-border);
  /* min-width: 30px; */
  min-width: fit-content;
  /* width: 100%; */
  text-align: center;
  border: 1px solid var(--stroke-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.borderRight {
  border-right: 1px solid var(--stroke-color);
}

.fancy_det .color_title {
  font-size: var(--body14);
  margin-bottom: 0.313rem !important;
  line-height: 1.4;
  font-weight: var(--font-weight-regular);
}

.white_det_list li,
.search_clarity_list li,
.filter_modal .search_clarity_list li,
.search_finish_list li {
  padding: 0.5rem 0.8rem !important;
}

.clarity,
.eyeClean {
  border-right: 1px solid var(--stroke-color) !important;
}

/* .eyeClean {
  max-width: 330px;
  width: 100%;
} */

.bgm .check_text {
  font-size: 1.125rem;
  font-weight: var(--font-weight-bold);
  margin-left: 0.625rem;
  color: var(--black-color);
  line-height: normal;
}

.finish_cut,
.finish_polish,
.finish_symmetry {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  /* flex-wrap: wrap; */
}

/* .search_finish_list, */
.finish_cut,
.finish_polish {
  border-right: 1px solid var(--stroke-color);
}

.finish_cut .from_to,
.finish_polish .from_to,
.finish_symmetry .from_to,
.price_dis .from_to,
.measure_treat .from_to {
  gap: 0.313rem;
}

.finish_cut .from_to input,
.finish_polish .from_to input,
.finish_symmetry .from_to input {
  max-width: 148px;
  width: 100%;
}

.search_Fluorescence .color_drop p {
  color: var(--black-color);
  font-size: var(--body16);
  line-height: 1.5;
  font-weight: var(--font-weight-regular);
  margin-bottom: 0.5rem !important;
}

/* .search_Fluorescence_list {
  border-right: 1px solid var(--stroke-color);
} */

.search_Fluorescence,
.grading_report,
.search_finish,
.search_clarity,
.color_con,
.search_price,
.loc_source,
.search_media,
.advance_shade,
.advance_brand,
.advance_inclusion {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid var(--stroke-color);
}

.grading_input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.grading_input input {
  max-width: 650px;
  width: 100%;
}

ul.grading_report_list {
  margin-bottom: 1.875rem !important;
}

.loc_source p {
  margin-bottom: 0.5rem !important;
}

.loc_source select {
  max-width: 350px;
  width: 100%;
}

.loc_source_select {
  border-right: 1px solid var(--stroke-color);
}

.search_price .price_dis_list {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-right: 1px solid var(--stroke-color);
}

.search_price .price_dis_list:nth-last-child(3) {
  border-right: none !important;
}

/* .search_price .price_dis_list:nth-child(3){
  border-right: none !important;
} */

.search_price .price_dis input {
  max-width: 190px;
  width: 100%;
}

ul.search_media_list {
  /* display: flex; */
  gap: 20px;
  padding: 0;
}

.search_media_list .media_label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.313rem;
}

.search_media_list .media_label img {
  width: 1.125rem;
  height: 1.125rem;
}

.media_list_item {
  max-width: 485px;
  width: 100%;
}

.media_list_item:nth-child(2) {
  padding-left: 3.125rem !important;
}

.media_column {
  border-right: 1px solid var(--stroke-color);
}

.media_column:nth-child(3) {
  border-right: 0;
}

.measure_treat .measure_treat_title {
  display: flex;
  gap: 0.625rem;
  align-items: center;
}

.measure_treat .accordion-button:not(.collapsed) {
  background-color: rgba(188, 146, 63, 0.2);
  border-radius: var(--radius-border);
  box-shadow: none;
  padding: 0.563rem 0.75rem;
  border: 1px solid transparent !important;
  color: var(--primary-color);
}

.measure_treat .accordion-button {
  background-color: var(--page-color);
  border: 1px solid var(--stroke-color) !important;
  padding: 0.563rem 0.75rem;
  border-radius: var(--radius-border);
}

.measure_treat_title h6 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  /* color: var(--primary-color); */
}

.accordion-body {
  /* padding-top: 1.875rem; */
  padding: 1.875rem 0;
  background-color: var(--page-color) !important;
}

.measure_list {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.col-12.col-md-4:not(:last-child) .measure_list,
.col-12.col-md-4:not(:last-child) .price_dis_list,
.col-12.col-md-3:not(:last-child) .inclusions_select {
  border-right: 1px solid var(--stroke-color);
}

.col-md-6:not(:last-child) .crown_list {
  border-right: 1px solid var(--stroke-color) !important;
}

.measure_list input {
  max-width: 203px;
  width: 100%;
}

.measure_treat .input_pos {
  position: relative;
}

.measure_treat .mm_pos,
.measure_treat .angel_pos,
.buyRequest_modal .measure_treat .mm_pos {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: var(--body12);
  color: var(--secondary-color);
}

.measure_treat .angel_pos {
  top: 13px;
}

.measurements_list {
  margin-bottom: 1.875rem;
}

.measure_list_item input[type="radio"] {
  display: none;
}

.measure_list_item {
  display: flex;
  margin-bottom: 0 !important;
  padding: 0;
  gap: 0.5rem;
}

.crown_list input {
  max-width: 110px;
  width: 100%;
}

/* .crown_list{
  border-right: 1px solid var(--stroke-color);
} */

/* .crown_list:last-child {
  border-right: var(--page-color) !important;
} */

.measure_treat_crown,
.Gridle_culet {
  margin-bottom: 1.25rem;
}

.treatment_checklist {
  display: flex;
  gap: 0.625rem;
  align-items: baseline;
}

.inclusions_select {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  /* border-right: 1px solid var(--stroke-color); */
}

.inclusions_select_list {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

.inclusions_select_list select {
  width: 140px;
  /* width: 100%; */
}

.inclusions_select select {
  max-width: 300px;
  width: 100%;
}

.svg_icon_acco {
  transition: fill 0.3s ease;
  width: 24px;
}

.svg_icon_acco.collapsed {
  filter: brightness(0) saturate(100%) invert(69%) sepia(44%) saturate(594%) hue-rotate(2deg) brightness(81%) contrast(82%);
}

.svg_icon_acco.expanded {
  filter: brightness(0) saturate(100%) invert(0%) sepia(1%) saturate(7500%) hue-rotate(177deg) brightness(98%) contrast(98%);
}

.save_modal .save_modallist {
  margin-bottom: 1.25rem;
}

.save_modal .save_modaltitle {
  color: var(--primary-color);
  /* text-align: center; */
  justify-content: center;
  cursor: pointer;
  font-size: var(--body30);
  line-height: 1.4;
}

.save_modal .modal-header {
  border: none;
  padding: 1.25rem;
}

.save_modal .modal-body {
  padding: 0 1.25rem;
}

.save_modal .save_modalbtn {
  /* width: 50%; */
  flex: 1;
  padding: 0.5rem 0.75rem;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.save_modal .save_modalbtn:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.repspec_select+.select2-container {
  width: 124px !important;
  /* width: 100%; */
}

.Fluorescence_select+.select2-container {
  width: 330px !important;
  height: 36px !important;
}

.shape_stone_selected {
  display: flex;
  gap: 0.625rem;
  padding-bottom: 1.25rem;
}

.shape_stone_selected_item {
  display: flex;
  background: var(--white-color);
  border-radius: 3.125rem;
  gap: 0.313rem;
  padding: 0.219rem 0.625rem;
  font-size: var(--body12);
}

.shape_stone_selected_item .addClose_icon {
  cursor: pointer;
}

.loadSave {
  min-width: 190px;
  /* width: 100%; */
  height: 44px !important;
}

.Rapspec {
  min-width: 125px;
  width: 100%;
  height: 44px !important;
}

.eyeClean_select {
  max-width: 330px;
  width: 100%;
}

.loc_source_select_list {
  max-width: 349px !important;
  width: 100%;
}

.milky_Select {
  min-width: 141px !important;
  width: 100%;
}

.black_white {
  min-width: 297px;
}

.shape_stone_Check li.label-wrapper input[type="checkbox"] {
  display: none !important;
}

.custom-button {
  background-color: var(--primary-color);
  border: none;
  color: var(--white-color);
  padding: 0.625rem 1.25rem;
  border-radius: var(--radius-border);
  cursor: pointer;
}

.custom-button:hover {
  background-color: var(--primary-color);
}

/* search stock page */

/* 22-5-2024 */

.tr__searchStock .searchStock_title,
.tr__calculator .calculator_title,
.tr__profile .tr__profile_title,
.tr__compareDimond .tr__compareDimond_title,
.tr__buyRequest .buyRequest_title,
.tr__saveSearch .tr__saveSearch_title,
.tr__trackDiamond .trackDimond_title,
.tr__trackeDimond_list .trackDimond_title,
.tr__rapaPort .tr__rapaPort_title {
  display: flex;
  gap: 0.625rem;
}

.tr__searchStock .searchStock_title h1,
.tr__calculator .calculator_title h1,
.tr__profile .tr__profile_title h1,
.tr__compareDimond .tr__compareDimond_title h1,
.tr__buyRequest .buyRequest_title h1,
.tr__saveSearch .tr__saveSearch_title h1,
.tr__trackDiamond .trackDimond_title h1,
.tr__trackeDimond_list .trackDimond_title h1,
.tr__rapaPort .tr__rapaPort_title h1,
.tr__address_selection h1 {
  font-size: var(--h5);
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
}

/* .tr__searchStock .searchStock_btns {
  display: flex;
  gap: 0.938rem;
  justify-content: end;
  align-items: flex-start;
} */

/* .tr__searchStock .loadSearch,
.tr__searchStock .Rapspec {
  width: 100%;
  padding: 0.625rem;
  font-size: var(--body16);
} */
.tr__searchStock .loadSearch {
  max-width: 190px;
}

.tr__searchStock .Rapspec {
  max-width: 125px;
}

/* diamonds list start */

.diamondList_container {
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  padding-bottom: 0.938rem;
  border-bottom: 1px solid var(--stroke-color);
}

.page-title {
  display: flex;
  gap: 0.625rem;
}

.page-title h1 {
  font-size: var(--h5);
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
  font-family: var(--secondry-font);
}

.status_container {
  width: 20px;
  height: 20px;
  border-radius: var(--radius-border);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  font-size: var(--body14);
}

.cart_leftAction p {
  font-size: var(--body14);
}

.available_status {
  background-color: var(--green-color);
  font-size: var(--body12);
  line-height: 1.4;
}

.memo_status {
  background-color: var(--primary-color);
}

.hold_status {
  background-color: var(--red-color);
}

.option_container {
  /* min-width: 2.5rem; */
  /* min-height: 2.5rem; */
  padding: 0.5rem;
  border-radius: var(--radius-border);
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 0.938rem; */
}

.tr__cart .more_option,
.tr__watchlist .more_option {
  /* margin-left: 0.938rem; */
  width: 6.063rem;
}

.grid_selection_container {
  width: 76px;
  height: 40px;
  border-radius: var(--radius-border);
  padding: 0.125rem;
  /* margin-left: 15px; */
  display: flex;
  align-items: center;
  background-color: var(--white-color);
}

.selected_grid {
  background-color: var(--primary-color);
  width: 36px;
  height: 36px;
  border-radius: var(--radius-border);
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected_grid svg path {
  stroke: var(--white-color);
}

.not_selected_grid svg path {
  stroke: var(--primary-color);
}

.not_selected_grid {
  background-color: var(--white-color);
  width: 36px;
  height: 36px;
  border-radius: var(--radius-border);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* diamonds list end */

/* 29-5-2024 */

.tr_calculator_table {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: var(--radius-border);
}

.tr__calculator ul.calculator_rightList_item,
.tr__tradeCenter .tradeCenter_rightList_item,
.tr__notificationCenter .notificationCenter_rightList_item,
.tr__pair .pair_rightList_item {
  display: flex;
  gap: 0.938rem;
  margin: 0;
  justify-content: end;
}

.tr__calculator .calculator_rightList_item li,
.tr__tradeCenter .tradeCenter_rightList_item li,
.tr__notificationCenter .notificationCenter_rightList_item li,
.tr__pair .pair_rightList_item li {
  background: var(--white-color);
  padding: 0.563rem;
  border-radius: var(--radius-border);
}

.tr__calculator .calculator_rightList_item li:hover,
.tr__tradeCenter .tradeCenter_rightList_item li:hover,
.tr__notificationCenter .notificationCenter_rightList_item li:hover,
.option_container:hover,
.tr__pair .pair_rightList_item li:hover {
  background-color: var(--primary-color);
}

.tr__trackeDimond_list .trackeDimond_rightList_item li:hover .calculator_icon,
.tr__calculator .calculator_rightList_item li:hover .calculator_icon,
.tr__calculator .calculator_rightList_item li:hover .list_icon,
.tr__tradeCenter .tradeCenter_rightList_item li:hover .trade_icon,
.tr__tradeCenter .tradeCenter_rightList_item li:hover .list_icon,
.tr__notificationCenter .notificationCenter_rightList_item li:hover .noti_icon,
.option_container:hover img,
.tr__pair .pair_rightList_item li:hover .trade_icon {
  filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(0%) hue-rotate(161deg) brightness(101%) contrast(102%);
}

.tr__calculator .tr__calculator_top,
.tr__tradeCenter .tr__tradeCenter_top,
.tr__profile .tr__profile_title,
.tr__compareDimond .tr__compareDimond_title,
.tr__notificationCenter .tr__notificationCenter_top {
  padding-bottom: 1.094rem;
  border-bottom: 1px solid var(--stroke-color);
  margin-bottom: 1.25rem;
}

.tr__tradeCenter .tradeCenter_title,
.tr__notificationCenter .notificationCenter_title {
  display: flex;
  gap: 0.625rem;
  align-items: center;
}

.tr__tradeCenter .viewList,
.tr__pair .viewList {
  background-color: var(--white-color);
  /* padding: 0.563rem; */
  display: flex;
  border-radius: var(--radius-border);
}

.tr__tradeCenter .tradeCenter_title h1,
.tr__notificationCenter .notificationCenter_title h1 {
  font-size: var(--h5);
  font-weight: var(--font-weight-bold);
  color: var(--black-color);
}

.tr__tradeCenter_list {
  display: flex;
  /* gap: 1.875rem; */
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin: 0 -0.9375rem;
  row-gap: 1.875rem;
}

.tr__tradeCenter_item {
  padding: 0 0.9375rem;
  /* max-width: 292px; */
  /* width: 100%; */
  border-radius: var(--radius-border);
  /* flex: 0 0 292px; */
  flex: 0 0 20%;
  /* flex: 1; */
  /* max-width: 292px; */
  overflow: hidden;
}

.tr__tradeCenter_img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--light-color);
  padding: 0.938rem 0;
  border-radius: var(--radius-border) var(--radius-border) 0 0;
  position: relative;
  overflow: hidden;
}

.tr__tradeCenter_img img {
  max-width: 150px;
  min-height: 150px;
  max-height: 150px;
  /* width: 100%; */
}

.trade_badge {
  position: absolute;
  top: 0.938rem;
  right: 0.938rem;
  font-size: var(--body12);
  line-height: 0.908;
  color: var(--green-color);
  background-color: rgba(4, 109, 21, 0.2);
  padding: 0.25rem 0.563rem;
  border-radius: 1.688rem;
}

.tr__tradeCenter_det {
  background: var(--white-color);
  padding: 0.938rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  border-radius: 0 0 var(--radius-border) var(--radius-border);
}

.tradeCenter_det_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--body14);
  line-height: 1.4;
}

.tradeCenter_det_size {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tradeCenter_price,
.tradeCenter_location {
  display: flex;
  gap: 0.313rem;
  align-items: center;
}

.tradeCenter_det_title h6 {
  font-weight: var(--font-weight-medium);
  font-size: var(--body14);
  line-height: 1.75;
  color: var(--black-color);
}

.tradeCenter_det_title p {
  color: var(--red-color);
  border-radius: 1.688rem;
  font-size: var(--body12);
  padding: 0.25rem 0.563rem;
  background-color: rgba(255, 44, 72, 0.2);
}

.tradeCenter_det_size p span {
  font-size: var(--body14);
  line-height: 1.25;
}

.tradeCenter_det_size .trade_none {
  font-size: var(--body12);
  line-height: 0.906;
  border: 1px solid var(--stroke-color);
  border-radius: var(--radius-border);
  padding: 0.313rem 0.375rem;
}

.trade_listIcon {
  background-color: rgba(188, 146, 63, 0.2);
  padding: 0.188rem 0.2rem;
  border-radius: 50%;
  display: inline-flex;
}

.tradeCenter_location p,
.tradeCenter_price p {
  font-size: var(--body14);
  line-height: 1.25;
  font-weight: var(--font-weight-regular);
  color: var(--black-color);
}

.media_container {
  position: relative;
}

.trade_video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
}

.trade_video .video_img {
  width: 80px;
  min-height: 80px;
}

.tr__tradeCenter_img:hover .trade_video {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tr__tradeCenter_img:hover .video_badge {
  position: absolute;
  top: 0.938rem;
  right: 0.938rem;
  font-size: var(--body12);
  line-height: 0.908;
  color: var(--white-color);
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.25rem 0.563rem;
  border-radius: 1.688rem;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.video_img img {
  width: 50px;
  height: 50px;
}

@media (max-width: 1650px) {
  .tr__tradeCenter_item {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

@media (max-width: 1400px) {
  .tr__tradeCenter_item {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
}

@media (max-width: 875px) {
  .tr__tradeCenter_item {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (max-width: 575px) {
  .tr__tradeCenter_item {
    max-width: 100%;
    flex: 100%;
  }
}

.shape_stone_selected_item {
  display: flex;
  background: var(--white-color);
  border-radius: 3.125rem;
  gap: 0.313rem;
  padding: 0.219rem 0.625rem;
  font-size: var(--body12);
  line-height: 1.4;
}

.shape_stone_selected_item .addClose_icon {
  cursor: pointer;
}

.loadSave {
  min-width: 190px;
  /* width: 100%; */
  height: 44px !important;
}

.Rapspec {
  min-width: 125px;
  width: 100%;
  height: 44px !important;
}

.loc_source_select_list {
  max-width: 349px !important;
  width: 100%;
}

.milky_Select {
  min-width: 141px !important;
  width: 100%;
}

.include_select {
  width: 100%;
}

/* calculator  */

/* 28-5-2024 */

.tr_calculator_table {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: var(--radius-border);
}

.tr__calculator ul.calculator_rightList_item,
.tr__trackeDimond_list .trackeDimond_rightList_item {
  display: flex;
  gap: 0.938rem;
  margin: 0;
  justify-content: end;
  flex-wrap: wrap;
}

.tr__calculator .calculator_rightList_item li,
.tr__trackeDimond_list .trackeDimond_rightList_item li {
  background: var(--white-color);
  padding: 0.563rem;
  border-radius: var(--radius-border);
}

.tr__calculator .calculator_rightList_item li:hover,
.tr__trackeDimond_list .trackeDimond_rightList_item li:hover {
  background-color: var(--primary-color);
}

.tr__calculator .calculator_rightList_item li:hover .calculator_icon,
.tr__calculator .calculator_rightList_item li:hover .list_icon,
.tr__support .tr__help_wrap:hover .help_icon,
.tr__trackeDimond_list .trackeDimond_rightList_item li:hover .list_icon {
  filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(0%) hue-rotate(161deg) brightness(101%) contrast(102%);
}

.tr__calculator .tr__calculator_top {
  padding-bottom: 1.094rem;
  border-bottom: 1px solid var(--stroke-color);
  margin-bottom: 1.25rem;
}

.tr__calculator .viewList,
.tr__trackeDimond_list .viewList {
  background-color: var(--white-color);
  /* padding: 0.563rem; */
  display: flex;
  border-radius: var(--radius-border);
}

.tr__calculator .calc_header {
  background-color: var(--page-color);
}

/* 30-5-2024 */

/* support */

.tr__support_help {
  text-align: center;
}

.tr__support .tr__help_title h1 {
  font-size: var(--h3);
  font-weight: var(--font-weight-bold);
  line-height: 1.5;
}

.tr__support .tr__help_list {
  padding: 1.875rem 0 3.75rem;
  display: flex;
  /* gap: 1.875rem; */
  flex-wrap: wrap;
  margin: 0 -0.9375rem !important;
  row-gap: 1.875rem;
}

.tr__support .tr__help_wrap {
  padding: 0 0.9375rem;
  flex: 0 0 20%;
}

.tr__help_wrap .tr__help_item:hover,
.tr_addInfo .tr__addInfo_item:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.tr__help_wrap .tr__help_item:hover h6,
.tr_addInfo .tr__addInfo_item:hover h5,
.tr_addInfo .tr__addInfo_item:hover p {
  color: var(--white-color);
}

.tr__addInfo_item_img img {
  /* max-width: 100%; */
  max-height: 100px;
  min-width: 100px;
}

.tr__help_item_img img {
  max-width: 80px;
  max-height: 80px;
}

.tr__help_item h6 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  margin-top: 0.625rem;
}

.tr__help_wrap .tr__help_item {
  background-color: var(--white-color);
  padding: 0.938rem;
  border-radius: var(--radius-border);
  transition: all ease-in-out 0.5s;
}

.tr_addInfo {
  text-align: center;
}

.tr_addInfo .tr_addInfo_heading h4 {
  font-size: var(--h4);
  font-weight: var(--font-weight-bold);
  line-height: 1.5;
  color: var(--black-color);
  margin-bottom: 0.625rem !important;
}

.tr_addInfo .tr_addInfo_heading p {
  font-size: var(--body16);
  line-height: 1.625;
  color: var(--secondary-color);
  font-weight: var(--font-weight-regular);
}

.tr_addInfo .tr__addInfo_list {
  padding: 3.75rem 0 0.75rem;
  display: flex;
  /* gap: 1.875rem; */
  flex-wrap: wrap;
  margin: 0 -0.9375rem !important;
  row-gap: 1.875rem;
}

.tr_addInfo .tr__addInfo_wrap {
  padding: 0 0.9375rem;
  flex: 0 0 50%;
}

.tr_addInfo .tr__addInfo_item {
  background-color: var(--white-color);
  padding: 1.875rem;
  border-radius: var(--radius-border);
  transition: all ease-in-out 0.5s;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.tr_addInfo .tr__addInfo_item h5 {
  font-size: var(--h5);
  line-height: 1.4;
  font-weight: var(--font-weight-bold);
  color: var(--black-color);
}

.tr_addInfo .tr__addInfo_item p {
  color: var(--secondary-color);
  font-size: var(--body20);
  line-height: 1.5;
  font-weight: var(--font-weight-regular);
}

@media (max-width: 1400px) {
  .tr__support .tr__help_wrap {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .tr_addInfo .tr__addInfo_wrap {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
}

@media (max-width: 991px) {
  .tr__support .tr__help_wrap {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .tr_addInfo .tr__addInfo_wrap {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (max-width: 575px) {
  .tr__support .tr__help_wrap {
    max-width: 100%;
    flex: 100%;
  }

  .tr_addInfo .tr__addInfo_wrap {
    max-width: 100%;
    flex: 100%;
  }
}

/* 31-5-2024 */

/* profile page */

.tr__profile_details .tr__profile_details_wrap {
  background-image: url(../svgs/profile/profileBg.png);
  width: 100%;
  background-size: 100% 45%;
  background-repeat: no-repeat;
  /* height: 111px; */
  border-radius: var(--radius-border) var(--radius-border) 0 0;
}

.tr__profile .tr__profile_img {
  /* padding: 1.25rem 1.875rem; */
  margin: 1.25rem 1.875rem;
  position: relative;
  /* width: 40px; */
  /* height: 40px; */
  border-radius: 50%;
  overflow: hidden;
  background: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tr__profile_det_wrapper {
  display: flex;
  align-items: center;
}

.tr__profile_id {
  width: fit-content;
  background: var(--white-color);
  padding: 0.25rem 0.938rem;
  border-radius: 2.688rem;
  margin-bottom: 1.25rem;
}

.tr__profile_name h5 {
  font-size: var(--h5);
  font-weight: var(--font-weight-bold);
  color: var(--black-color);
  margin-bottom: 0.625rem !important;
}

.tr__profile_det {
  font-size: var(--body14);
}

.tr__profile_info {
  flex: 2;
  margin-top: 2.5rem;
}

.tr__profile_det {
  font-size: var(--body14);
  display: flex;
  gap: 1.875rem;
  flex-wrap: wrap;
}

.tr__profile_dealer,
.tr__profile_buyerAc,
.tr__profile_member,
.tr__profile_membership {
  padding: 0.188rem 0.625rem;
  width: fit-content;
  border-radius: 3.125rem;
}

.tr__profile_dealer {
  color: var(--green-color);
  background-color: rgba(4, 109, 21, 0.2);
}

.tr__profile_member {
  color: var(--red-color);
  background-color: rgba(255, 44, 72, 0.2);
}

.tr__profile_buyerAc {
  background-color: var(--white-color);
}

.tr__profile_membership {
  background-color: rgba(0, 0, 0, 0.2);
}

.tr__company_about {
  padding: 3.125rem 1.875rem 1.25rem;
  border-bottom: 1px solid var(--stroke-color);
}

.tr__Endorsement {
  padding: 3.125rem 1.875rem 1.25rem;
}

.tr__company_title h6,
.tr__about_title h6,
.tr__rat_feed_title h6 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  line-height: 1.125;
  margin-bottom: 0.938rem !important;
  color: var(--black-color);
}

.tr__company_item {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  margin-bottom: 1.25rem;
}

.tr__company_item:nth-child(2) {
  border-right: 1px solid var(--stroke-color);
}

.company_icon,
.about_icon {
  padding: 0.5rem;
  background: rgba(188, 146, 63, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company_icon img {
  width: 24px;
  height: 24px;
}

.about_icon {
  cursor: pointer;
}

.about_icon {
  cursor: pointer;
}

.about_icon .com_icon {
  width: 24px;
  height: 24px;
}

.tr__about_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about_det {
  margin-bottom: 0.938rem;
}

.about_det p {
  font-size: var(--body16);
  line-height: 1.625;
  white-space: nowrap;
}

.tr__rat_feed {
  padding: 1.25rem 1.875rem;
  border-bottom: 1px solid var(--stroke-color);
}

.rating_item {
  display: flex;
  gap: 0.625rem;
  align-items: center;
}

.rating_list {
  display: flex;
  gap: 1.25rem;
  margin-bottom: 0.938rem;
}

.rating_icon {
  padding: 0.5rem;
  border-radius: 50%;
}

.rating_year {
  display: flex;
  margin-bottom: 0.938rem;
  gap: 1.25rem;
  border-right: 1px solid var(--stroke-color);
}

.feedback_det {
  border-right: 1px solid var(--stroke-color);
}

.rating_year a {
  color: var(--black-color);
}

.star_icon {
  background: rgba(188, 146, 63, 0.2);
}

.like_icon {
  background-color: rgba(4, 109, 21, 0.2);
}

.like_icon {
  background-color: rgba(4, 109, 21, 0.2);
}

.dislike_icon {
  background-color: rgba(255, 44, 72, 0.2);
}

.feedback_det p {
  font-size: var(--body16);
  line-height: 1.625;
}

.feed_popup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feed_popup h6 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
}

.rating_year_hidden {
  display: none;
}

.active_rateTab {
  color: var(--primary-color) !important;
  /* text-decoration: underline !important; */
  border-bottom: 1px solid var(--primary-color);
}

.rating_year p {
  padding-bottom: 0.313rem;
  font-size: var(--body16);
  line-height: 1.625;
}

@media (max-width: 1850px) {
  .about_det p {
    white-space: normal;
  }
}

.endorsement_title,
.feedback_title,
.editCompany_title,
.track_deleteModal h5 {
  font-size: var(--h5);
  font-weight: var(--font-weight-bold);
  color: var(--black-color);
}

.cancel_btn_popup {
  background-color: var(--red-color);
  border: none;
  width: 100%;
}

.cancel_btn_popup.active {
  background-color: var(--red-color);
}

.cancel_btn_popup:hover {
  background-color: var(--red-color);
}

.req_btn_popup {
  background-color: var(--primary-color);
  border: none;
  width: 100%;
}

.req_btn_popup:hover {
  background-color: var(--primary-color);
}

.editCompany_popup .modal-dialog {
  width: 700px;
  max-width: 100%;
}

.discard_btn_popup,
.saveChanges_btn_popup {
  padding: 0.365rem 0.625rem;
}

/* .tr__about_title {
  border-right: 1px solid var(--stroke-color);
} */

.tr__profile_det div:not(:last-child):after {
  content: " ";
  position: absolute;
  width: 1px;
  height: 100%;
  background: var(--stroke-color);
  right: -15px;
  top: 0;
}

.tr__profile_det div {
  position: relative;
}

@media (max-width: 768px) {

  .rating_year,
  .feedback_det,
  .tr__about_title,
  .tr__company_item:nth-child(2) {
    border-right: none;
  }
}

/* 3-6-2024 */

/* compare dimond  */

.tr__compareDimond .compareDimond_list_item {
  background: var(--white-color);
  text-align: center;
  border-radius: var(--radius-border);
  box-shadow: rgba(0, 0, 0, 0.2);
  min-width: 257px;
  width: 100%;
  /* flex-shrink: 0; */
}

.tr__compareDimond .compareDimond_list {
  padding: 0.781rem;
  border-bottom: 1px solid var(--stroke-color);
}

.tr__compareDimond .compareDimond_head {
  padding: 0.61rem 0.75rem;
  background: var(--primary-color);
  color: var(--white-color);
  border-radius: 5px 5px 0 0;
}

.tr__compareDimond .compareDimond_head p {
  font-size: var(--body14);
  line-height: 1.625;
  font-weight: var(--font-weight-medium);
}

.compareDimond_img {
  height: 100px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--stroke-color);
}

.compareDimond_list p {
  font-size: var(--body12);
  line-height: 0.938;
  color: var(--black-color);
}

.compareDimond_list:nth-child(odd) {
  background-color: #f4f4f4;
}

/* .compareDimond_list:nth-child(even) {
  background-color: #e0e0e0;
} */

.compareDimond_head_Del {
  display: flex;
  /* justify-content: space-around; */
  justify-content: space-between;
}

.delete_com {
  background-color: var(--black-color);
  /* border-radius: 0.875rem 0.875rem 0.938rem 0.938rem; */
  border-radius: 50%;
  padding: 0.1rem 0.25rem;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
}

.tr__compareDimond_list {
  display: flex;
  gap: 1.25rem;
}

.compareDimond_title {
  position: sticky;
  left: 0;
  z-index: 1;
}

.compareDimond_list_container {
  display: flex;
  overflow-x: auto;
  cursor: pointer;
  -webkit-overflow-scrolling: touch;
}

.tr__compareDimond_list_item {
  display: flex;
  gap: 1.25rem;
}

/* lost connection */

.tr__lostConnection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.25rem;
  /* height: 90vh; */
  height: 800px;
}

.tr__lostConnection_img img {
  max-width: 100%;
  width: 100%;
}

/* buy request */

.buyRequest_right_menu {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.938rem;
}

.buyRequest_center_list {
  /* padding: 0 1.875rem; */
  font-size: var(--body14);
  font-weight: var(--font-weight-smedium);
  color: var(--black-color);
}

/* .buyRequest_center_list:nth-child(1){
  border-right: 1px solid var(--stroke-color);
} */

.filter_btn {
  background-color: var(--white-color);
  border-radius: var(--radius-border);
  padding: 0.625rem 1.375rem;
  font-size: var(--body16);
  font-weight: var(--font-weight-regular);
  line-height: 1.188;
  color: var(--black-color);
}

.Request_btn {
  display: flex;
  align-items: center;
  border-radius: var(--radius-border);
  font-weight: var(--font-weight-regular);
  gap: 0.5rem;
  color: var(--white-color);
  background-color: var(--primary-color);
  padding: 0.49rem 1.375rem;
  font-size: var(--body16);
  line-height: 1.188;
}

.Request_btn:hover {
  background-color: var(--white-color);
  color: var(--primary-color);
}

.Request_btn:hover img {
  filter: brightness(0) saturate(100%) invert(54%) sepia(65%) saturate(391%) hue-rotate(2deg) brightness(96%) contrast(89%);
}

.buyReq {
  padding: 0.625rem 0.75rem;
}

@media (max-width: 768px) {
  .buyRequest_right_menu {
    justify-content: end;
    gap: 0.313rem;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0.625rem;
  }

  .Request_btn {
    padding: 0.43rem 0.313rem;
  }

  .buyRequest_right_menu {
    margin-top: 0.625rem;
  }
}

/* 5-6-2024 */

.buyRequest_action,
.saveSearch_action,
.trackDiamond_action {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  gap: 0.938rem;
  padding-right: 0.938rem;
}

/* filter popup */

.filter_modal {
  width: 300px !important;
}

.filter_modal h6 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  line-height: 1.75;
}

.filterModal_btns {
  /* width: 100%; */
  display: flex;
  gap: 0.938rem;
  margin-bottom: 0.938rem;
  position: sticky;
  top: 0px;
  z-index: 9;
  background: var(--white-color);
}

.filterModal_btns .resetFilter_btn,
.filterModal_btns .applyFilter_btn {
  font-size: var(--body14);
  font-weight: var(--font-weight-regular);
  line-height: 1.063;
  padding: 0.5rem;
  width: 100%;
}

.filterModal_btns .resetFilter_btn {
  background-color: var(--red-color);
  border: none;
}

.filterModal_btns .applyFilter_btn {
  background-color: var(--black-color);
  border: none;
}

.filterModal_title {
  font-size: var(--body14);
  line-height: 1.25;
  font-weight: var(--font-weight-regular);
  font-family: var(--primary-font);
  color: var(--black-color);
}

.filter_modal .accordion-button {
  padding: 0.313rem;
  background-color: var(--white-color);
  border-bottom: 1px solid var(--stroke-color);
}

.filter_modal .filterModal_aco .accordion-item {
  border: none !important;
  margin-bottom: 1.25rem;
}

.filter_modal .accordion-body {
  background-color: var(--white-color) !important;
  padding: 0.625rem 0;
}

.filter_modal .filterModal_check {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.filter_modal .price_filter,
.filter_modal .size_filter {
  display: flex;
  align-items: center;
  gap: 0.313rem;
  margin-bottom: 0.938rem;
}

.filter_modal .price_filter .from_to,
.filter_modal .size_filter .from_to {
  gap: 0.313rem;
}

.filter_modal .price_filter p {
  min-width: 45px;
}

.filter_modal .filterTitlecheck {
  display: flex;
  gap: 3.125rem;
  align-items: end;
}

.filterSelect,
.Flurorescence_select {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
  gap: 0.313rem;
}

.filterSelect p {
  min-width: 72px;
  font-size: var(--body14);
}

.filterSelect .css-b62m3t-container,
.Flurorescence_select .css-b62m3t-container {
  width: 100%;
}

/* buy request modal */

.buyRequest_modal {
  width: 1200px !important;
}

.buyRequest_modal .buyRequest_title {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  margin-bottom: 1.438rem;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid var(--stroke-color);
}

.buyRequest_modal .buyRequest_btns {
  display: flex;
  gap: 1.25rem;
}

.buyRequest_btns .cancelBuy_btn,
.buyRequest_btns .saveBuy_btn {
  font-size: var(--body14);
  font-weight: var(--font-weight-regular);
  line-height: 1.063;
  color: var(--white-color);
  padding: 0.64rem 2.938rem;
  border-radius: var(--radius-border);
  width: 140px;
  transition: all ease-in-out 0.7s;
  border: 1px solid transparent;
  text-align: center;
}

.buyRequest_btns .saveBuy_btn:hover {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background-color: var(--white-color);
}

.buyRequest_btns .cancelBuy_btn:hover {
  border: 1px solid var(--red-color);
  color: var(--red-color);
  background-color: var(--white-color);
}

.buyRequest_btns .cancelBuy_btn {
  background-color: var(--red-color);
}

.buyRequest_btns .saveBuy_btn {
  background-color: var(--primary-color);
}

.buyRequest_title h5 {
  font-size: var(--h5);
  font-weight: var(--font-weight-bold);
  color: var(--black-color);
}

.buyRequest_modal .inclusions_list {
  display: flex;
  gap: 0.938rem;
}

.buyRequest_modal .inclusions_list_item {
  flex: 1;
}

.buyRequest_modal .milky_select {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.inclusions_list_item p,
.price_list_item p,
.location_item p,
.buyRequest_type_item p,
.specify_list_item p,
.buyRequest_modal .measure_list p {
  font-size: var(--body16);
  font-weight: var(--font-weight-regular);
  line-height: 1.5;
  margin-bottom: 0.313rem !important;
  color: var(--black-color);
}

.buyRequest_inclusions h6,
.buyRequest_price h6 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  margin-bottom: 0.625rem !important;
}

.buyRequest_inclusions,
.buyRequest_price,
.buyRequest_location,
.buyRequest_type,
.buyRequest_specify_wrap {
  padding: 0.938rem;
  background: var(--light-color);
  margin-bottom: 0.938rem;
}

.buyRequest_modal .css-1jqq78o-placeholder {
  font-size: var(--body16);
  line-height: 1.25;
  font-weight: var(--font-weight-regular);
}

.price_list_item .from_to {
  gap: 0.313rem;
}

/* 6-6-2024 */

/* save search */

.tr__saveSearch_action {
  display: flex;
  justify-content: end;
  gap: 1.25rem;
}

.tr__saveSearch_save p:nth-child(1) {
  font-size: var(--body20);
  line-height: 1.4;
  font-weight: var(--font-weight-regular);
  color: var(--black-color);
}

.tr__saveSearch_save p:nth-child(2) {
  font-size: var(--body14);
  line-height: 1.25;
  color: var(--secondary-color);
}

.tr__saveSearch_tableTitle {
  margin-bottom: 1.25rem;
}

.saveSearch_btn {
  cursor: pointer;
}

/* topbar menu css 6-6-2024 */
.tr__topmenus {
  /* padding-left: 1rem; */
  padding-left: 6.25rem;
}

/* stone details */

.shadow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08) !important;
}

.stone_details .content_wrapper {
  margin-top: 1.25rem;
}

.stone_details .form-check .form-check-input {
  transition: all 0.5s ease-in-out;
}

.stone_details .form-check .form-check-input[type="radio"]:checked+label {
  color: var(--primary-color);
}

.stone_details .table_wrapper {
  border-radius: var(--radius-border);
  /* overflow: hidden; */
  overflow-x: auto;
}

.stone_details .table_wrapper table tr {
  font-size: 0.875rem;
  transition: all 0.5s ease-in-out;
  background: var(--white-color) !important;
}

.stone_details .table_wrapper table thead tr,
.stone_details .table_wrapper table thead tr:hover {
  font-size: 0.875rem;
  transition: all 0.5s ease-in-out;
  background: #f4f4f4 !important;
}

.row.mt-2.justify-content-between,
.row.mt-2.justify-content-md-center {
  margin: 0 !important;
}

.row.mt-2.justify-content-md-center .col-12 {
  padding: 0;
}

.stone_details .table_wrapper table tr:hover {
  background: #c8c8c8 !important;
}

.stone_details .table_wrapper table tr th {
  background: transparent !important;
  border-color: var(--stroke-color) !important;
  text-align: start !important;
}

.stone_details .table_wrapper table tr th:hover {
  offset: 0 !important;
  outline: 0 !important;
}

.stone_details .table_wrapper table td {
  border: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
  transition: all 0.5s ease-in-out;
  text-align: start !important;
}

.stone_details .table_wrapper table tbody tr:nth-child(odd) {
  background: var(--white-color) !important;
}

.stone_details .table_wrapper table tbody tr:nth-child(even) {
  background: #f4f4f4 !important;
}

.stone_details .table_wrapper table tbody tr:hover {
  background: #c8c8c8 !important;
}

.stone_details .table_wrapper .dt-layout-row {
  display: none;
}

.stone_details .table_wrapper .dt-layout-cell {
  padding: 0;
}

.stone_details .table_wrapper .sorting_1:hover {
  box-shadow: none !important;
}

.stone_details .swiper-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.stone_details .swiper-slide {
  background-size: cover;
  background-position: center;
}

.stone_details .swiper-button-prev,
.stone_details .swiper-button-next {
  background-image: none;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  background-color: rgba(188, 146, 63, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}

.stone_details .swiper-button-prev:hover,
.stone_details .swiper-button-next:hover {
  background-color: var(--primary-color);
}

.stone_details .swiper-button-prev:hover svg path,
.stone_details .swiper-button-next:hover svg path {
  stroke: var(--white-color);
}

.stone_details .gallery-top {
  width: 100%;
}

.stone_details .gallery-thumbs {
  box-sizing: border-box;
  overflow-x: auto;
}

.stone_details .gallery-thumbs .swiper-slide {
  width: 100%;
  height: 100%;
  max-width: 110px;
}

.stone_details .gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.stone_details .gallery-thumbs .swiper-slide {
  border: 1px solid var(--stroke-color);
  border-radius: var(--radius-border);
  overflow: hidden;
  transition: all ease-in-out 0.5s;
  min-width: 107px;
  max-width: 107px;
}

.stone_details .gallery-thumbs .swiper-slide.swiper-slide-active {
  border: 1px solid var(--primary-color);
}

.stone_details .slider_card {
  padding: 1.563rem;
}

.detail_content .card {
  padding: 1.25rem;
}

/* .detail_content .card p {

  font-size: 1.125rem;
} */

.detail_content .card h5 {
  font-size: 1.625rem;
  color: var(--black-color);
  margin-bottom: 1.25rem !important;
}

/* 7-6-2024 */
/* buy request modal */
.buyRequest_modal {
  color: var(--black-color);
}

.buyRequest_modal .buyRequest_notification {
  display: flex;
  gap: 1.25rem;
}

.buyRequest_modal .notification_wrap {
  display: flex;
  align-items: center;
  gap: 0.313rem;
}

.buyRequest_type_item .radio_wrap label,
.radio_grp {
  font-size: var(--body14);
  line-height: 1.4;
  font-weight: var(--font-weight-regular);
}

.buyRequest_type_item p span {
  font-size: var(--body12);
  line-height: 1.4;
}

.buyRequest_type_comment {
  margin-top: 0.625rem;
}

.specify_list_item .from_to {
  gap: 0.313rem;
}

.buyRequest_specify h6 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  margin: 0.938rem 0 !important;
}

.buyRequest_modal .measurements_list {
  display: flex;
  gap: 0.938rem;
}

.buyRequest_modal .measure_list {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.625rem;
}

.buyRequest_modal .from_to {
  gap: 0rem;
}

.buyRequest_modal .input_pos {
  position: relative;
}

.buyRequest_modal .measure_list .mm_pos {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: var(--body12);
  color: var(--secondary-color);
}

.measure_list_item p {
  margin-bottom: 0 !important;
  padding: 0.22rem 0.313rem !important;
  line-height: 0;
  font-size: var(--body12) !important;
}

.buyRequest_modal .measure_list_item li {
  padding: 0;
}

.buyRequest_modal input[type="checkbox"] {
  display: none;
}

.buyRequest_modal .clarity,
.buyRequest_modal .search_finish_list,
.buyRequest_modal .finish_cut,
.buyRequest_modal .finish_polish,
.buyRequest_modal .search_Fluorescence_list {
  border-right: 0 !important;
  margin-bottom: 0.625rem;
}

.buyRequest_modal .finish_cut p,
.buyRequest_modal .finish_polish p,
.buyRequest_modal .finish_symmetry p {
  font-size: var(--body16);
}

.buyRequest_modal .color_con {
  border-bottom: none;
}

.buyRequest_modal .color_con,
.buyRequest_modal .search_clarity,
.buyRequest_modal .search_finish,
.buyRequest_modal .search_Fluorescence {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

@media (max-width: 768px) {
  .buyRequest_modal .measurements_list {
    flex-direction: column;
  }

  .buyRequest_modal .inclusions_list {
    flex-direction: column;
  }

  .milky_select .css-b62m3t-container {
    width: 100%;
  }

  .buyRequest_modal .finish_cut p,
  .buyRequest_modal .finish_polish p,
  .buyRequest_modal .finish_symmetry p {
    min-width: 70px;
  }

  .buyRequest_modal .clarity,
  .buyRequest_modal .search_finish_list,
  .buyRequest_modal .finish_cut,
  .buyRequest_modal .finish_polish,
  .buyRequest_modal .search_Fluorescence_list {
    margin-bottom: 0.625rem;
  }

  .buyRequest_modal .buyRequest_title {
    flex-direction: column;
  }

  .buyRequest_title h5 {
    margin-bottom: 0.938rem !important;
  }
}

@media (max-width: 1199px) {
  .buyRequest_modal {
    width: 90vw !important;
  }
}

/* 10-6-2024 */

.buyRequest_notification .css-b62m3t-container {
  width: 150px !important;
}

.css-1laqsz7-MuiInputAdornment-root {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.buyRequest_center_list.active {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  max-width: min-content;
  /* padding-bottom: 0.125rem; */
}

.buyRequest_center_menu {
  display: flex;
  gap: 1.875rem;
  white-space: nowrap;
  justify-content: center;
}

a.buyRequest_center_list:not(:last-child):after {
  content: " ";
  background: var(--stroke-color);
  position: absolute;
  width: 1px;
  height: 100%;
  bottom: 0;
  right: -15px;
}

a.buyRequest_center_list {
  position: relative;
  border-bottom: 1px solid transparent;
  transition: all ease-in-out 0.3s;
}

/* 10-6-2024 */

/* tracked diamon */

.tr__trackDiamond_result {
  /* padd: 0.625rem; */
  display: flex;
  align-items: center;
}

.tr__trackDiamond_result p {
  padding: 0 0.625rem;
}

.tr__trackDiamond_result p:first-child {
  border-right: 1px solid var(--stroke-color);
  padding-left: 0;
}

.tr__trackDiamond_table {
  padding: 1.25rem 0;
}

.tr_deselect {
  color: var(--primary-color);
}

.trackDiamond_action img,
.buyRequest_action img,
.saveSearch_action img {
  cursor: pointer;
}

/* edit group name modal  */
/* 
.tr__editGroup_modal{
  width: 550px;
} */

.tr__editGroup_modal input {
  border-bottom: 1px solid var(--primary-color);
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.tr__editGroup_modal .cancel_btn,
.tr__editGroup_modal .fill_btn,
.tr__delete_modal .cancel_btn,
.tr__delete_modal .fill_btn {
  /* width: 100%; */
  flex: 1;
}

/* .tr__delete_modal button , */
.tr__editGroup_modal button {
  padding: 0.625rem;
  border: transparent;
}

.tr__editGroup_modal .cancel_btn:hover,
.tr__delete_modal .cancel_btn:hover {
  background-color: var(--red-color);
}

.tr__editGroup_modal .form-control:focus {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

/* delete modal  */
.tr__delete_modal p {
  text-align: center;
}

.trackDiamond_list {
  width: 140px;
}

li.track_modify {
  display: flex;
  gap: 0.625rem;
  background-color: var(--primary-color) !important;
  align-items: center;
  justify-content: center;
}

.track_modify p {
  color: var(--white-color);
}

.trackeDimond_rightList_item .trackDiamond_list input {
  height: 44px;
}

.trackeDimond_rightList_item .css-13cymwt-control {
  height: 44px !important;
}

.tr__trackDiamond_Result p {
  font-size: var(--body20);
  line-height: 1.5;
  font-weight: var(--font-weight-regular);
  padding: 0 0.625rem;
}

.tr__trackDiamond_Result .diamond_info p a {
  font-size: var(--body14);
  color: var(--black-color);
  padding: 0 0.625rem;
  transition: all ease-in-out 0.5s;
}

.tr__trackDiamond_Result .diamond_info p a:hover {
  color: var(--primary-color);
}

.fromGroupCntr {
  display: flex;
  align-items: center;
  gap: 5px
}

.eyeView {
  cursor: pointer;
}

.tr__trackDiamond_Result .diamond_info p {
  border-right: 1px solid var(--stroke-color);
}

.tr__trackDiamond_Result .diamond_info p:last-child {
  border-right: none;
}

.tr__trackDiamond_Result {
  display: flex;
  /* gap: 0.625rem; */
  /* border-right: 1px solid beige; */
  align-items: flex-start;
  flex-wrap: wrap;
}

.tr__trackDiamond_Result .diamond_info {
  display: flex;
  gap: 0.625rem;
  flex-wrap: wrap;
}

.tr__trackeDimond_filter_item {
  display: flex;
  gap: 0.313rem;
  background: var(--white-color);
  border-radius: 3.125rem;
  padding: 0.188rem 0.625rem;
  align-items: center;
  cursor: pointer;
}

.tr__trackeDimond_filter_item p {
  font-size: var(--body12);
  line-height: 1.4;
}

.traceDiamond_Wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tr__trackeDimond_filter_list {
  display: flex;
  gap: 0.625rem;
  margin: 1.25rem 0;
  flex-wrap: wrap;
}

/* 11-6-2024 */
/* .tr__trackDiamond_list .viewList{
  margin: auto;
} */
.tr__trackeDimond_list .viewButton.active {
  background-color: var(--primary-color) !important;
  /* padding: 0.313rem; */
}

.tr__trackeDimond_list .list_icon.active {
  /* fill: var(--white-color);  */
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7482%) hue-rotate(179deg) brightness(105%) contrast(108%);
}

.trackDiamond_btns img {
  padding-right: 0.313rem;
}

/* rapaport price */

.tr__rapaPort_tabs_list {
  padding: 0.625rem 1.25rem;
  /* background: var(--white-color); */
  border-bottom: 1px solid var(--stroke-color);
  /* margin-bottom: 1.25rem; */
}

.tr__rapaPort_con {
  background: var(--white-color);
  border-radius: var(--radius-border);
  padding-top: 0.625rem;
}

/* .rapaPort_tabs_item{
  font-size: var(--body14);
  line-height: 1.25rem;
  font-weight: var(--font-weight-regular);
  color: var(--black-color);
} */

.rapaPort_tabs_item {
  text-align: center;
  padding: 0.2rem 0.125rem;
  background-color: var(--light-color);
  border-radius: var(--radius-border);
  cursor: pointer;
  font-size: var(--body14);
  line-height: 1.25;
  font-weight: var(--font-weight-regular);
  color: var(--black-color);
  transition: all ease-in-out 0.5s;
  margin: 0 0.625rem;
}

.tr__rapaPort_tabs_list .col-lg-2 {
  border-right: 1px solid var(--stroke-color);
}

.tr__rapaPort_tabs_list .col-lg-2:last-child {
  border-right: none;
}

.tr__rapaPort_shape {
  background: var(--light-color);
  padding: 1.25rem;
  /* margin-bottom: 1.25rem; */
}

.rapaPort_shape_item .rapaport_price {
  max-width: 150px;
}

.tr__rapaPort_shape_list {
  display: flex;
  gap: 1.25rem;
  align-items: end;
  flex-wrap: wrap;
}

.rapaPort_shape_item p {
  font-size: var(--body16);
  line-height: 1.5;
  margin-bottom: 0.313rem !important;
  color: var(--black-color);
  font-weight: var(--font-weight-regular);
}

.rapaport_btn {
  background-color: var(--white-color);
  border-radius: var(--radius-border);
  padding: 0.625rem 0.938rem;
  font-size: var(--body14);
  line-height: 1.063;
  color: var(--black-color);
  border: 1px solid transparent;
  display: inline-block;
  transition: all ease-in-out 0.5s;
}

.rapaport_btn:hover {
  background-color: #dfe0e5;
}

.tr__rapaPort_calc {
  padding: 1.25rem;
  background-color: var(--white-color);
}

.tr__rapaPort_instructions p {
  font-size: var(--body14);
  line-height: 1.625;
  padding-bottom: 0.938rem;
  border-bottom: 1px solid var(--stroke-color);
  margin-bottom: 0.625rem !important;
}

.instructions_con {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.instructions_con p {
  padding-bottom: 0;
  color: var(--secondary-color);
  border-bottom: none;
  line-height: 1.25;
}

/* .rapaPort_calc_con {
  display: flex;
  gap: 0.625rem;
} */

.rapaPort_calc_title {
  flex: 1;
}

.tr__rapaPort_calc input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom: 1px solid var(--primary-color);
  font-size: var(--body14);
  color: var(--dark-color);
  line-height: 1.4;
}

.tr__rapaPort_calc .form-control:focus,
.tr__rapaPort_text input:focus {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid var(--primary-color) !important;
  background-color: transparent;
}

.rapaPort_calc_head {
  font-size: var(--body14);
  line-height: 1.5;
  font-weight: var(--font-weight-medium);
  padding-bottom: 0.938rem;
}

.rapaPort_calc_con p {
  font-size: var(--body14);
  font-weight: var(--font-weight-medium);
}

.tr__rapaPort_calc .row:first-child {
  border-bottom: 1px solid var(--stroke-color);
}

.tr__rapaPort_calc .row:last-child {
  border-bottom: none;
}

.rapaPort_btns_wrap {
  display: flex;
  margin-bottom: 1.25rem;
  /* gap: 1.25rem; */
}

.rapaPort_btns_wrap a {
  font-size: var(--body14);
  color: var(--primary-color);
  font-weight: var(--font-weight-smedium);
  line-height: 1.25;
  display: inline-block;
  padding: 0 1.25rem;
  border-right: 1px solid var(--stroke-color);
}

.rapaPort_btns_wrap a:last-child {
  border-right: none;
}

.tr__rapaPort_text input {
  border-top: none;
  border-right: none;
  border-left: none;
  background: transparent;
  border-radius: 0;
  border-bottom: 1px solid var(--primary-color);
}

.tr__rapaPort_table {
  padding: 1.25rem;
  background: var(--white-color);
  border-radius: var(--radius-border);
  margin-top: 1.25rem;
}

.tr__rapaPort_table_wrap {
  margin-top: 0.625rem;
}

.tr__rapaPort_tabs {
  background: var(--white-color);
  border-radius: var(--radius-border);
}

.rapaPort_price_list_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--radius-border);
  background-color: var(--light-color);
  /* gap: 0.625rem; */
  margin-bottom: 0.938rem;
  flex-wrap: wrap;
}

.rapnet_btn {
  width: fit-content;
  display: inline-block;
}

.rapnet_btn .rapaPort_price_btn {
  display: flex;
  align-items: center;
  border-radius: 50px;
  background-color: rgba(188, 146, 63, 0.2);
  gap: 0.625rem;
  padding: 0.5rem 0.938rem;
}

.rapnet_btn .rapaPort_price_btn span {
  color: var(--primary-color);
  font-size: var(--body14);
  line-height: 1.4;
}


.rapaPort_price_left {
  display: flex;
  gap: 0.625rem;
  align-items: center;
}

.rapaPort_price_imgWrap {
  padding: 0.625rem;
  background: rgba(188, 146, 63, 0.2);
  border-radius: var(--radius-border) 0 0 var(--radius-border);
}

.rapaPort_price_right {
  padding: 0 0.625rem;
}

.rapaPort_price_right p,
.rapaPort_price_con p {
  color: var(--secondary-color);
  font-size: var(--body16);
  line-height: 1.5;
  font-weight: var(--font-weight-regular);
}

.rapaPort_price_con p {
  color: var(--black-color);
}

.tr__rapaPort_price_Wrap h6 {
  font-size: var(--h6);
  line-height: 1.5;
  font-weight: var(--font-weight-bold);
  padding-bottom: 0.625rem;
  border-bottom: 1px solid var(--stroke-color);
  margin-bottom: 1.25rem !important;
}

.tr__rapaPort_price {
  background: var(--white-color);
  padding: 1.25rem;
}

.tr__rapaPort_price_Wrap ul {
  list-style: disc;
  margin-bottom: 0.938rem;
}

.tr__rapaPort_price_Wrap ul li {
  font-size: var(--body16);
  color: var(--secondary-color);
  line-height: 1.5;
  margin-bottom: 0.313rem;
}

.tr__rapaPort_price .accordion-button {
  padding: 0 !important;
  background-color: var(--light-color);
  border: none;
}

.tr__rapaPort_price .accordion-item {
  border: none !important;
}

.rapaPort_tabs_item.active {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.rapaPort_aco_con {
  padding: 0.625rem;
  background-color: var(--white-color);
  border: none;
}

.tr__rapaPort_price .accordion-body {
  padding: 0 !important;
}

.rapaPort_aco_con p {
  color: var(--dark-color);
  margin-bottom: 0.625rem !important;
}

.rapaPort_aco_con ul li a {
  color: var(--secondary-color);
}

/* 12-6 */

.tr__rapaPort_price .accordion-button:not(.collapsed) p {
  color: var(--primary-color);
}

.tr__priceToggle_wrap {
  display: flex;
  gap: 0.625rem;
  align-items: flex-start;
}

.tr__priceChanges,
.tr__rapanetPrice,
.tr__meleeCalc,
.tr__rapSpecs {
  background: var(--white-color);
  border-radius: var(--radius-border);
  padding: 1.25rem;
}

.tr__priceToggle_wrap {
  margin-bottom: 0.625rem;
}

.tr__priceToggle_wrap p {
  font-size: var(--body16);
  line-height: 1.5;
  font-weight: var(--font-weight-regular);
  color: var(--black-color);
}

.tr__priceToggle_con p {
  font-size: var(--body16);
  line-height: 1.5;
  font-weight: var(--font-weight-regular);
  color: var(--secondary-color);
  margin-bottom: 0.625rem !important;
}

.tr__rapanetPrice .tr__rapanetPrice_wrap p,
.tr__meleeCalc_item p {
  font-size: var(--body16);
  font-weight: var(--font-weight-regular);
  color: var(--secondary-color);
  line-height: 1.5;
  margin-bottom: 1.25rem !important;
}

.tr__meleeCalc_item p {
  margin-bottom: 0.375rem !important;
  color: var(--black-color);
}

.tr__rapanetPrice .rapanetPrice_btn {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.5rem 0.875rem;
  border-radius: 3.125rem;
  background: rgba(188, 146, 63, 0.2);
  width: fit-content;
}

.tr__rapanetPrice .rapanetPrice_btn span {
  color: var(--primary-color);
}

.tr__rapSpecs .tr__rapSpecs_title p:first-child {
  font-size: var(--body14);
  line-height: 1.25;
  color: var(--black-color);
  margin-bottom: 0.625rem !important;
}

.tr__rapSpecs .tr__rapSpecs_title p {
  font-size: var(--body16);
  font-weight: var(--font-weight-regular);
  color: var(--black-color);
  line-height: 1.5;
  margin-bottom: 0.625rem !important;
}

.tr__rapSpecs h5 {
  font-size: var(--h5);
  line-height: 1.4;
  font-weight: var(--font-weight-bold);
  color: var(--black-color);
  margin: 0.625rem 0 !important;
}

.tr__rapSpecs_report table,
.tr__size_report table,
.tr__rapSpecs_nonGIA table {
  font-size: var(--body14);
  line-height: 1.25;
  margin-bottom: 0;
}

.tr__rapSpecs_report thead tr th,
.tr__size_report thead tr th,
.tr__rapSpecs_nonGIA thead tr th {
  background-color: var(--primary-color) !important;
  color: var(--white-color);
  font-weight: var(--font-weight-regular);
  min-width: 279.66px;
}

.tr__size_report thead tr th {
  min-width: 190px;
}

.tr__rapSpecs_nonGIA th:first-child {
  min-width: 165px;
}

.tr__rapSpecs_nonGIA thead tr th {
  min-width: 450px;
}

.tr__rapSpecs_report thead tr th:first-child {
  min-width: 165px;
}

.tr__rapSpecs_report,
.tr__size_report,
.tr__rapSpecs_nonGIA {
  overflow: hidden;
  border-radius: var(--radius-border);
  margin-bottom: 2.5rem;
  border: 1px solid var(--stroke-color);
}

.calc_header {
  background-color: var(--stroke-color);
}

.rapaPort_tabs_item:hover {
  background-color: var(--primary-color);
}

.rapaPort_tabs_item:hover p {
  color: var(--white-color);
}

.rapaPort_btns_wrap a:first-child {
  padding-left: 0;
}

@media (max-width: 991px) {
  .rapaPort_tabs_item {
    margin-bottom: 0.625rem;
  }

  .tr__rapaPort_tabs_list .col-lg-2:nth-child(2),
  .tr__rapaPort_tabs_list .col-lg-2:nth-child(4) {
    border-right: none;
  }

  .tr__rapaPort_instructions {
    margin-top: 1.875rem;
  }
}

@media (max-width: 576px) {

  .tr__rapaPort_tabs_list .col-lg-2:nth-child(1),
  .tr__rapaPort_tabs_list .col-lg-2:nth-child(3),
  .tr__rapaPort_tabs_list .col-lg-2:nth-child(5) {
    border-right: none;
  }

  .rapaPort_calc_con p {
    font-size: var(--body12);
    /* font-weight: var(--font-weight-medium); */
  }
}

/* 12-6-2024 */

ul.tr__sidebar_bottom.tr__sidebar_menu span {
  color: var(--primary-color);
}

.tr__sidebar_bottom li {
  transition: all ease-in-out 0.7s;
}

.tr__sidebar_bottom li:hover span {
  color: var(--white-color);
}

/* 13-6-2024 */

/* upload diamond */

ul.tr__uploadDiamond_list {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-bottom: 1.25rem;
  padding-bottom: 0.938rem;
  border-bottom: 1px solid var(--stroke-color);
  flex-wrap: wrap;
  width: 100%;
}

ul.tr__uploadDiamond_list li a {
  line-height: 1.625;
  font-size: var(--body16);
  color: var(--black-color);
  /* padding: 0 1.063rem; */
}

ul.tr__uploadDiamond_list li a:hover {
  color: var(--primary-color);
}

ul.tr__uploadDiamond_list li:first-child {
  padding-left: 0;
}

ul.tr__uploadDiamond_list li {
  border-right: 1px solid var(--stroke-color);
}

ul.tr__uploadDiamond_list li:last-child {
  border-right: none;
}

/* notification center */
.tr__notification_con .check_text {
  padding-left: 0.313rem;
}

.tr__notificationCenter .tr__notificationCenter_Select {
  display: flex;
  justify-content: end;
  gap: 1.25rem;
}

.tr__notificationCenter_Select .noti_searches {
  max-width: 155px;
}

.tr__notificationCenter_Select .noti_actions {
  max-width: 120px;
}

/* 17-6-2024 */

.cart_leftAction {
  display: flex;
}

.cart_right_list {
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 0;
  flex-wrap: wrap;
}

.cart_right_list a {
  color: var(--black-color);
  padding: 0 0.938rem;
  font-size: var(--body14);
  font-weight: var(--font-weight-medium);
}

.cart_right_list a:hover {
  color: var(--primary-color);
}

.cart_right_list li {
  border-right: 1px solid var(--stroke-color);
}

.cart_right_list li:nth-child(5) {
  border-right: none;
}

.tr_cart_topbar {
  margin: 20px 0;
  align-items: center;
}

/* star */

.endorsement_popup .star_rating {
  margin-bottom: 20px;
}

.endorsement_popup .endorsement_textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.feedback-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.feedback-item {
  background-color: var(--white-color);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rating {
  display: flex;
  gap: 0.2rem;
}

.star {
  cursor: pointer;
  color: var(--primary-color);
}

.feedback-text {
  margin-top: 0.5rem;
  font-size: var(--body16);
  color: #333;
}

.tr__uploadDiamond_single h6 {
  font-size: var(--h6);
  line-height: 1.5;
  font-weight: var(--font-weight-bold);
  margin-bottom: 0.5rem !important;
}

.uploadDiamond_single_item p,
.uploadDiamond_list p {
  font-size: var(--body16);
  line-height: 1.5;
  color: var(--black-color);
  font-weight: var(--font-weight-regular);
  margin-bottom: 0.5rem !important;
}

.uploadDiamond_single_item {
  margin-bottom: 0.5rem;
  width: 100%;
}

.uploadDiamond_list .measure_list_item p {
  margin-bottom: 0 !important;
}

.tr__uploadDiamond_single .measure_list_item li.active p {
  color: var(--white-color);
}

.tr__uploadDiamond_single .measure_list_item li:hover p {
  color: var(--white-color);
}

.uploadDiamond_single_item .radio_wrap {
  margin-top: 0.5rem;
}

.tr__uploadDiamond_single_wrap .col-lg-4:not(:last-child) {
  border-right: 1px solid var(--stroke-color);
}

.tr__uploadDiamond_ProductInfo,
.tr__uploadDiamond_diamondInfo,
.tr__uploadDiamond_measurements,
.tr__uploadDiamond_girdle,
.tr__uploadDiamond_length,
.tr__uploadDiamond_comment,
.tr__uploadDiamond_additional,
.tr__uploadDiamond_other {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid var(--stroke-color);
}

.tr__uploadDiamond_diamondInfo .diamondInfo_items,
.tr__uploadDiamond_measurements .measurements_items,
.tr__uploadDiamond_girdle .girdle_items,
.tr__uploadDiamond_length .length_items,
.tr__uploadDiamond_additional .additional_items {
  display: flex;
  gap: 1.25rem;
  align-items: center;
}

.diamondInfo_items .uploadDiamond_single_item {
  min-width: 140px;
  /* width: 100%; */
}

.tr__uploadDiamond_diamondInfo .diamondInfo_items .check1 {
  margin-top: 1.625rem;
}

.tr__uploadDiamond_measurements .measurements_items input,
.tr__uploadDiamond_additional .additional_items .css-b62m3t-container {
  min-width: 140px;
  width: 100%;
}

.tr__uploadDiamond_girdle .girdle_items .uploadDiamond_single_item {
  min-width: 180px;
  width: 100%;
}

.tr__uploadDiamond_length .length_items_left .uploadDiamond_single_item:nth-child(1),
.tr__uploadDiamond_length .length_items_left .uploadDiamond_single_item:nth-child(2) {
  max-width: 180px;
  width: 100%;
}

.tr__uploadDiamond_length .length_items_left .uploadDiamond_single_item:nth-child(3) {
  min-width: 300px;
}

.tr__uploadDiamond_length .length_items_right .uploadDiamond_single_item {
  flex: 1;
}

.tr__uploadDiamond_length .date_picker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.438rem;
  background-color: var(--white-color);
}

.additional_column_items .uploadDiamond_single_item {
  max-width: 270px;
}

.additional_column_items .uploadDiamond_single_item:nth-child(4) {
  max-width: 300px;
}

.tr__uploadDiamond_media input[type="checkbox"] {
  display: none;
}

/* 14-6-2024 */

.tr__uploadDiamond_other .other_matchPair {
  display: flex;
  gap: 0.5rem;
  /* align-items: normal; */
}

.tr__uploadDiamond_other .matchPair_input {
  max-width: 300px;
}

.tr__uploadDiamond_other .other_stockWrap {
  margin: 0.938rem 0;
}

.tr__uploadDiamond_other .other_items {
  margin-top: 0.5rem;
}

.tr__uploadDiamond_other .other_items .uploadDiamond_single_item .css-b62m3t-container {
  max-width: 270px;
}

/* .uploadDiamond_single_item input {
  max-width: 560px;
} */

/* .tr__uploadDiamond_other .col-xl-5 {
  border-right: 1px solid var(--stroke-color);
} */

/* .number_input {
  max-width: 300px !important;
} */

.tr__uploadDiamond_additional .col-xl-5 {
  border-right: 1px solid var(--stroke-color);
}

.tr__uploadDiamond_length .col-xl-6:not(:last-child),
.tr__uploadDiamond_girdle .col-xl-6:not(:last-child),
.tr__uploadDiamond_measurements .col-xl-3:not(:last-child),
.tr__uploadDiamond_measurements .col-xl-6,
.tr__uploadDiamond_diamondInfo .col-xl-5 {
  border-right: 1px solid var(--stroke-color);
}

.tr__uploadDiamond_multiple_con .multiple_title {
  font-size: var(--body16);
  font-weight: var(--font-weight-regular);
  line-height: 1.5;
  text-align: center;
  color: var(--black-color);
  margin-bottom: 0.5rem !important;
  margin: 0 auto;
}

.tr__uploadDiamond_multiple_con p {
  font-size: var(--body16);
  font-weight: var(--font-weight-regular);
  line-height: 1.5;
  text-align: center;
  color: var(--secondary-color);
  margin-bottom: 0.5rem !important;
  max-width: 860px;
  margin: 0 auto;
}

.tr__uploadDiamond_multiple_con {
  padding: 1.25rem 0;
  border-bottom: 1px solid var(--stroke-color);
}

.multiple_inputWrap {
  gap: 0.5rem;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.multiple_inputTitle {
  margin: 0 !important;
}

.multiple_select {
  min-width: 300px;
}

.tr__uploadDiamond_multiple_con .option_wrap label.active {
  color: var(--primary-color);
}

.tr__uploadDiamond_multiple_con .option_wrap {
  padding: 1.875rem;
  background: var(--white-color);
  border-radius: var(--radius-border);
  min-height: 155px;
  /* height: 100%; */
  margin: 1.25rem 0;
}

.tr__uploadDiamond_multiple_con .option_wrap .radio_wrap {
  margin-bottom: 0.625rem;
}

.tr__uploadDiamond_multiple_con .option_wrap p {
  margin: 0 !important;
  text-align: left;
  max-width: 715px;
  width: 100%;
}

.tr__uploadDiamond_multiple_con .report_email {
  font-size: var(--body14);
  color: var(--black-color);
  margin: 0 !important;
}

.tr__uploadDiamond_multiple_con .multiple_report {
  display: flex;
  gap: 1.25rem;
  align-items: center;
  flex-wrap: wrap;
}

.tr__uploadDiamond_multiple_con .email_btn {
  padding: 0.313rem 1.25rem;
  text-align: center;
}

.tr__uploadDiamond_multiple .check1 {
  margin-bottom: 1.25rem;
}

.tr__uploadDiamond_multiple .email_Select {
  min-width: 180px;
}

.tr__uploadDiamond_ftpSetup .ftpSetup_Setup,
.tr__uploadDiamond_ftpSetup .ftpSetup_Setup_steps {
  padding: 1.25rem 0;
  border-bottom: 1px solid var(--stroke-color);
}

.ftpSetup_Setup h5 {
  font-size: var(--h5);
  color: var(--black-color);
  font-weight: var(--font-weight-bold);
  margin-bottom: 0.5rem !important;
}

.ftpSetup_Setup p {
  max-width: 860px;
  font-size: var(--body16);
  font-weight: var(--font-weight-regular);
  line-height: 1.5;
  color: var(--secondary-color);
}

.ftpSetup_Setup_steps h6 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  line-height: 1.5;
  margin-bottom: 0.5rem !important;
}

.ftpSetup_Setup_steps ol {
  color: var(--secondary-color);
  font-size: var(--body16);
  font-weight: var(--font-weight-regular);
  line-height: 1.5;
}

.ftpSetup_Setup_steps ol li {
  margin-bottom: 0.5rem;
}

.ftpSetup_Setup_steps .ftp_btn {
  /* width: fit-content; */
  padding: 0.5rem;
  width: 171px;
  font-size: var(--body14);
  line-height: 1.4;
}

.ftpSetup_Setup_steps a {
  color: var(--primary-color);
}

.setup_list {
  padding-left: 0;
}

.his_icon {
  padding: 0 0.313rem !important;
}

.tr__uploadDiamond_history .custom_header {
  background-color: var(--stroke-color);
}

.result_delete {
  display: flex;
  gap: 0.5rem;
  padding: 0.188rem 0.75rem;
  width: fit-content;
  border: 1px solid var(--stroke-color);
  border-radius: var(--radius-border);
  color: var(--secondary-color);
  background-color: var(--white-color);
  align-items: center;
}

.article_wrap {
  display: flex;
  align-items: center;
  gap: 1.688rem;
  justify-content: end;
  flex-wrap: wrap;
}

.article_wrap p a {
  color: var(--primary-color);
}

.rightpendingVer_result_action {
  padding: 0.5rem 0.75rem;
  border-radius: var(--radius-border);
  background-color: #f4f4f4;
  box-shadow: 0px 0px 20px #00000008;
}

.pendingVer_table {
  margin-top: 1.25rem;
}

ul.tr__uploadDiamond_list li a.active {
  color: var(--primary-color);
}

@media (max-width: 768px) {

  .tr__uploadDiamond_diamondInfo .diamondInfo_items,
  .tr__uploadDiamond_measurements .measurements_items,
  .tr__uploadDiamond_girdle .girdle_items,
  .tr__uploadDiamond_length .length_items,
  .tr__uploadDiamond_additional .additional_items {
    flex-wrap: wrap;
  }

  .uploadDiamond_list .measure_list_item {
    flex-wrap: wrap;
  }

  .report_uploadFile {
    margin-top: 1.25rem;
  }
}

/* 17-6-2024 */

.img-vedio label {
  cursor: pointer;
}

.stone_details .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stone_details .swiper-slide img {
  max-width: 100%;
  width: 100%;
}

.stone_details .gallery-thumbs .swiper-slide:hover {
  border: 1px solid var(--primary-color);
}

@media (max-width: 1199px) {

  .tradeCenter_rightList_item,
  .result_right,
  .pair_rightList_item {
    padding-left: 0;
    overflow-x: auto;
    justify-content: left !important;
  }

  /* 
  .tradeCenter_rightList_item li {
    margin: 5px 0;
  } */
}

.result_right {
  display: flex;
  justify-content: end;
  align-items: center;
}

/* terms conditions */

.tr__memLicAgree_title h1,
.tr__tradingRule_title h1,
.tr__privacyPolicy_title h1 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  margin-bottom: 0.625rem !important;
}

.tr__memLicAgree_title p,
.tr__tradingRule_title p,
.tr__privacyPolicy_title p {
  color: var(--secondary-color);
  font-size: var(--body14);
}

.tr__memLicAgree_list,
.tr__tradingRule_list,
.tr__privacyPolicy_list {
  margin-top: 1.25rem;
}

.tr__memLicAgree_list ol li::marker,
.tr__tradingRule_list ol li::marker,
.tr__privacyPolicy_list ol li::marker {
  font-weight: var(--font-weight-regular);
}

/* .tr__memLicAgree .tr__memLicAgree_sblist{
  padding-left: 0;
} */

.tr__memLicAgree_list p,
.tr__tradingRule_list p,
.tr__privacyPolicy_list p {
  font-size: var(--body14);
  font-weight: var(--font-weight-medium);
  margin: 0.625rem 0;
}

.tr__memLicAgree .tr__memLicAgree_sblist li,
.tr__tradingRule_list li,
.tr__trading_sblist li,
.tr__tradingRule_sblist li {
  font-size: var(--body14);
  margin: 0.625rem 0;
  text-align: justify;
}

.tr__memLicAgree .tr__memLicAgree_sblist ul,
.tr__tarding_sblist ul {
  padding-left: 0;
}

.tr__tradingRule_list ol li {
  margin: 0.625rem 0;
}

.tr__privacyPolicy_top {
  margin: 1.25rem 0;
}

.tr__privacyPolicy_top h6 {
  font-weight: var(--font-weight-bold);
  font-size: var(--body16);
}

.tr__privacyPolicy_top p {
  font-size: var(--body14);
}

.tr__privacyPolicy_list {
  padding-bottom: 3.125rem;
  border-bottom: 1px solid var(--stroke-color);
}

.tr__cookies_wrap h5 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  color: var(--black-color);
  margin-top: 1.875rem;
  margin-bottom: 1.25rem !important;
}

.tr__cookies_wrap h6 {
  font-size: var(--body16);
  font-weight: var(--font-weight-smedium);
}

.tr__cookies_wrap p {
  font-size: var(--body14);
  margin-top: 0.625rem;
}

table.cookies_table {
  margin: 1.25rem;
}

tr.table_item td {
  font-size: var(--body14);
  margin: 0.625rem 0;
}

.tr__notification_table {
  /* margin: 1.875rem 0; */
  /* overflow-x: auto; */
  overflow: hidden;
}

.tr__notification_table .row {
  /* width: fit-content; */
  padding: 10px 0;
  /* border-bottom: 1px solid var(--stroke-color); */
  overflow: hidden;
  /* justify-content: space-between; */
  align-items: center;
}

.tr__notification_table .row p {
  font-size: var(--body14);
}

.tr__notification_title h5,
.tr__priceMarkup_title h5,
.tr__language_wrap h5,
.tr__pricingOption_title h5,
.tr_shippinDetails h5,
.tr__tradingDetails_title h5,
.tr__passSecurity_title h5,
.tr__passSecurity_active h5,
.tr__apiAccess_title h5,
.tr__apiAccess_keys h5,
.tr__listPermission_title h5,
.tr__blocking_title h5,
.tr__trading_title h5,
.tr__plans_title h5,
.tr__promotedHistory_title h5,
.tr__order_title h5 {
  font-size: var(--h5);
  font-weight: var(--font-weight-bold);
  line-height: 1.5;
  color: var(--black-color);
}

.tr__notification_title p,
.tr__pricingOption_title p,
.tr__tradingDetails_title p,
.tr__tradingDetails_invoice p,
.tr__tradingDetails_term p,
.tr__passSecurity_title p,
.tr__apiAccess_keys p,
.tr__listPermission_title p,
.tr__blocking_title p,
.tr__order_title p {
  color: var(--secondary-color);
  font-size: var(--body16);
  margin: 0.5rem 0;
}

@media (max-width: 768px) {
  .tr__notification_table {
    /* overflow-x: auto; */
  }
}

/* 18-6-2024 */
@media (max-width: 425px) {
  .tr__notification_table .row {
    flex-direction: column;
  }
}

.tr__notification_table {
  max-width: 810px;
  width: 100%;
  margin-top: 1.25rem;
}

.notification_table_row {
  border-bottom: 1px solid var(--stroke-color);
  padding: 0 0.625rem;
}

.tr__notification_table .notification_table_row:nth-child(odd) {
  background-color: var(--stroke-color);
}

.tr__notification_table .notification_table_row:nth-child(even) {
  background-color: #ffffff;
}

.notification_table_row .check1 {
  align-items: center;
}

.notification_table_btn {
  display: flex;
  gap: 1.25rem;
  margin-top: 1.25rem;
}

.notification_table_btn a {
  padding: 0.5rem;
  font-size: var(--body14);
}

.tr__priceMarkup_list {
  list-style: disc;
  margin-top: 0.5rem;
}

.tr__priceMarkup_list li,
.tr__priceMarkup_title p {
  font-size: var(--body16);
  line-height: 1.5;
  font-weight: var(--font-weight-regular);
  color: var(--secondary-color);
}

.tr__priceMarkup_table {
  margin-top: 1.25rem;
  max-width: 810px;
  width: 100%;
  background-color: var(--white-color);
  padding: 0 1.25rem;
  border-radius: var(--radius-border);
}

.tr__priceMarkup .dlt_icon {
  padding: 0.313rem;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.priceMarkup_icons {
  gap: 0.938rem;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}

.tr__priceMarkup .tr_toggle_switch {
  display: flex;
  gap: 0.313rem;
}

thead tr th {
  background: var(--light-color) !important;
  font-weight: var(--font-weight-medium) !important;
}

.tr__priceMarkup_table table {
  border-radius: 5px;
  /* border: 1px solid; */
  overflow: hidden;
  margin: 0.625rem 0;
}

.tr__priceMarkup .createNew_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.25rem;
  flex-wrap: wrap;
  gap: 0.625rem;
}

.createIcon_wrap {
  background: var(--primary-color);
  width: fit-content;
  padding: 0.5rem;
  border-radius: var(--radius-border);
  cursor: pointer;
}

.tr__priceMarkup .createNew_Add {
  display: flex;
  align-items: center;
  gap: 0.938rem;
}

.createNew_wrap p {
  font-size: var(--body14);
  line-height: 1.25;
  color: var(--black-color);
}

.tr__priceMarkup_table table {
  white-space: nowrap;
  font-size: var(--body14);
  line-height: 1.625;
}

/* .tr__priceMarkup .tr_toggle_switch p {
  white-space: nowrap;
} */

@media (max-width: 768px) {
  .tr__priceMarkup_table table {
    overflow-x: auto;
  }
}

.tr__language .lang_select {
  max-width: 300px;
  width: 100%;
  margin-top: 1.25rem !important;
}

.tr__language .lang_btn,
.tr__pricingOption .priceOption_btn {
  padding: 0.313rem 0.75rem;
  margin-top: 1rem;
  width: fit-content;
}

.tr__pricingOption .radio_wrap {
  flex-direction: column;
  gap: 0.625rem;
}

.tr__pricingOption_radio {
  margin-top: 1.25rem;
}

.tr_shippinDetails_form {
  max-width: 800px;
  width: 100%;
  margin-top: 2.5rem;
}

.tr_shippinDetails_form .row {
  margin: 0.625rem 0;
}

.applyShipping_btn {
  padding: 0.4rem 1.25rem;
}

.tr_shippinDetails .form-label {
  margin-bottom: 0.5rem;
  font-size: var(--body14);
  font-weight: var(--font-weight-smedium);
}

.invoice_inputWrap {
  display: flex;
  flex-direction: column;
  gap: 0.938rem;
  margin: 1.25rem 0;
}

.tr__tradingDetails_invoice {
  padding: 1.875rem 0;
  border-bottom: 1px solid var(--stroke-color);
}

.tr__tradingDetails_invoice h6,
.tr__tradingDetails_term h6 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  line-height: 1.75;
  margin-bottom: 0.625rem !important;
}

.tr__tradingDetails .form-label {
  margin-bottom: 0;
  white-space: nowrap;
}

.tr__tradingDetails [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
  display: none;
}

.tr__tradingDetails [type="file"]+label {
  background: var(--primary-color);
  border: none;
  border-radius: var(--radius-border);
  color: var(--white-color);
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 0.5rem;
  width: 100%;
  position: relative;
  /* transition: all 0.3s; */
  vertical-align: middle;
}

.previewInv_btn,
.resetTra_btn,
.resetTra_btn {
  padding: 0.5rem 0.75rem;
}

.tradingDet_check {
  display: flex;
  gap: 1.25rem;
  align-items: center;
  margin: 1.25rem 0;
}

.tradingPay_list {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
}

.tr__tradingDetails .tradingPay_list p {
  font-size: var(--body14);
  white-space: nowrap;
  margin: 0;
}

.tr__tradingDetails .pay_Select {
  min-width: 200px;
}

.tr__tradingDetails_term .tradingPay_list .form-control {
  max-width: 50px;
  width: 100%;
}

.tr__tradingDetails_term {
  padding: 1.875rem 0;
}

/* sidebar support button */
ul.tr__sidebar_bottom.tr__sidebar_menu a:hover span {
  color: var(--white-color);
}

/* 19-6-2024 */

.tr__notification_table .check_text {
  font-size: var(--body12);
  line-height: 1;
  /* color: var(--black-color); */
  color: var(--secondary-color);
}

@media (max-width: 1200px) {
  .item_wrap p {
    white-space: nowrap;
  }
}

.profile_wrap .card {
  border: 1px solid var(--stroke-color) !important;
}

.social_list ul li:hover {
  background-color: var(--page-color);
}

.tr__order_tables .table_wrap .table {
  margin-bottom: 0;
  border-radius: var(--radius-border);
  overflow: hidden;
}

.tr__order_tables .table_wrap .table tr th {
  font-weight: var(--font-weight-regular);
  line-height: 1.625;
  white-space: nowrap;
}

.tr__order_tables .table_wrap .table tbody td {
  font-size: var(--body14);
  line-height: 1.25rem;
}

.passSec_btn,
.api_activate {
  margin-top: 1.25rem;
  padding: 0.5rem 0.75rem;
}

.tr__passSecurity_title {
  padding-bottom: 3.125rem;
  border-bottom: 1px solid var(--stroke-color);
}

.passSecurity_active_wrap {
  display: flex;
  gap: 0.625rem;
}

.tr__passSecurity_active {
  max-width: 800px;
  width: 100%;
}

.tr__passSecurity_active {
  margin-top: 3.125rem;
}

.passSecurity_active_wrap {
  padding: 1.25rem;
  border-bottom: 1px solid var(--stroke-color);
  overflow: hidden;
}

.passSecurity_active_wrap h6 {
  margin-bottom: 0.625rem !important;
  font-weight: var(--font-weight-bold);
  font-size: var(--body16);
}

.tr__password .passSecurity_active_wrap p {
  color: var(--secondary-color);
  font-size: var(--body14);
}

.tr__password .active_wrap {
  display: flex;
  align-items: baseline;
  margin-top: 0.313rem;
}

.tr__password .active_wrap img {
  max-width: 10px;
}

.tr__password_helpArticle {
  margin: 3.125rem 0;
}

.tr__password .helpArticle_links {
  display: flex;
  flex-direction: column;
  gap: 0.313rem;
  margin: 0.625rem 0;
}

.tr__password .helpArticle_links a {
  font-size: var(--body14);
  color: var(--primary-color);
  font-weight: var(--font-weight-medium);
  border-bottom: 1px solid transparent;
  width: fit-content;
  transition: all ease-in-out 0.5s;
}

.tr__password .helpArticle_links a:hover {
  color: var(--black-color);
  border-bottom: 1px solid var(--black-color);
}

.tr__password_helpArticle h6 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
}

.tr__apiAccess_title p {
  color: var(--secondary-color);
  font-size: var(--body16);
}

.tr__apiAccess .tr__apiAccess_title {
  max-width: 800px;
  width: 100%;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid var(--stroke-color);
}

.tr__apiAccess_title h6 {
  font-weight: var(--font-weight-medium);
  font-size: var(--body16);
}

.tr__apiAccess ol.api_list {
  font-size: 14px;
  margin-top: 10px;
}

.tr__apiAccess ol.api_list a {
  color: var(--primary-color);
}

.tr__apiAccess .api_doc {
  margin-top: 1.25rem;
}

.tr__apiAccess .api_doc a {
  font-size: var(--body14);
  color: var(--primary-color);
}

/* 20-6-2024 */

.tr__apiAccess_activateKey h6 {
  font-weight: var(--font-weight-bold);
  font-size: var(--h6);
}

.tr__apiAccess_activateKey a {
  color: var(--primary-color);
}

.tr__apiAccess_activateKey {
  margin: 1.875rem 0;
}

.tr__apiAccess_activateKey p,
.activateKey_list {
  font-size: var(--body14);
  line-height: 1.5;
}

.tr__apiAccess_keys,
.api_credentials,
.api_linkHelp {
  padding: 1.875rem 0;
}

.api_credentials_info {
  max-width: 500px;
  width: 100%;
  margin-top: 1.25rem;
  border: 1px solid var(--stroke-color);
  overflow: hidden;
  border-radius: 5px;
}

.api_credentials_head,
.api_credentials_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0.938rem 0.313rem;
  border-bottom: 1px solid var(--stroke-color);
}

.api_credentials_head {
  background-color: var(--light-color);
  border-radius: var(--radius-border) var(--radius-border) 0 0;
}

.api_credentials_list {
  justify-content: flex-start;
  gap: 1.25rem;
}

.api_credentials_list p {
  margin-top: 0;
}

.api_credentials_info img {
  cursor: pointer;
}

.api_credentials_list h6 {
  font-size: var(--body16);
  line-height: 1.625;
}

.api_credentials_head a.activate_btn {
  font-weight: var(--font-weight-bold);
  font-size: var(--body12);
  background: #bc923f59;
  padding: 0.188rem 0.313rem;
  border-radius: var(--radius-border);
}

.api_credentials {
  border-bottom: 1px solid var(--stroke-color);
}

.api_linkHelp a,
.listPermission_para a {
  font-weight: var(--font-weight-medium);
  text-decoration: underline !important;
  color: var(--primary-color);
}

.listPermission_para p,
.blocking_para p {
  font-size: var(--body14);
  margin: 0 !important;
  line-height: 1.5;
  color: var(--black-color);
}

.tr__listPermission .listPermission_para,
.blocking_para {
  margin-top: 1.25rem;
}

.listPermission_radioList .radio_wrap {
  flex-direction: column;
  gap: 0.313rem;
}

.listPermission_radioList {
  margin-top: 1.25rem;
}

.tr__listPermission a.listMem_btn,
.tr__blocking a.listMem_btn {
  background: var(--primary-color);
  padding: 0.313rem 0.5rem;
  border-radius: var(--radius-border);
  color: var(--white-color);
  display: inline-block;
}

.listPermission_search,
.blocking_search {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.listPermission_search input,
.blocking_search input {
  max-width: 400px;
  width: 100%;
}

.listPermission_radioList .radio_wrap label {
  font-size: var(--body14);
  line-height: 1.5;
}

.listPermission_selectMember,
.blocking_selectMember {
  margin-top: 1.563rem;
}

.listPermission_selectMember h6,
.blocking_selectMember h6 {
  font-size: var(--body16);
  font-weight: var(--font-weight-bold);
  line-height: 0.313;
  margin-bottom: 0.625rem !important;
}

.blocking_para h6 {
  font-weight: var(--font-weight-bold);
}

.tr__trading_switch label.tr_switch span {
  padding-right: 0.625rem;
}

.tr__trading_switch {
  margin-top: 10px;
  font-size: var(--body14);
  line-height: 1.5;
}

/* 21-6-2024 */
.tr__plans_table {
  max-width: 600px;
  width: 100%;
  margin-top: 1.875rem;
  font-size: var(--body14);
  border-radius: var(--radius-border);
  overflow: hidden;
  border: 1px solid var(--stroke-color);
}

.tr__plans_table table {
  /* border-radius: var(--radius-border); */
  /* overflow: hidden; */
  overflow-x: auto;
  margin-bottom: 0;
}

.tr__plans_table table thead tr th {
  background-color: var(--primary-color) !important;
  color: var(--white-color);
  font-weight: var(--font-weight-regular);
  white-space: nowrap;
}

.tr__promotedHistory_table,
.tr__order_table {
  margin-top: 1.875rem;
}

.order_header {
  background-color: var(--stroke-color);
}

/* stone detail 21-6-2024 */

.content_wrapper .detail_content h5 {
  font-size: var(--h5);
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
  margin-bottom: 1.25rem !important;
  color: var(--black-color);
}

.detail_content p {
  font-size: var(--body18);
  line-height: 1.4;
  font-weight: var(--font-weight-regular);
  color: var(--black-color);
}

.diamond_Details h4 {
  font-size: var(--body20);
  line-height: 1.4;
  font-family: var(--primary-font) !important;
  font-weight: var(--font-weight-regular);
  color: var(--black-color);
}

@media (max-width: 1440px) {
  .stone_details .swiper-slide img {
    max-width: 60%;
  }
}

@media (max-width: 768px) {
  .tr__priceMarkup .createNew_Add {
    margin-top: 0.625rem;
  }
}

/* 24-6-2024 */

@media (max-width: 991px) {

  .clarity,
  .eyeClean,
  .loc_source_select,
  .col-12.col-md-4:not(:last-child) .measure_list,
  .col-12.col-md-4:not(:last-child) .price_dis_list,
  .col-12.col-md-3:not(:last-child) .inclusions_select,
  .search_Fluorescence_list,
  .search_finish_list,
  .finish_cut,
  .finish_polish,
  .clarity,
  .eyeClean,
  .col-md-6:not(:last-child) .crown_list,
  .borderRight {
    border-right: none !important;
  }

  .tr__searchStock .searchStock_title,
  .tr__calculator .calculator_title,
  .tr__profile .tr__profile_title,
  .tr__compareDimond .tr__compareDimond_title,
  .tr__buyRequest .buyRequest_title,
  .tr__saveSearch .tr__saveSearch_title,
  .tr__trackDiamond .trackDimond_title,
  .tr__trackeDimond_list .trackDimond_title,
  .tr__rapaPort .tr__rapaPort_title {
    margin-bottom: 0.625rem;
  }

  .radio_wrap {
    gap: 0.625rem;
    flex-wrap: wrap;
  }

  .treatment_checklist {
    margin-top: 0.625rem;
  }

  .from_to {
    width: 100%;
  }

  .search_finish .css-b62m3t-container {
    width: 100%;
  }

  .finish_symmetry p,
  .finish_polish p,
  .finish_cut p,
  .price_dis_list p {
    min-width: 82px;
  }

  .tr__searchStock .searchStock_btns {
    justify-content: start;
    overflow-x: auto;
    align-items: flex-start;
  }

  .media_list_item {
    max-width: 100%;
  }

  .measure_list p {
    min-width: 80px;
  }
}

.measure_list p,
.grading_input p {
  white-space: nowrap;
}

.saveSearch_btn:hover {
  background-color: var(--white-color);
  border: 1px solid var(--black-color);
  color: black;
}

.saveSearch_btn:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7484%) hue-rotate(183deg) brightness(104%) contrast(97%);
}

.tr__searchStock_top .search_btn:hover img {
  filter: brightness(0) saturate(100%) invert(52%) sepia(74%) saturate(373%) hue-rotate(2deg) brightness(100%) contrast(84%);
}

/* 25-6-2024 */

.first .select_item input {
  min-width: 120px;
  max-width: 130px;
  width: 100%;
  height: 36px;
}

.select .select_item input,
.select .select_item select {
  min-width: 115px;
  width: 100%;
}

/* 25 - 6 - 2024 */

.multipal_upload {
  display: flex;
  align-items: center;
  gap: 15px;
}

.multipal_upload .fill_btn {
  padding: 0.313rem 1.25rem !important;
  font-size: 16px;
  max-width: 100%;
}

.plan_details table tbody td {
  font-size: var(--body14);
  line-height: 1.25;
}

/* cart page */
.tr__cart .cart_list_wrap,
.tr__dashboard .cart_list_wrap,
.tr__watchlist .watchlist_wrap {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.938rem;
}

.tr__cart .option_container img,
.grid_selection_container svg,
.tr__watchlist .option_container img {
  width: 22px;
  height: 22px;
}

@media (max-width: 991px) {

  .tr__cart .cart_list_wrap,
  .tr__watchlist .watchlist_wrap,
  .diamondList_container .result_right,
  .tr__tradeCenter .tradeCenter_rightList_item,
  .tr__pair .pair_rightList_item {
    overflow-x: auto;
    justify-content: start;
    padding-bottom: 0.313rem;
  }
}

@media (max-width: 991px) {
  .cart_header {
    min-width: 150px !important;
  }
}

/* 26-6-2024 */

.plan_details table th {
  padding: 0 !important;
}

.pricing_head p {
  font-size: var(--body14);
  line-height: 1.25;
  font-weight: var(--font-weight-regular);
}

.plans_wrap .apply_btn {
  font-weight: var(--font-weight-regular) !important;
  padding: 0.563rem;
  transition: all ease-in-out 0.5s;
}

/* 28-6-2024 */

@media (max-width: 1440px) and (min-width: 1350px) {
  .tr__sidebar_wrap {
    padding: 0.938rem;
  }

  ul.tr__sidebar_menu span {
    font-size: var(--body14);
  }

  .item_wrap p {
    font-size: var(--body14);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.left_menulist {
  z-index: 20;
}

.leftmenu_toggle img {
  transition: transform 0.3s ease;
}

.leftmenu_toggle.rotate img {
  transform: rotate(135deg);
}

.no-scroll {
  overflow-y: hidden;
  position: relative;
}

.overlay-visible .overlay {
  display: block;
}

.overlay-hidden .overlay {
  display: none;
}

@media (max-width: 575px) {
  .tr__profile_det_wrapper {
    flex-direction: column;
  }

  .tr__profile_det {
    gap: 1rem;
    flex-direction: column;
    align-items: center;
  }

  .tr__profile_name {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .tr__profile_det div:not(:last-child):after {
    display: none;
  }

  .select {
    gap: 0.25rem;
  }
}

/* left toggle */

@media (max-width: 450px) {
  .tr__topmenus .left_menulist.leftmenu_hidden {
    right: -160%;
  }

  .small_hideCon {
    display: none !important;
  }

  .right_menulist .nav-link {
    border-right: none !important;
    padding: 0 0.7rem !important;
  }

  .profile_drop {
    transform: translate(-240px, 15px) !important;
  }

  .right_menulist a:nth-child(4) {
    padding-right: 0 !important;
  }
}

.no-scroll::before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

/* address selection page  */

.tr__address_table {
  border-radius: 5px;
  overflow: hidden;
}

.tr__address_table thead th {
  background-color: var(--primary-color) !important;
  color: var(--white-color);
  font-size: var(--body14);
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
}

.tr__address_selection .fill_btn,
.address_modal .modal-footer button {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}

.address_modal .modal-footer button,
.recent_modal .modal-footer button {
  width: 100%;
}

.address_modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.address_modal .form-label {
  font-size: var(--body16);
}

.err_msg {
  color: var(--red-color);
  font-size: var(--body12);
}

.tr__address_table address {
  margin-bottom: 0;
  font-style: italic;
}

/* Temp */

.checkout-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.25rem;
}

.tr__order_summary .card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.25rem;
  padding: 1.25rem;
}

.tr__order_summary .card h2 {
  margin-bottom: 0.938rem !important;
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
}

.tr__address_table table {
  width: 100%;
  border-collapse: collapse;
}

.tr__order_summaryTable th,
.tr__order_summaryTable td {
  padding: 0.625rem;
  text-align: left;
  border-bottom: 1px solid var(--stroke-color);
}

.tr__order_summaryTable th {
  background-color: var(--stroke-color) !important;
  font-size: var(--body14);
  font-weight: var(--font-weight-medium);
}

.tr__order_summaryTable strong {
  font-size: 14px;
  font-weight: 500;
}

.tr__proceed_button {
  text-align: center;
  margin-top: 1.25rem;
}

table.tr__order_summaryTable {
  width: 100%;
  overflow-x: auto;
}

.tr__proceed_button button {
  cursor: pointer;
}

.tr__orderListTable {
  overflow: scroll;
  border-radius: var(--radius-border);
}

.tr__address_table td img {
  cursor: pointer;
}

.key_symbolRadio,
.advance_shade_radio {
  display: flex;
  gap: 1.875rem;
  align-items: center;
  margin-bottom: 0.5rem;
}

@media (max-width: 575px) {

  .key_symbolRadio,
  .advance_shade_radio {
    flex-direction: column;
    align-items: start;
    gap: 0;
  }
}

/* 2-7-2024 */

/* search stock page */

@media (max-width: 1800px) {
  .loc_source_select {
    border-right: none;
  }
}

@media (max-width: 767px) {
  .tr__trackeDimond_list .trackeDimond_rightList_item {
    overflow-x: auto;
    justify-content: start;
    flex-wrap: nowrap;
  }

  .uploadDiamond_single_item input,
  .additional_column_items .uploadDiamond_single_item {
    max-width: 100%;
  }

  .loc_source_select_list {
    max-width: 100% !important;
    width: 100%;
  }

  .search_price .price_dis input {
    max-width: 100%;
  }

  .measure_list input {
    max-width: 100%;
  }

  .black_white {
    width: 100%;
  }

  .black_white.css-b62m3t-container {
    width: 100%;
    max-width: 100% !important;
  }

  .eyeClean_select,
  .eyeClean {
    min-width: 100%;
  }
}

/* result page */
@media (max-width: 1650px) {
  .result_right {
    overflow-x: auto;
    justify-content: start;
    padding-bottom: 0.313rem;
  }
}

.diamondList_container .more_option {
  min-width: 175px;
}

@media (max-width: 1440px) {

  .tr__uploadDiamond_diamondInfo .diamondInfo_items,
  /* .tr__uploadDiamond_measurements .measurements_items, */
  /* .tr__uploadDiamond_girdle .girdle_items, */
  .tr__uploadDiamond_length .length_items,
  .tr__uploadDiamond_additional .additional_items {
    flex-wrap: wrap;
  }

  .tr__uploadDiamond_measurements .col-xl-6 {
    border: none;
  }

  .col-md-6:not(:last-child) .crown_list {
    border-right: none !important;
  }
}

.tr__sidebar_bottom li.active span {
  color: var(--white-color);
}

.uploadDiamond_single_title .measure_list_item {
  flex-wrap: wrap;
}

@media (max-width: 991px) {

  .tr__uploadDiamond_girdle .girdle_items,
  .tr__uploadDiamond_measurements .measurements_items {
    flex-wrap: wrap;
  }

  .tr__uploadDiamond_measurements .col-xl-6,
  .tr__uploadDiamond_diamondInfo .col-xl-5,
  .tr__uploadDiamond_single_wrap .col-lg-4:not(:last-child) {
    border: none;
  }
}

.black_white.css-b62m3t-container {
  width: 100%;
  max-width: 300px;
}

.tr__plans_table table td {
  white-space: nowrap;
}

.tr__uploadDiamond_tabs {
  display: flex;
  justify-content: space-between;
}

.tr_termsCondition .fill_btn {
  padding: 0.5rem 0.75rem;
}

.track_delete img {
  cursor: pointer;
}

.track_modify {
  cursor: pointer;
}

/* 3-7-2024 */

@media (max-width: 767px) {
  .tr_addInfo .tr__addInfo_item p {
    font-size: var(--body18);
    line-height: 1.4;
  }

  .tr__uploadDiamond_tabs {
    flex-direction: column-reverse;
  }

  .tr_termsCondition_backBtn {
    display: flex;
    justify-content: end;
    margin-bottom: 1.25rem;
  }

  .tr_termsCondition .fill_btn {
    padding: 0.313rem 0.75rem;
    font-size: 14px;
  }
}

.tr__sidebar_bottom li.active span {
  color: var(--white-color);
}




/* 05 - 07 - 2024  */

/* Add this CSS to your styles */
.unread-dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.tr__notificationCenter_wrap .MuiDataGrid-row.unread .MuiDataGrid-cell {
  background-color: #ffe6e6;
}

/* 8-7-2024 */

.nav_top {
  background: var(--white-color);
}

.nav_top .dropdown-item {
  display: flex;
  justify-content: center;
}


.gallery img {
  max-width: 100%;
  width: 100%;
}

.gallery_op_list img {
  width: 14px;
  height: 14px;
}

.gallery {
  display: flex;
  gap: 0.625rem;
  padding: 0.625rem;
}

.gallery_op_list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.gallery_op_list a {
  padding: 0.313rem;
  border: 1px solid var(--stroke-color);
  height: auto;
  border-radius: var(--radius-border);
  /* transition: all ease-in-out 0.5s; */
  display: inline-flex;
}

.imgVideo_title {
  font-weight: var(--font-weight-bold);
  font-size: var(--h6);
  line-height: 1.4;
  color: var(--black-color);
}

.expandableTable thead th {
  padding: 0.5rem 0.75rem;
  height: 42px;
  gap: 10px;
  background: #F4F4F4;
}

.expandableTable tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.expandableTable tbody tr:nth-child(even) {
  background-color: var(--white-color);
}

.not_graded {
  float: right;
}

.not_graded img {
  margin-right: 5px;
}

.MuiCheckbox-root {
  color: #bc923f !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #bc923f !important;
}

/* 9-7-2024 */
.left_menulist li {
  position: relative;
}

.left_menulist li.active a {
  color: var(--primary-color);
}

.tr__topmenubar .navbar {
  padding: 0;
}

.tr_fin_btns {
  background: rgba(188, 146, 63, 0.2);
  padding: 0.2rem;
  border-radius: var(--radius-border);
  display: flex;
  gap: 0.125rem;
}

.tr_fin_btns a {
  padding: 0.25rem 2.5rem;
  color: var(--primary-color);
  border-radius: var(--radius-border);
  font-size: var(--body16);
  line-height: 1.4;
  display: inline-block;
}

@media (max-width: 575px) {
  .tr_fin_btns a {
    padding: 0.25rem 1rem;
  }
}

.tr_fin_btns a:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.tr_fin_btns a.active {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.low_amount p {
  color: var(--red-color);
}

.plus_amount p {
  color: var(--green-color);
}

.low_amount,
.plus_amount {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.tr__fin_tables table th {
  font-size: var(--body16);
  font-weight: var(--font-weight-regular);
  line-height: 1.4;
  padding: 0.625rem;
  background-color: var(--stroke-color);
}

.tr__fin_tables table td {
  font-size: var(--body14);
  line-height: 1.4;
}

.fin_table {
  border-radius: 5px;
  overflow: hidden;
}


.seeAll_btn:hover {
  color: var(--primary-color);
  font-weight: var(--font-weight-medium);
}

.buyRequest_right_menu .more_option {
  max-width: 100px;
  height: 40px;
}


@media (max-width:767px) {
  .tr__uploadDiamond_multiple_con .multiple_report {
    gap: 0.313rem;
  }
}

.tr__uploadDiamond_multiple_con .check1 {
  align-items: center;
  gap: 1.25rem;
}

.cart_list_wrap .more_option {
  max-width: 100px;
  min-width: 100px;
  height: 40px;
}

.stone_details_btns .fill_btn {
  padding: 0.44rem 0.75rem;
}

.gallery_op_list a:hover {
  background-color: var(--primary-color);
}

.gallery_op_list a:hover img {
  filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(2%) hue-rotate(226deg) brightness(108%) contrast(101%);
}


.stone_details .table_wrapper td span,
.stone_details .table_wrapper th span {
  white-space: nowrap;
}

.cartModal.modal button {
  width: 100%;
}

button.fill_btn.cart_btn {
  padding: 0.313rem 0.75rem;
}

/* 10-7-2024 */

ul.tr__sidebar_menu li.active img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7491%) hue-rotate(66deg) brightness(133%) contrast(95%);
}


@media (max-width: 450px) {
  .tr__profile_info {
    margin-top: 0.5rem;
  }
}

@media (max-width: 767px) {
  .tr__profile_det {
    gap: 1rem;
  }
}

.tr__password label {
  font-size: var(--body14);
  line-height: 1.4;
}

@media (max-width:350px) {
  ul.tr__uploadDiamond_list li {
    border-right: none;
  }
}

.addMarkup_modal .label_text {
  font-size: var(--body14);
  line-height: 1.4;
  margin-bottom: 0.313rem;
}

.addMarkup_modal .modal-footer {
  padding: 0.625rem 0;
}

.tr__pair_rightMenu {
  display: flex;
  align-items: center;
  justify-content: end;
  /* gap: 0; */
}

.tr__pair .pair_select.css-b62m3t-container {
  min-width: 100px;
  /* padding: 5px; */
}

.tr__pair .pair_rightList_item.mt-2.mt-lg-0 {
  overflow-x: auto;
  padding-left: 0;
  padding-bottom: 0.313rem;
  justify-content: left !important;
}

/* add group name css start */
.save_modal .addBtn_new {
  padding: 0.313rem 0.625rem !important;
  margin-bottom: 0.625rem;
}

/* add group name css end */

/*  */
.tr__pair_table th {
  font-size: var(--body16);
  background-color: var(--white-color);
  white-space: nowrap;
  color: var(--black-color);
}

.tr__pair_table tbody {
  background-color: var(--white-color);
  white-space: nowrap;
  color: var(--black-color);
}

.tr__pair_table .css-1ex1afd-MuiTableCell-root {
  padding: 0.313rem 1rem;
}

.tr__pair_table {
  overflow-x: auto;
  border-radius: var(--radius-border);
}

/*  */

/* 12-7-2024 */

.tr__newStoneDetails a.fill_btn.newstoneBtn {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: fit-content;
  padding: 0.4rem 1rem;
}

.tr__newStoneDetails a.fill_btn.newstoneBtn p {
  font-size: var(--body14);
  line-height: 1.4;
}


.tr__newStoneDetails .newstoneBtn img {
  filter: brightness(0) saturate(100%) invert(85%) sepia(100%) saturate(1%) hue-rotate(195deg) brightness(105%) contrast(101%);
}

.tr__newStoneDetails .newstoneBtn:hover img {
  filter: brightness(0) saturate(100%) invert(61%) sepia(82%) saturate(333%) hue-rotate(2deg) brightness(83%) contrast(86%);
}


.tr__newStoneDetails_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 0;
}

.tr__newStoneDetails_topbtns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
}

.more_btn {
  background-color: rgba(188, 146, 63, 0.2);
  padding: 0.4rem 0.438rem;
  border-radius: var(--radius-border);
}

.tr__newStoneDetails_title h6 {
  font-size: var(--h6);
  line-height: 1.4;
  font-weight: var(--font-weight-bold);
}

.notGraded_table_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--primary-color);
  padding: 0.438rem 0.625rem;
  border-radius: var(--radius-border) var(--radius-border) 0 0;
}

.notGraded_table_title p {
  font-size: var(--body16);
  line-height: 1.625;
  color: var(--white-color);
}

.notGraded_iconlist {
  display: flex;
  gap: 0.625rem;
}

.tr__newStoneDetails_table .card {
  border: none !important;
}

.shadow.tr__newStoneDetails_first {
  padding: 0.938rem;
  border-radius: var(--radius-border);
}

.tr__newStoneDetails_first .img-vedio {
  border-bottom: 1px solid var(--stroke-color);
  padding-bottom: 0.625rem;
}

.notGraded .notGraded_list {
  display: flex;
  align-items: center;
  gap: 1.875rem;
  margin-bottom: 1.25rem;
}

.table_row {
  display: flex;
  border-bottom: 1px solid var(--stroke-color);
}

/* Even rows */
.table_row:nth-child(even) {
  background-color: var(--white-color);
}

/* Odd rows */
.table_row:nth-child(odd) {
  background-color: #f4f4f4 !important;
}

.tr__newStoneDetails .notGraded_table_row {
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0.313rem 0.625rem;
}

.tr__newStoneDetails .notGraded_table_row h6 {
  min-width: 145px;
  font-size: var(--body14);
  line-height: 1.4;
  font-weight: var(--font-weight-bold);
}

.tr__newStoneDetails .notGraded_wrap {
  padding: 1.25rem;
  background-color: var(--white-color);
}

.tr__newStoneDetails .notGraded_list p {
  font-size: 14px;
  line-height: 1.4;
  padding: 0.5rem 0;
}

.notGraded_table_row p {
  font-size: var(--body14);
  line-height: 1.4;
}

.tr__newStoneDetails .availability p {
  white-space: nowrap;
}

.tr__newStoneDetails .sellerDetails {
  white-space: nowrap;
}

.tr__newStoneDetails .sellerDetails a {
  color: var(--primary-color);
  text-decoration: underline !important;
}

.tr__newStoneDetails .price img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(280%) hue-rotate(178deg) brightness(118%) contrast(100%);
}

.tr__newStoneDetails .price table {
  width: 100%;
  padding: 0.625rem;
}

.tr__newStoneDetails .price table th {
  background: #f4f4f4;
  padding: 0.313rem;
}

.tr__newStoneDetails .price table tbody tr td {
  padding: 0.313rem;
}

.tr__newStoneDetails .price table tbody tr:nth-child(odd) {
  background-color: var(--white-color);
}

.tr__newStoneDetails .price table tbody tr:nth-child(even) {
  background-color: #f4f4f4;
}

.tr__newStoneDetails .notGraded_iconlist img {
  cursor: pointer;
  width: 22px;
  height: 22px;
}

/* buy resuest modal responsive */

.buyRequest_modal p {
  font-size: var(--body12);
  line-height: 1.4;
}

.buyRequest_modal h6 {
  font-size: var(--body16);
  line-height: 1.4;
  color: var(--black-color);
}

.buyRequest_modal .col-md-6:not(:last-child) .crown_list,
.buyRequest_modal .loc_source_select {
  border-right: none !important;
}

.buyRequest_modal .treatment .radio_wrap {
  flex-wrap: wrap;
  gap: 0.625rem;
}

.buyRequest_modal .viewmore a,
.buyRequest_modal .measure_list p {
  font-size: var(--body14);
  line-height: 1.4;
}

.buyRequest_modal .search_price .price_dis input::placeholder {
  font-size: var(--body14);
  line-height: 1.4;
}


@media (max-width: 1450px) {
  .tr__sidebar_menu {
    max-height: calc(100vh - 250px);
  }
}

@media (max-width: 767px) {
  .tr__sidebar_menu {
    max-height: calc(100vh - 270px);
  }
}

/* 16-7-2024 */

.buyRequest_modal .accordion-body {
  padding: 1.25rem;
}

.buyRequest_modal .measure_treat {
  margin-top: 1.25rem;
}


.tr__statasticshome li a {
  padding: 0 15px;
  color: var(--black-color);
  transition: all ease-in-out 0.5s;
}

.tr__statasticshome li a:hover {
  color: var(--primary-color);
}

.tr__statasticshome .table_list li {
  border-right: 1px solid var(--black-color);
}

.tr__statasticshome li:last-child {
  border-right: none;
}

.tr__statasticshome .table_list {
  display: flex;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
}

.tr__statasticshome .table_title {
  margin-top: 1.25rem;
  gap: 1.25rem;
}

.tr__statasticshome .tr__statastics_table th,
.tr__statasticshome .tr__statastics_table td {
  font-size: var(--body14);
  line-height: 1.4;
}

.tr__statastics_table table {
  border-radius: var(--radius-border);
  overflow: hidden;
}

/* CMS pages css start */
.tr__navbar {
  justify-content: space-between;
}

.navbar_web a.tr__logo img {
  width: 100%;
  height: auto;
}

.tr__menulist a {
  font-size: var(--body16);
  /* font-weight: var(--font-weight-medium); */
  line-height: 1.4;
  transition: all ease-in-out 0.5s;
  padding: 1rem !important;
  color: var(--black-color);
}

.tr__menulist a:hover {
  color: var(--primary-color);
}

.tr__menulist {
  padding-left: 1.875rem !important;
}

.tr__btnlist {
  display: flex;
  gap: 1.25rem;
}

.tr__menulist .nav-link.active {
  color: var(--primary-color);
}

/* swiper slider */

.diamondlist_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  /* border: 1px solid var(--primary-color); */
  border: 1px solid transparent;
  padding: 1.25rem;
  border-radius: var(--radius-border);
  background: var(--white-color);
  box-shadow: 0 0 5px 0 rgb(0, 0, 0, 0.2);
}

.tr__dimondlistslider .swiper-scrollbar {
  display: none;
}

.diamondlist_item p {
  color: var(--primary-color);
  font-size: var(--body16);
  line-height: 1;
  text-align: center;
  min-height: 32px;
}

.tr__shapeslider {
  padding: 0.625rem 0 !important;
}

.tr__dimondlistslider {
  /* margin: 3.125rem 0; */
  padding-bottom: 50px;
}

/* .diamondlist_item img{
    filter: brightness(0) saturate(100%) invert(58%) sepia(67%) saturate(378%) hue-rotate(2deg) brightness(89%) contrast(91%);
} */

/* hero section */

.tr__herosec_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.125rem;
  padding: 6.25rem 0 1.875rem;
}

.tr__herosec_con,
.tr__herosec_img {
  max-width: calc(50% - 25px);
  flex: 0 0 calc(50% - 25px);
}

.tr__herosec_con h1 {
  font-size: var(--h1);
  line-height: 1.2;
  font-weight: var(--font-weight-medium);
  margin-bottom: 1.25rem !important;
}

.tr__herosec_con p {
  font-size: var(--body16);
  line-height: 1.4;
  margin-bottom: 1.25rem !important;
}

.tr__herosec_img img {
  max-width: 100%;
  height: 100%;
  width: 100%;
}

.tr__herosec {
  background-color: rgba(188, 146, 63, 0.2);
  background-image: var(--linear-gradient);
}


@media (max-width: 991px) {
  .tr__herosec_wrap {
    flex-direction: column-reverse;
  }

  .tr__herosec_con,
  .tr__herosec_img {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

/* quality diamond section */

.tr__qualitydiamond_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.125rem;
  padding: 6.25rem 0;
}

.tr__qualitydiamond_img,
.tr__qualitydiamond_con {
  max-width: calc(50% - 25px);
  flex: 0 0 calc(50% - 25px);
}

.tr__qualitydiamond_img_Wrap img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.tr__qualitydiamond .playbtn {
  display: flex;
  justify-content: center;
  color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background: var(--linear-gradient);
  transition: all 0.3s ease-in-out 0s;
  box-shadow: rgba(193, 244, 246, 0.698) 0px 0px 0px 0px;
  animation: 1.2s cubic-bezier(0.8, 0, 0, 1) 0s infinite normal none running pulse;
  align-items: center;
  border: 0;
}

.tr__qualitydiamond .playbtn:is(:hover, :focus) {
  transform: scale(1.2);
}

@keyframes pulse {
  100% {
    box-shadow: 0 0 0 45px rgba(193, 244, 246, 0);
  }
}

.tr__qualitydiamond_img_Wrap img {
  position: relative;
}

.tr__qualitydiamond_img_Wrap::after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 1.25rem;
}

.tr__qualitydiamond_img_Wrap {
  position: relative;
}

.tr__qualitydiamond_img_Wrap .playbtn {
  position: absolute;
  top: 43%;
  left: 43%;
}

.tr__qualitydiamond_con h4 {
  font-size: var(--h4);
  line-height: 1.4;
  font-weight: var(--font-weight-bold);
  margin-bottom: 0.938rem !important;
  color: var(--primary-color);
}

.tr__qualitydiamond_con p {
  font-size: var(--body16);
  color: var(--dark-color);
  line-height: 1.4;
  margin-bottom: 0.938rem !important;
}

ol.tr__qualitydiamondlist li {
  font-size: var(--body14);
  margin-bottom: 0.438rem;
  font-weight: var(--font-weight-medium);
  line-height: 1.4;
}

@media (max-width: 991px) {
  .tr__qualitydiamond_wrap {
    flex-direction: column;
  }

  .tr__qualitydiamond_img,
  .tr__qualitydiamond_con {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

/* unique diamond */

.tr__uniquediamond {
  background-image: url(../imgs/bg_pattern.png);
  background-repeat: repeat;
  /* background-position: left top; */
  background-size: auto;
  height: 100%;
  padding: 1.875rem;
  position: relative;
}

.tr__uniquediamond::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(127deg,
      rgba(17, 64, 24, 1) 0%,
      rgba(0, 124, 20, 1) 47%,
      rgba(64, 64, 64, 1) 100%);
  width: 100%;
  height: 100%;
  z-index: -1;
}


.tr__uniquediamond_wrap {
  padding: 100px 0;
}

.tr__uniquediamond_list,
.tr__uniquediamond_lists {
  display: flex;
  gap: 1.875rem;
  justify-content: space-between;
  /* align-items: flex-start; */
  margin-bottom: 2.5rem;
}

.tr__uniquediamond_lists {
  justify-content: center;
  gap: 1.875rem;
  margin-bottom: 0;
}


.tr__uniquediamond_item {
  padding: 1.25rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: var(--radius-border);
  max-width: calc(33% - 15px);
  flex: 0 0 calc(33% - 15px);
  /* background-color: var(--white-color); */
  transition: all ease-in-out 0.5s;
  border: 1px solid transparent;
}

.tr__uniquediamond_item:hover {
  /* transform: scale(1.1); */
  border: 1px solid var(--primary-color);
  /* background-color: rgba(255, 255, 255, 0.9); */
  background-color: var(--white-color);
}

.tr__uniquediamond_title {
  margin-bottom: 3.125rem;
}

.tr__uniquediamond_title h3 {
  text-align: center;
  font-weight: var(--font-weight-medium);
  line-height: 1.4;
  font-size: var(--h3);
  color: var(--white-color);
}


.tr__uniquediamond_imgwrap img {
  width: 50px;
  /* width: 100%; */
  height: 50px;
}

.tr__uniquediamond_item h6 {
  font-size: var(--body18);
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
  margin-bottom: 0.938rem !important;
}

.tr__uniquediamond_imgwrap {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 0.938rem;
}

.tr__uniquediamond_item p {
  font-size: var(--body14);
  line-height: 1.4;
  min-height: 60px;
}

@media (max-width: 767px) {

  .tr__uniquediamond_list,
  .tr__uniquediamond_lists {
    flex-direction: column;
  }

  .tr__uniquediamond_item {
    max-width: 100%;
    flex: 0 0 100%;
  }
}


/* diamond type */

.tr__diamondtype_item {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.875rem;
}

.tr__diamondtype_img,
.tr__diamondtype_con {
  max-width: calc(50% - 15px);
  flex: 0 0 calc(50% - 15px);
}

.tr__diamondtype_imgwrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tr__diamondtype_imgwrap img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-border);
}

.tr__diamondtype_con h5 {
  font-size: var(--h5);
  line-height: 1.4;
  font-weight: var(--font-weight-bold);
  margin-bottom: 1.25rem !important;
}

.tr__diamondtype_con p {
  font-size: var(--body18);
  line-height: 1.4;
  color: var(--dark-color);
}

.tr__diamondtype_wrap {
  margin: 100px 0;
}

.tr__diamondtype_con {
  padding: 0 60px;
}

@media (max-width: 767px) {
  .tr__diamondtype_item {
    flex-direction: column;
  }

  .tr__diamondtype_item:last-child {
    flex-direction: column-reverse;
  }

  .tr__diamondtype_img,
  .tr__diamondtype_con {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
  }
}

/* buy request */

.tr__buydiamond_wrap {
  margin: 100px 0 0;
  padding: 100px 0;
}

.tr__buydiamond_list {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}

.tr__buydiamond_item {
  max-width: calc(33% - 15px);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33% - 15px);
  /* border: 1px solid var(--primary-color); */
  padding: 1.25rem;
  border-radius: var(--radius-border);
  /* background: linear-gradient(180deg, rgba(23, 37, 104, 0) 38.94%, #bc923f47 100%); */
  background: white;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  transition: all ease-in-out 0.5s;
}

.tr__buydiamond_item:hover {
  border: 1px solid var(--primary-color);
}

.tr__buydiamond_title {
  margin-bottom: 60px;
  text-align: center;
}

.tr__buydiamond_title h3 {
  font-weight: var(--font-weight-medium);
  line-height: 1.4;
  font-size: var(--h3);
}

.tr__buydiamond {
  background-image: var(--linear-gradient);
  /* background: rgb(242 233 217); */
}

.tr__buydiamond_item h5 {
  font-size: var(--h6);
  font-weight: var(--font-weight-bold);
  line-height: 1.4;
  margin-bottom: 0.938rem !important;
}

.tr__buydiamond_imgwrap {
  margin-bottom: 0.938rem;
  width: fit-content;
  padding: 10px;
  background: linear-gradient(180deg, rgba(23, 37, 104, 0) 38.94%, #bc923f47 100%);
  ;
  border-radius: 5px;
}

.tr__buydiamond_imgwrap img {
  max-width: 100%;
  height: 100%;
}

.tr__buydiamond_item p {
  font-size: var(--body16);
  line-height: 1.4;
  min-height: 135px;
}

@media (max-width: 991px) {
  .tr__buydiamond_list {
    flex-wrap: wrap;
  }

  .tr__buydiamond_item {
    max-width: calc(50% - 15px);
    flex: 0 0 calc(50% - 15px);
  }
}

@media (max-width: 767px) {
  .tr__buydiamond_list {
    flex-wrap: wrap;
  }

  .tr__buydiamond_item {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .tr__buydiamond_wrap {
    padding: 0;
  }
}

/* cta */

.tr__cta_wrap {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* background: aliceblue; */
  padding: 1.25rem;
  border-radius: var(--radius-border);
}

.tr__cta_wrap h5 {
  font-size: var(--h4);
  line-height: 1.4;
  font-weight: var(--font-weight-bold);
}

.tr__cta {
  /* background: #f2e9d9; */
  padding: 20px 0;
  margin: 50px 0;
}

.tr__cta_wrap a {
  padding: 0.625rem 1.25rem;
}

/* footer */

.tr__footer_wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  padding: 100px 0 50px;
  border-bottom: 1px solid var(--primary-color);
}

ul.tr__footer_Social {
  display: flex;
  gap: 1.25rem;
  align-items: flex-start;
  margin-bottom: 1.875rem;
  /* justify-content: center; */
}

.tr__footer_logo,
.tr__footer_link {
  max-width: calc(30% - 15px);
  flex: 0 0 calc(30% - 15px);
}

.tr__footer_cta {
  max-width: calc(30% - 15px);
  flex: 0 0 calc(30% - 15px);
}

.tr__footer_logowrap img {
  max-width: 40%;
  height: auto;
}

.tr__footer_link h6,
.tr__footer_cta h6 {
  font-size: var(--h6);
  line-height: 1.4;
  font-weight: var(--font-weight-bold);
  margin-bottom: 0.938rem !important;
}

ul.tr__footer_Social li img {
  width: 20px;
  height: 20px;
  transition: all ease-in-out 0.5s;
}

.tr__footer_link li a {
  color: var(--dark-color);
  font-size: var(--body14);
  line-height: 1.4;
  font-weight: var(--font-weight-medium);
}

.tr__footer_link li a:hover,
.tr__footer_bottomlinks li a:hover {
  color: var(--primary-color);
}

.tr__footer_cta p {
  font-size: var(--body14);
  line-height: 1.4;
  margin-bottom: 0.625rem !important;
}

.tr__footer_cta .email_wrap input[type="email"] {
  padding: 0.313rem;
  border: 2px solid var(--primary-color);
  border-radius: var(--radius-border) 0 0 var(--radius-border);
}

.tr__footer_cta .email_wrap button {
  padding: 0.313rem;
  border-radius: 0 var(--radius-border) var(--radius-border) 0;
  background: var(--primary-color);
  color: var(--white-color);
  border: 2px solid var(--primary-color);
}

.tr__footer_cta .email_wrap input[type="email"]::placeholder {
  font-size: var(--body14);
}

.tr__footer_cta .email_wrap input[type="email"]:focus-visible {
  outline: 0;
}

.tr__footer_bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 1.25rem 0;
}

.tr__footer_bottomlinks ul {
  display: flex;
  gap: 1.25rem;
  margin-bottom: 0;
}

.tr__footer_bottomlinks li a {
  color: var(--dark-color);
  font-weight: var(--font-weight-medium);
}

.tr__footer_copyright p {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;
}

@media (max-width: 991px) {
  .tr__footer_wrap {
    flex-wrap: wrap;
  }

  .tr__footer_logo,
  .tr__footer_link {
    max-width: calc(50% - 15px);
    flex: 0 0 calc(50% - 15px);
  }

  .tr__footer_cta {
    max-width: calc(80% - 15px);
    flex: 0 0 calc(80% - 15px);
  }
}

@media (max-width: 767px) {

  .tr__footer_logo,
  .tr__footer_link {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .tr__footer_cta {
    max-width: 100%;
    flex: 100%;
  }
}

.tr__footer {
  background: #f2e9d9;
}

.tr__loc img {
  width: 25px;
  height: 25px;
  padding-right: 0.313rem;
}

.tr__loc a {
  color: var(--dark-color);
}

.tr__footer_logo p {
  font-size: var(--body14);
  line-height: 1.4;
  margin-bottom: 15px !important;
  color: var(--dark-color);
}

.tr__footer_logowrap {
  margin-bottom: 15px;
}

.tr__store li img {
  max-width: 130px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

ul.tr__store {
  display: flex;
  gap: 15px;
}

.tr__footer form.email_wrap {
  display: flex;
  width: 100%;
}

ul.tr__footer_Social li img:hover {
  filter: brightness(0) saturate(100%) invert(57%) sepia(44%) saturate(551%) hue-rotate(2deg) brightness(95%) contrast(90%);
}

.tr__footer_link li {
  margin-bottom: 0.313rem;
}

.tr__footer ul {
  padding-left: 0;
}


/* terms condition */
/* Common */
.tr__list-style {
  list-style: disc;
}

.tr__right_padding .ps-4 {
  padding-left: 2rem !important;
}

.tr__right_padding a {
  color: var(--black-color);
  border-bottom: 1px solid var(--stroke-color);
}

.tr__right_padding ol li {
  list-style: circle;
}



@media (max-width: 767px) {

  .tr__right_padding li,
  .tr__right_padding p {
    font-size: var(--body14);
  }

  .tr__right_padding h5 {
    font-size: var(--body18);
  }
}

@media (max-width: 376px) {

  .tr__right_padding li,
  .tr__right_padding p {
    font-size: var(--body12);
  }


  .tr__right_padding h5 {
    font-size: var(--body16);
  }
}

/* CMS pages css end */

/* new loader css start */
.diamondCon {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  /* background: #ffff; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  gap: 80px;
}

.diamond {
  display: block;
  width: 200px;
  height: auto;
  position: relative;
}

.diamond li {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  opacity: 0;
  z-index: 100;
  transition: 400ms ease;
}

.diamond li:nth-child(1) {
  border-width: 20px 20px 0 20px;
  border-color: #BC923F transparent transparent transparent;
  left: 0;
  right: 0;
  margin: auto;
  animation: traingle1 200ms 2.2ms ease, opacity 2s 5.4ms ease infinite;
  animation-fill-mode: forwards;
}

.diamond li:nth-child(2) {
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent #BC923F;
  left: 59px;
  animation: traingle2 200ms 1.8ms ease, opacity 2s 5.4ms ease infinite;
  animation-fill-mode: forwards;
}

.diamond li:nth-child(3) {
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent #BC923F;
  right: 59px;
  animation: traingle3 200ms 2ms ease, opacity 2s 5.4ms ease infinite;
  animation-fill-mode: forwards;
}

.diamond li:nth-child(4) {
  border-width: 0 20px 20px 20px;
  border-width: 20px 20px 0 20px;
  border-color: #BC923F transparent transparent transparent;
  animation: traingle4 200ms 1.6ms ease, opacity 2s 5.6ms ease infinite;
  animation-fill-mode: forwards;
}

.diamond li:nth-child(5) {
  border-width: 0 20px 20px 20px;
  border-width: 20px 20px 0 20px;
  border-color: #BC923F transparent transparent transparent;
  animation: traingle5 200ms 1.4ms ease, opacity 2s 5.6ms ease infinite;
  animation-fill-mode: forwards;
}

.diamond li:nth-child(6) {
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent #BC923F;
  left: 0;
  right: 0;
  margin: auto;
  animation: traingle6 200ms 1.2ms ease, opacity 2s 5.6ms ease infinite;
  animation-fill-mode: forwards;
}

.diamond li:nth-child(7) {
  border-width: 20px 20px 0 20px;
  border-color: #BC923F transparent transparent transparent;
  left: 0;
  right: 0;
  margin: auto;
  animation: traingle7 200ms 1ms ease, opacity 2s 5.8ms ease infinite;
  animation-fill-mode: forwards;
}

.textCon {

  /* margin-top: -500px; */
  text-align: center;
  line-height: 50px;
  font-size: 30px;
  color: #BC923F;
  opacity: 0;
  animation: text 500ms 2.4ms ease;
  animation-fill-mode: forwards;
  transform: translateY(-110px);
  user-select: none;
  pointer-events: none;

}

@keyframes traingle1 {
  0% {
    top: -50px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes traingle2 {
  0% {
    top: -50px;
    opacity: 0;
  }

  100% {
    top: 1px;
    opacity: 1;
  }
}

@keyframes traingle3 {
  0% {
    top: -50px;
    opacity: 0;
  }

  100% {
    top: 1px;
    opacity: 1;
  }
}

@keyframes traingle4 {
  0% {
    right: 59px;
    top: -33px;
    opacity: 0;
  }

  100% {
    right: 59px;
    top: 22px;
    opacity: 1;
  }
}

@keyframes traingle5 {
  0% {
    left: 59px;
    top: -33px;
    opacity: 0;
  }

  100% {
    left: 59px;
    top: 22px;
    opacity: 1;
  }
}

@keyframes traingle6 {
  0% {
    top: -33px;
    opacity: 0;
  }

  100% {
    top: 23px;
    opacity: 1;
  }
}

@keyframes traingle7 {
  0% {
    top: -10px;
    opacity: 0;
  }

  100% {
    top: 44px;
    opacity: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@keyframes text {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    /* margin-top: 0px;s */
    opacity: 1;
    transform: translateY(0);
  }
}

.diamondCon .diamond {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* new loader css end */
@media (max-width: 991px) {

  .buyRequest_modal .loc_source_select_list .css-13cymwt-control,
  .buyRequest_modal .crown_list input {
    width: 100%;
    max-width: 100%;
  }
}

.buyRequest_modal .loc_source_select input[type="checkbox"],
.buyRequest_modal .bgm input[type="checkbox"] {
  display: block !important;
}

.buyRequest_modal .loc_source_select .check_text,
.buyRequest_modal .bgm .check_text {
  font-size: var(--body12);
}

.buyRequest_modal .bgm {
  margin-bottom: 0.625rem;
}

.buyRequest_modal .bgm .check_text {
  margin-left: 0.313rem;
}

.buyRequest_modal .grading_report {
  margin-top: 0.625rem;
}

/* 22-7-2024 */
@media (max-width: 1750px) {
  .tr__topmenus {
    padding-left: 1rem;
  }

  #Tr__menuwrapper .right_menubar {
    padding-right: 1rem;
  }
}

.right_menulist .con_drop img {
  width: 32px;
  height: auto;
}


.tr__topmenus .left_menulist li a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  /* border-radius: 5px; */
  /* height: 2px; */
  top: 95% !important;
  border-bottom: 3px solid var(--primary-color) !important;
  left: 0;
  background: transparent;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}


.tr__topmenus .left_menulist li a:hover::after,
.tr__topmenus .left_menulist li.active a::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  background: var(--primary-color);
}

.tr__searchStock .searchStock_title img {
  width: 30px;
  height: 30px;
}

.tr__searchStock .eyeClean {
  margin: 0 25px;
}

@media (max-width: 991px) {
  .tr__searchStock .eyeClean {
    margin: 0;
  }

  .color_drop.col-xl-3.col-md-6.col-sm-12.col-12 {
    padding: 0 0.938rem !important;
  }
}

.search_Fluorescence .col-xs-12.col-md-6.col-sm-12.col-xl-4.col-xxl-4.col-lg-4.col {
  border-right: 1px solid var(--stroke-color);
}

.color_drop.col-xl-3.col-md-6.col-sm-12.col-12 {
  padding: 0 0 0 1.875rem;
}

.tr__resulttable table th,
.tr__resulttable table tr td {
  color: var(--black-color);
}

.tr__resulttable table tbody tr:nth-child(odd) {
  background-color: var(--white-color) !important;
}

.tr__resulttable table tbody tr:nth-child(even) {
  background-color: #F4F4F4 !important;
}

.tr__resulttable table thead th {
  font-weight: var(--font-weight-smedium) !important;
}

.diamondList_container .result_right p {
  color: var(--black-color);
  font-size: var(--body12);
  line-height: 1.4;
}


.navbar_web {
  position: sticky;
  top: 0;
  left: 0;
  background: var(--white-color);
  z-index: 9;
}

@media (max-width:991px) {
  .navbar_web .tr__menulist {
    padding-left: 0 !important;
    justify-content: center;
    align-items: center;
  }

  .navbar_web {
    box-shadow: 0px 0px 5px rgba(188, 146, 63, 0.25);
  }

  .tr__navbar {
    left: 0;
    padding: 0 20px 20px;
    position: absolute;
    right: 0;
    top: 75px;
    background: white;
  }

  .navbar_web .navbar-toggler {
    border: none;
  }

  .tr__herosec_wrap {
    padding: 1.5rem 0;
    gap: 0;
  }
}

/* sidebar overflow hidden */
body.scroll {
  overflow: hidden;
  position: relative;
}

/* body.scroll::before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
} */

@media (max-width: 575px) {
  .cart_right_list a {
    font-size: var(--body12);
  }

  .cart_right_list li {
    margin-bottom: 0.313rem;
  }

  .cart_right_list {
    justify-content: left;
  }
}

.navbar_web.navbar .navbar-toggler img {
  width: 20px;
  height: 20px;
}

.pair_list td {
  text-transform: uppercase !important;
}

.save_modal .save_modallist p span {
  color: var(--red-color);
}

.save_modal .modal-dialog {
  max-width: 450px;
  /* width: 100%; */
}

.save_modal .modal-footer button {
  width: 100%;
}

.save_modal .modal-footer {
  padding: 1.25rem;
  border-top: none;
}

.save_modal span.save_daily {
  color: var(--primary-color);
  cursor: pointer;
}

.save_modal .check1 {
  display: flex;
  gap: 0.938rem;
}

.save_modal .check_text {
  margin-bottom: 0.125rem;
  color: var(--black-color);
}

.save_modal .save_modallist p {
  color: var(--black-color);
}

.tr__announcement_wrap h3 {
  margin-bottom: 1.25rem !important;
}

.tr__announcement_table table tr {
  font-size: var(--body14);
  line-height: 1.4;
}

/* 25-7-2024 */

/* About us start */
/* .tr__about_us_main {
  background-color: var(--white-color);
  max-width: max-content;
  padding: 2rem;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  position: relative;
  z-index: var(--z-index-header);
}

.tr__aboutus_heading {
  display: flex;
  align-items: center;
  gap: 0.875rem;
  margin-bottom: 0.25rem
}

.tr__aboutus_heading h4 {
  font-size: var(--body20);
  font-weight: var(--font-weight-bold);
}

.tr__about_us_main .border-content {
  height: 0.125rem;
  width: 2rem;
  background-color: var(--primary-color);
}

.tr__aboutus-bg {
  background-color: rgba(188, 146, 63, 0.2);
  background-image: url('./../imgs/about_heading.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding: 6rem 0;
  position: relative;
}

.tr__aboutus-bg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index);
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0) 100%);
}



.tr__about_us_main h2 {
  font-weight: var(--font-weight-bold);
  margin-bottom: 1rem !important;
}

.tr__about_us_main p {
  max-width: 36rem;
  width: 100%;
}

.tr__about_us_main p:first-of-type {
  margin-bottom: 1rem !important;
} */
.tr__counts_heading_port {
  margin-bottom: 4rem;
}

.tr__counts_heading_port h2 {
  font-weight: var(--font-weight-bold) !important;
  font-family: var(--primary-font) !important;
}

.tr__counts_heading_port .border-content {
  height: 0.25rem;
  width: 8rem;
  background-color: var(--primary-color);
}

.tr__aboutus_heading {
  background-color: rgba(188, 146, 63, 0.2);
  background-image: var(--linear-gradient);
  font-weight: var(--font-weight-medium);
  height: 10rem;
}

.tr__aboutus_heading {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.tr__aboutus_heading h4 {
  font-weight: var(--font-weight-bold);
  font-size: 2.2rem;
}

.tr__aboutus_heading .border-content {
  height: 0.125rem;
  width: 2rem;
  background-color: var(--primary-color);
}

.tr__aboutus_img_container {
  max-width: 540px;
  height: 540px;
  overflow: hidden;
  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem;
  -ms-border-radius: 0.75rem;
  -o-border-radius: 0.75rem;
}

.tr__aboutus_img_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.tr__about_us_main h2 {
  font-weight: var(--font-weight-bold);
}

.tr__about_us_main {
  max-width: 37.5rem;
  width: 100%;
}

.tr__about_us_main p:first-of-type {
  margin-bottom: 1rem !important;
}

.tr__aboutus_subheading {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem !important;
}

.tr__aboutus_subheading .border-content {
  height: 0.125rem;
  width: 2rem;
  background-color: var(--primary-color);
}

.gap-aboutus {
  gap: 5rem;
  margin: 5rem 0rem 5rem 0rem;
}

.tr_aboutus_counts_main {
  display: flex;
  gap: 2rem;
  /* align-items: center; */
}

.tr_aboutus_counts_main h1 {
  font-size: 3rem;
  background-color: var(--page-color);
  /* padding: 1rem; */
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-bold);
  background-color: #E7DECE;
  background-image: var(--linear-gradient);
  border-radius: 0.75rem;
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem;
  -ms-border-radius: 0.75rem;
  -o-border-radius: 0.75rem;
  color: black;
}

.tr_aboutus_count_content h5 {
  font-weight: var(--font-weight-bold);
  font-family: var(--secondry-font) !important;
  margin-bottom: 0.50rem !important;
}

.tr_aboutus_count_content p {
  font-size: var(--body16);
  padding-bottom: 4rem;
}

.tr_aboutus_count_content {
  max-width: 64rem;
  width: 100%;
}

.tr_aboutus_count_h1 {
  position: relative;
  z-index: 1;
  width: auto;
}

.tr_aboutus_count_h1::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  border: 1px solid var(--primary-color);
  transform: translate(-50%, -0%);
  -webkit-transform: translate(-50%, -0%);
  -moz-transform: translate(-50%, -0%);
  -ms-transform: translate(-50%, -0%);
  -o-transform: translate(-50%, -0%);
  z-index: -1;
}

.max-w-max {
  max-width: max-content;
  margin: 4rem auto;
}

.card_wrapper_main {
  border: 1px solid var(--primary-color);
  padding: 1.6rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  box-shadow: -7px 7px 0px 0px #f2e9d9;
  background-color: var(--light-color);
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.card_wrapper_main:hover {
  box-shadow: -12px 12px 0px 0px #f2e9d9;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.card_img_wrapper {
  max-width: 160px;
  height: 160px;
  width: 100%;
  margin: 0 auto 1.7rem auto;
}

.card_img_wrapper img {
  width: 100%;
  height: 100%;
}

.tr__gaping_add {
  gap: 2rem;
}

.card_wrapper_main h5 {
  font-weight: var(--font-weight-bold);
  margin-bottom: 0.5rem !important;
}

.tr_extra_b_margin {
  margin-bottom: 8rem;
}

@media (max-width: 1399px) {
  .gap-aboutus {
    gap: 3rem;
  }

  .tr__aboutus_img_container {
    max-width: 100%;
    width: 100%;
    height: 380px;
  }

  .tr_aboutus_counts_main h1 {
    font-size: 2rem;
  }

  .tr__aboutus_heading h4 {
    font-size: 1.8rem;
  }

  .max-w-max {
    margin: 1rem auto 4rem;
  }

  .tr_aboutus_counts_main h1 {
    width: 4rem;
    height: 4rem;
  }

  .tr_aboutus_count_content p {
    padding-bottom: 2rem
  }

  .tr__gaping_add {
    gap: 1rem;
  }

  .card_wrapper_main {
    padding: 1rem;
  }
}

/* @media (max-width: 1199px) {} */

@media (max-width: 991px) {
  .gap-aboutus {
    margin: 5rem 0rem 3rem 0rem;
  }

  .tr__counts_heading_port {
    margin-bottom: 3rem;
  }

  .tr_extra_b_margin {
    margin-bottom: 4rem;
  }

  .tr__aboutus_img_container {
    height: auto;
  }

  .tr_aboutus_counts_main h1 {
    font-size: 2rem;
  }

  .tr_aboutus_count_content p {
    padding-bottom: 2rem;
  }
}

/* @media (max-width: 767.98px) {} */

@media (max-width: 575px) {

  .tr_aboutus_counts_main h1 {
    width: 4rem;
    height: 4rem;
  }

  .tr__aboutus_img_container {
    height: auto;
  }

  .gap-aboutus {
    margin: 2.5rem 0rem 2.5rem 0rem;
    gap: 2.5rem;
  }

  .max-w-max {
    margin: 2em auto;
  }

  .tr_extra_b_margin {
    margin-bottom: 4rem;
  }

  .tr__aboutus_heading h4 {
    font-size: 1.6rem;
  }

  .tr__aboutus_heading {
    height: 8rem;
  }

  .tr__counts_heading_port {
    margin-bottom: 2rem;
  }
}





/* About us end */


/* Contact us start */
.tr__contact_us_bg {
  background-color: rgba(188, 146, 63, 0.2);
  background-image: var(--linear-gradient);
  font-weight: var(--font-weight-medium);
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.tr__contactus_heading .border-content {
  height: 0.125rem;
  width: 2rem;
  background-color: var(--primary-color);
}

.tr__contactus_heading {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.tr__contactus_heading h4 {
  font-weight: var(--font-weight-bold);
  font-size: 2.2rem;
}

.tr__contact_us_bg p {
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
  font-size: 1.2rem;
}

.tr__about_us_form_img_wrapper {
  max-width: 800px;
  height: 850px;
}

.tr__about_us_form_img_wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tr__form_wrapper_main {
  display: flex;
  align-items: center;
  gap: 4rem;
  padding: 0rem 0rem 5rem 0rem;
}

.tr__width_custome {
  width: 46%;
  padding: 0 2rem;
}

.tr__contact_heading p {
  margin-bottom: 0.50rem !important;
}

.tr__contact_heading h2 {
  font-weight: var(--font-weight-bold) !important;
  font-family: var(--primary-font) !important;
  margin-bottom: 0.188rem !important;
  margin-top: 4rem;
  margin-bottom: 0.50rem !important;
  color: var(--primary-color);
}

.tr__contact_info_heading h2 {
  font-weight: var(--font-weight-bold) !important;
  font-family: var(--primary-font) !important;
  margin-bottom: 0.188rem !important;
  /* margin-top: 4rem; */
  margin-bottom: 0.50rem !important;
}

.tr__contact_info_heading .border-content {
  height: 0.25rem;
  width: 8rem;
  background-color: var(--primary-color);
}

;

.tr__contact_heading p {
  font-weight: var(--font-weight-medium) !important;
  font-family: var(--primary-font) !important;
  /* max-width: 20.1rem; */
  margin-bottom: 0.375rem !important;
}

.tr__contact_heading .border-content {
  height: 0.25rem;
  width: 8rem;
  background-color: var(--primary-color);
}

/* form */
.tr__form-container {
  padding: 2rem 0 0 0;
  border-radius: 10px;
  width: 100%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.tr__input_main_wrapper_area textarea {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  min-height: 150px;
  max-height: 400px;
}

.tr__input_main_wrapper_area {
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--primary-color);
  border-radius: 4rem;
  -webkit-border-radius: 4rem;
  -moz-border-radius: 4rem;
  -ms-border-radius: 4rem;
  -o-border-radius: 4rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  -webkit-border-radius: 1rem !important;
  -moz-border-radius: 1rem !important;
  -ms-border-radius: 1rem !important;
  -o-border-radius: 1rem !important;
  border-radius: 1rem !important;
}

.tr__input_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.tr__form-group {
  margin-bottom: 25px;
  width: 100%;
}

.tr__form-group input:focus,
input:focus-visible {
  outline: none;
}

.tr__form-group textarea {
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.tr__user_icon_img_main {
  min-width: 24px;
  width: 24px;
  height: 24px;
}

.tr__user_icon_img_main img {
  height: 100%;
  width: 100%;
}

.tr__form-group label {
  font-size: var(--body18);
  margin-bottom: 0.50rem;
  font-weight: var(--font-weight-bold);
  font-family: var(--secondry-font);
  letter-spacing: 0.063rem;
  line-height: 1.4;
}

.tr__input_main_wrapper {
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--primary-color);
  border-radius: 4rem;
  -webkit-border-radius: 4rem;
  -moz-border-radius: 4rem;
  -ms-border-radius: 4rem;
  -o-border-radius: 4rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.tr__input_main_wrapper input {
  background-color: transparent;
  border: none;
  width: 100%;
}

.tr__form-container .submit-btn {
  background: var(--primary-color);
  color: var(--white-color);
  padding: 10px 20px;
  border: none;
  border-radius: 0.50rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  border: 1px solid var(--primary-color);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-border-radius: 0.50rem;
  -moz-border-radius: 0.50rem;
  -ms-border-radius: 0.50rem;
  -o-border-radius: 0.50rem;
  box-shadow: -5px 5px 0px 0px rgba(242, 233, 217, 1);
}

.tr__form-container .submit-btn:hover {
  background-color: var(--light-color);
  color: var(--primary-color);
}

.tr__form-container .submit-btn .arrow {
  margin-left: 10px;
  transition: margin-left 0.3s ease;
}

.tr__form-container .submit-btn:hover .arrow {
  margin-left: 15px;
}

.tr__form-container .submit-btn:focus {
  outline: none;
}

/* contact_info */
.tr__grid_contact_info {
  margin-bottom: 8rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.tr__contact_us_image_container {
  max-width: 60px;
  width: 100%;
  min-width: 60px;
}

.tr__contact_us_image_container img {
  width: 100%;
  height: 100%;
}

.tr__grid_contact_info .row {
  gap: 1rem;
}

.tr__grid_contact_info .col {
  padding: 0;
}

.tr__bg_contact_us {
  background-color: rgba(188, 146, 63, 0.2);
  background-image: var(--linear-gradient);
  font-weight: var(--font-weight-medium);
  max-width: max-content;
  padding: 1rem;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  box-shadow: -7px 7px 0px 0px rgba(242, 233, 217, 1);
}

.tr__grid_contact_card_contaier {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  border: 1px solid var(--primary-color);
  padding: 1rem 1.50rem;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.tr__grid_contact_card_content h5 {
  font-size: 1rem;
  margin-bottom: 0.375rem !important;
  font-weight: var(--font-weight-bold);
}

.tr__grid_contact_card_content a {
  text-decoration: none;
  color: var(--primary-color);
}

@media (max-width: 1441px) {
  .tr__about_us_form_img_wrapper {
    max-width: 600px;
  }

  .tr__width_custome {
    padding: 0 0rem;
  }
}

@media (max-width: 1199px) {
  .tr__about_us_form_img_wrapper {
    max-width: 450px;
  }

  .tr__form_wrapper_main {
    gap: 2rem;
  }

  .tr__width_custome {
    width: 100%;
    padding-right: 1rem;
  }

  .tr__input_main_wrapper {
    padding: 0.7rem;
  }

  .tr__contact_heading h2 {
    margin-top: 2rem;
  }

  .tr__form_wrapper_main {
    padding: 0rem 0rem 2rem 0rem;
  }

  .tr__grid_contact_info {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 991px) {
  .tr__form_wrapper_main {
    flex-direction: column;
  }

  .tr__about_us_form_img_wrapper {
    width: 100%;
    max-width: 100%;
    height: 27.25rem;
  }

  .tr__width_custome {
    padding-left: 1rem;
  }

  .tr__grid_contact_info {
    margin-bottom: 4rem;
  }

  .max-w-max {
    margin: 2rem auto;
  }
}

@media (max-width: 768px) {
  .tr__width_custome {
    max-width: 92%;
  }
}

@media (max-width: 575px) {
  .tr__grid_contact_info {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 0.50rem;
  }

  .tr__contact_heading p {
    max-width: 16.875rem;
    width: 100%;
  }

  .tr__input_wrapper {
    flex-direction: column;
    gap: 0;
  }

}


/* Contact us end */


/* for-buyer pages start */
.tr__for_buyer_bg_main {
  /* background-image: url('./../imgs/for_buyers_header1.jpg'); */
  background-size: cover;
  /* height: 60vh; */
  background-position: top center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-bottom: 2rem; */
  position: relative;
  z-index: 1;
  background-color: rgba(188, 146, 63, 0.2);
  background-image: var(--linear-gradient);
}

.tr__for_buyer_bg_content {
  width: 100%;
  padding: 3rem 2rem;
  position: relative;
  z-index: 3;
  max-width: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.tr__for_buyer_content_wrapper {
  width: 43%;
}

.tr__for_buyer_content_wrapper p {
  width: 100%;
  /* max-width: 60%; */
}

.tr__for_buyer_content_wrapper h1 {
  font-weight: var(--font-weight-bold);
  margin-bottom: 1rem !important;
  color: var(--primary-color);
}

.tr__diamond_img_wrapper img {
  width: 100%;
  height: 100%;
}

.tr__diamond_img_wrapper {
  max-width: 500px;
  width: 100%;
  background-color: #ffffff4a;
  border: 1px solid var(--primary-color);
  box-shadow: -7px 7px 0px 0px #bc923f38;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.tr_sailent_heading h2 {
  font-size: var(--h3);
  font-weight: var(--font-weight-bold);
}

.tr_sailent_heading {
  margin: 7rem auto !important;
  max-width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tr_sailent_heading .border-content {
  height: 0.25rem;
  width: 8rem;
  margin-top: 0.30rem;
  background-color: var(--primary-color);
}

.tr__silent_img_wrapper {
  width: 100%;
  height: 28.125rem;
  max-width: 28.125rem;
  background-color: #bc923f1c;
  /* background-color: #ffffff4a; */
  border: 1px solid var(--primary-color);
  box-shadow: -7px 7px 0px 0px #bc923f2f;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.tr__silent_img_wrapper img {
  width: 100%;
  height: 100%;
  padding: 1rem 0;
}

.tr_salient_features_content_holder {
  max-width: max-content;
}

.tr_salient_features_content_holder h3 {
  font-size: var(--h4);
  font-weight: var(--font-weight-bold);
  margin-bottom: 1rem !important;
}

.tr_salient_features_content_holder p {
  font-size: var(--body18);
  max-width: 34rem;
}

.tr_salient_features_container {
  gap: 8rem;
  margin-bottom: 7rem;
}



/* Faq  */
.tr_faq {
  margin: 0rem auto 3.5rem auto !important;
  max-width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tr_faq .border-content {
  height: 0.25rem;
  width: 8rem;
  margin-top: 0.30rem;
  background-color: var(--primary-color);
}

.tr_faq h2 {
  font-size: var(--h3);
  font-weight: var(--font-weight-bold);
}


.tr__faq_main_wrapper .accordion {
  margin-bottom: 7rem;
}

.tr__faq_main_wrapper .accordion-item {
  margin-bottom: 1.5rem;
}

.tr__faq_main_wrapper .accordion-body {
  padding: 0.70rem 1rem;
}

.tr__faq_main_wrapper .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.tr__faq_main_wrapper .accordion-header button {
  font-weight: var(--font-weight-bold);
  font-size: var(--body18) !important;
}


/* responsive */
@media (max-width: 1199px) {
  .tr__diamond_img_wrapper {
    max-width: 450px;
  }

  .tr__for_buyer_content_wrapper {
    width: 46%;
  }

  .tr_salient_features_container {
    gap: 4rem;
  }

  .tr__silent_img_wrapper {
    height: 20.125rem;
    width: 100%;
    max-width: 20.125rem;
  }

  .tr_salient_features_content_holder {
    max-width: 25rem;
    min-width: 20rem;
  }

  .tr_sailent_heading {
    margin: 4rem auto !important;
  }

  .tr_salient_features_container {
    margin-bottom: 4rem;
  }

  .tr_faq {
    margin: 5rem auto 3.5rem auto !important;
  }
}

@media (max-width: 991px) {
  .tr__diamond_img_wrapper {
    max-width: 320px;
  }

  .tr__for_buyer_content_wrapper p {
    font-size: var(--body14);
  }

  .tr__for_buyer_bg_main {
    height: auto;
    padding: 7rem 0rem;
  }

  .tr_salient_features_container {
    gap: 2rem;
  }

  .tr__faq_main_wrapper.accordion {
    margin-bottom: 4rem;
  }
}

@media (max-width: 767px) {

  .tr__for_buyer_bg_content {
    flex-direction: column;
    align-items: start;
  }

  .tr__for_buyer_content_wrapper {
    width: 100%;
  }

  .tr__for_buyer_bg_content {
    gap: 2rem;
  }

  .tr_salient_features_container {
    gap: 2rem;
  }
}

@media (max-width: 575px) {
  .tr__for_buyer_content_wrapper {
    width: 90%;
  }

  .tr__for_buyer_bg_main {
    height: auto;
    padding: 5rem 0rem;
  }

  .tr_salient_features_container {
    padding: 0rem 0.80rem;
  }
}

@media (max-width: 425px) {
  .tr__for_buyer_content_wrapper {
    text-align: center;
    margin: auto;
  }

  .tr__for_buyer_bg_content {
    align-items: center;
  }

  .tr_salient_features_content_holder {
    min-width: auto;
  }

  .tr__silent_img_wrapper {
    width: 18rem;
    height: 18rem;
  }

  .tr_sailent_heading {
    margin: 3rem auto !important;
  }

  .tr_salient_features_content_holder h3,
  .tr_salient_features_content_holder p {
    text-align: center;
  }

  .tr_faq {
    margin: 3rem auto 3.5rem auto !important;
  }

}

/* for-buyer pages end */


/* ForSupplier page*/
.tr__onestop_wrapper_img img {
  max-width: 18rem;
  width: 100%;
  margin: auto;
  display: flex;
}

.tr__how_does_work_bg {
  background-color: rgba(188, 146, 63, 0.2);
  background-image: var(--linear-gradient);
  padding: 7rem 0;
  margin-bottom: 7rem;
}

.tr__work_main_content {
  text-align: center;
  margin-bottom: 4rem;
}

.tr__work_main_content h3 {
  font-size: var(--h3);
  font-weight: var(--font-weight-bold);
}

.tr__work_main_content p {
  font-size: var(--body20);
  font-weight: var(--font-weight-medium);
}

.tr__works_card_main_wrapper {
  border: 1px solid var(--primary-color);
  padding: 2rem 2.5rem;
  margin: 0rem 1rem;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.tr__works_card_main_wrapper h5 {
  font-size: var(--body20);
  color: var(--dark-color);
  font-weight: var(--font-weight-bold);
  max-width: 16rem;
  min-height: 3rem;
  width: 100%;
  margin: 1.5rem 0 0.70rem 0 !important;
}

.tr__works_card_main_wrapper p {
  font-size: var(--body18);
}

.tr__works_images_wrapper {
  max-width: 8rem;
  width: 100%;
  height: 8rem;
}

.tr__works_images_wrapper img {
  width: 100%;
  height: 100%;
}

.tr__buyer_acc.accordion p {
  padding: 0 1.25rem;
}


/* responsive */
@media (max-width: 1199px) {
  .tr__onestop_wrapper_img img {
    max-width: 12rem;
  }

  .tr__works_card_main_wrapper {
    padding: 1rem 1.5rem;
    margin: 0rem 0.50rem;
  }

  .tr__works_images_wrapper {
    max-width: 7rem;
    height: 7rem;
  }

  .tr__works_card_main_wrapper p {
    font-size: var(--body14);
  }

  .tr__how_does_work_bg {
    padding: 4rem 0;
  }

  .tr__work_main_content {
    margin-bottom: 3rem;
  }
}

@media (max-width: 991px) {
  .tr__works_card_main_wrapper {
    margin: 0;
    padding: 1rem 1rem;
  }

  .tr__works_card_main_wrapper p {
    font-size: var(--body12);
  }

  .tr__how_does_work_bg .row {
    gap: 0.50rem;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .tr__how_does_work_bg .row {
    padding: 0 1rem;
    gap: 1rem;
  }

  .tr__how_does_work_bg {
    margin-bottom: 5rem;
  }

}

@media (max-width: 575px) {
  .tr__works_card_main_wrapper h5 {
    margin: 1.5rem 0 0.60rem 0 !important;
    min-height: auto;
    max-width: 13rem;
  }

  .tr__works_card_main_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .tr__works_images_wrapper {
    width: 5rem;
    height: 5rem;
  }

}

/* 25-7-2024 */

.tr__logout_modal.modal .modal-dialog {
  max-width: 450px;
}

section.stone_details .fill_btn {
  padding: 0.5rem 0.75rem;
}

.diamondList_container .option_container img {
  width: 22px;
  height: 22px;
}

.tr__newStoneDetails_table .notGraded_table {
  border-radius: 5px;
  overflow: hidden;
}

.tradeCenter_price p div {
  margin-bottom: 0.313rem;
}

.tradeCenter_location span {
  font-size: var(--body14);
  line-height: 1.4;
  color: var(--black-color);
}

.drag_calc .tr__calcacc .accordion-body h5 {
  font-size: 14px;
  line-height: 1.4;
}

.tr__profile {
  padding: 0.625rem 0;
}

.tr__profile_id p {
  font-size: var(--body14);
  line-height: 1.4;
}

.editCompany_popup.modal.modal-header {
  padding: 1.875rem 0 0px;
  border-bottom: none;
}

.editCompany_popup .modal-body {
  padding: 30px;
}

.editCompany_popup .modal-footer {
  border-top: none;
  padding: 0;
}

/* 27-6-2024 */

.plan_details table th:nth-child(1) {
  min-width: 160px;
  width: 160px;
  max-width: 10%;
}

.plan_details table th:nth-child(2),
.plan_details table th:nth-child(3),
.plan_details table th:nth-child(4),
.plan_details table th:nth-child(5) {
  table-layout: fixed;
  min-width: 283.5px;
  max-width: 283.5px;
}

.tr__trackDiamond_Result p:nth-child(2) {
  border-left: 1px solid var(--stroke-color);
}

.edittrack_modal .modal-dialog,
.track_deleteModal .modal-dialog {
  max-width: 550px;
}

/* .edittrack_modal.modal input {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}

.edittrack_modal.modal input:focus{
  border-bottom: 1px solid var(--primary-color) !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
} */

.edittrack_modal .modal-body,
.edittrack_modal .modal-footer,
.track_deleteModal .modal-body,
.track_deleteModal .modal-footer {
  padding: 0 1.875rem 1.875rem;
}

/* 29-7-2024 */

.tr__searchStock .search_media label.media_label {
  font-size: var(--body12);
  line-height: 1.4;
}

.rapaPort_tabs_item:first-child {
  padding-left: 0;
}

.tr__rapaPort_Calculator .table_data {
  padding-top: 1.25rem;
}

.tr__rapaPort_Calculator .dataTable {
  border-radius: var(--radius-border);
  overflow: hidden;
}

.tr__rapaPort_Calculator .dataTable img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* /* share diamond */
.tr__shareDiamond .tr__shareDiamond_title {
  background: var(--primary-color);
  padding: 1.25rem;
  /* font-size: 16px; */
}

.tr__shareDiamond .tr__shareDiamond_title h1 {
  font-size: var(--h5);
  line-height: 1.4;
  color: var(--white-color);
}

.tr__shareDiamond .tr__shareDiamond_list {
  display: flex;
  gap: 100px;
  align-items: center;
  margin-bottom: 0.313rem;
}

.tr__shareDiamond .info {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.313rem;
}

.tr__shareDiamond_con {
  padding: 3.125rem 0;
}

.tr__shareDiamond_datawrap h5 {
  font-size: var(--h4);
  line-height: 1.4;
  margin-bottom: 1.25rem !important;
}

.tr__shareDiamond_list h6 {
  font-size: var(--body16);
  line-height: 1.4;
  font-weight: var(--font-weight-smedium);
}

.tr__shareDiamond_list p {
  font-size: var(--body16);
  line-height: 1.4;
}

.tr__shareDiamond_expire {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 10px 0;
}

.tr__shareDiamond_expire p {
  font-size: var(--body14);
  line-height: 1.4;
  opacity: 0.8;
}

.tr__shareDiamond_det {
  padding: 1.875rem 0;
}

.tr__shareDiamond_media h6 {
  font-size: var(--body16);
  line-height: 1.4;
}

.tr__shareDiamond_media p {
  font-size: var(--body14);
  line-height: 1.4;
}

.tr__shareDiamond_info h5,
.tr__shareDiamond_add h5,
.tr__shareDiamond_mes h5,
.tr__shareDiamond_key h5 {
  font-size: var(--body18);
  line-height: 1.4;
  font-weight: var(--font-weight-smedium);
}

.tr__shareDiamond_info .info h6,
.tr__shareDiamond_add h6,
.tr__shareDiamond_mes h6,
.tr__shareDiamond_key h6 {
  font-size: 16px;
  line-height: 1.4;
  opacity: 0.8;
}

.tr__shareDiamond_info .info p,
.tr__shareDiamond_add .info p,
.tr__shareDiamond_mes .info p,
.tr__shareDiamond_key .info p {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 600;
}

.tr__shareDiamond_info,
.tr__shareDiamond_add,
.tr__shareDiamond_mes,
.tr__shareDiamond_key {
  margin-bottom: 1.875rem;
}

.tr__shareDiamond_pro .pro_img {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 50%; */
}

.pro_img img {
  border-radius: 50%;
  max-width: 100%;
}

.tr__shareDiamond_prodet h5 {
  text-align: center;
}

.tr__shareDiamond_prodet .userName {
  text-align: center;
  padding: 0.938rem;
  font-size: var(--body18);
  line-height: 1.4;
  font-weight: var(--font-weight-bold);
}

.tr__shareDiamond_pro {
  padding: 3.125rem 1.875rem;
}

.tr__shareDiamond_prodet {
  padding: 1.25rem;
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1); */
  border: 1px solid var(--stroke-color);
  border-radius: var(--radius-border);
  background-color: var(--white-color);
}

.tr__shareDiamond_prodet p.sellerDet {
  font-size: var(--body14);
  line-height: 1.4;
  font-weight: var(--font-weight-medium);
}

.tr__shareDiamond_data {
  background: var(--page-color);
}

.tr__shareDiamond_form input[type="text"],
.tr__shareDiamond_form textarea {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--stroke-color);
  background: transparent;
  margin-bottom: 1.25rem;
  width: 100%;
}

.tr__shareDiamond_form {
  padding: 1.25rem;
  background-color: var(--white-color);
  border-radius: var(--radius-border);
  border: 1px solid var(--stroke-color);
  margin-top: 1.25rem;
}

.tr__shareDiamond_form h5 {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 1.25rem !important;
}

.tr__shareDiamond_form input::placeholder,
.tr__shareDiamond_form ::placeholder {
  font-size: var(--body14);
  line-height: 1.4;
  padding-left: 0.313rem;
}

.tr__shareDiamond_form textarea:focus-visible {
  outline: 0;
}

.tr__shareDiamond_form button.fill_btn.w-100 {
  padding: 0.313rem;
}

/* 30-7-2024 */

.buyRequest_modal .buyRequest_btns {
  display: flex;
  gap: 0.313rem;
  flex-wrap: wrap;
}

.buyRequest_modal .form-control {
  line-height: 1.4;
}

.buyRequest_modal .offcanvas-header {
  padding: 1.875rem;
}

@media (max-width: 767px) {

  .buyRequest_btns .cancelBuy_btn,
  .buyRequest_btns .saveBuy_btn {
    width: 100%;
  }
}

.compareDimond_list_item .delete_com img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}


.tr__rapaPort_price .accordion-button::after {
  margin-right: 0.625rem;
}

/* 31-7-2024 */

.tr__pair_table .odd-row {
  background-color: var(--light-color);
}

.tr__pair_table .even-row {
  background-color: var(--white-color);
}

.table_customfilter .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.438rem 1.875rem !important;
}

.tr__pair_table table th,
.tr__pair_table table tbody td {
  color: var(--black-color);
}


.tr__lostConnection .tr__lostConnection_btn .fill_btn {
  width: 104px;
  font-size: var(--body14);
  line-height: 1.4;
  padding: 0.5rem;
}

.editCompany_popup .check_text {
  color: var(--black-color);
}

/* 1-8-2024 */

.tr__uploadDiamond .label_text p {
  margin-bottom: 0.375rem !important;
}

.tr__uploadDiamond_multiple_con .check_text {
  color: var(--black-color);
}

.tr__uploadDiamond_multiple_con:first-child {
  padding-top: 0;
}

ul.tr__uploadDiamond_list li {
  /* padding-left: 0; */
  padding: 0 1.063rem;
}

.tr__uploadDiamond_pendingVer a.result_delete img {
  width: 16px;
  height: 16px;
}

.tr__delete_modal .modal-dialog {
  max-width: 550px;
}

.price_dis .row .col-12:last-child .price_dis_list {
  /* background: red; */
  border-right: none !important;
}

/* 5-8-2024 */

.editCompany_popup .check1 {
  gap: 1.25rem;
}

.editCompany_popup .modal-header {
  padding: 1.875rem 1.875rem 0;
  border-bottom: none;
}


@media (max-width: 767px) {
  .fancy_det .color_title {
    margin-top: 0.625rem;
  }

  .tr__sidebar_menu>li {
    margin-bottom: 0.625rem;
  }

  .tr__footer_bottomlinks ul {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.625rem;
  }

  .tr__footer_bottom {
    justify-content: center;
    gap: 0.625rem;
  }

  .tr__form-group label {
    font-size: var(--body14);
  }

  .tr__herosec_con h1 {
    font-size: var(--h2);
  }

  .navbar_web a.tr__logo img {
    width: 75%;
  }

  .navbar_web.navbar .navbar-toggler img {
    width: 17px;
    height: 17px;
  }

  .tr__menulist a {
    padding: 0.625rem 1rem !important;
  }

  .buyRequest_modal .media_column,
  .buyRequest_modal .search_price .price_dis_list {
    border-right: none !important;
  }
}


.tr__rapaPort_Calculator .tr__rapaPort_shape_list .label {
  margin-bottom: 0.375rem;
}

.tr__rapaPort_table .dataTable .MuiDataGrid-columnHeaderTitle.css-mh3zap {
  color: var(--black-color);
}

/* 6-8-2024 */

.MuiDataGrid-columnHeaderTitle {
  color: var(--black-color) !important;
}

.MuiTypography-root {
  color: var(--black-color) !important;
}


.MuiDataGrid-cell {
  color: var(--black-color) !important;
}


/* 9-8-2024 */

.certificate_btn {
  padding: 0.5rem;
  border: 1px solid var(--stroke-color);
  border-radius: var(--radius-border);
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.certificate_btn img {
  width: 20px;
  height: 20px;
}

.tr__certidow h1 {
  font-size: var(--h5);
  font-weight: var(--font-weight-bold);
  line-height: 1.5;
  color: var(--black-color);
  font-family: var(--secondry-font);
  padding-bottom: 0.938rem;
  border-bottom: 1px solid var(--stroke-color);
}

.tr__certidow .select_item.mb-3 {
  max-width: 40%;
  /* min-width: 100%; */
  width: 100%;
  margin-top: 1.25rem;
}

.tr__certidow_history p {
  font-size: var(--body14);
  line-height: 1.4;
}

.tr__certidow_history img {
  width: 1.25rem;
  height: 1.25rem;
}

/* .tr__certidow_history {
  max-width: 640px;
  justify-content: space-between;
} */

@media (max-width: 767px) {
  .tr__certidow .select_item.mb-3 {
    min-width: 100%;
  }


}


/* 16-8-2024 */
.uplode_item .cart li:hover {
  background-color: var(--primary-color);
}

.uplode_item .cart li:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(56deg) brightness(105%) contrast(102%);
}

.tr__thanku.modal {
  z-index: 11111;
}

.tr__thanku h1 {
  font-size: var(--h3);
  line-height: 1.4;
  font-weight: var(--font-weight-bold);
}

.tr__thanku.modal.show .modal-dialog {
  max-width: 560px;
  /* width: 100%; */
}

/* 20-8-2024 */

.Gridle_culet .Gridle_culet_list li:hover p,
.advance_Search .advance_Search_list li:hover p,
.carat_weight .from_to_range_item li:hover p,
.color_con .fancy_det_list li:hover p,
.filter_modal .search_clarity_list li:hover p,
.grading_report .grading_report_list li:hover p,
.measure_list_item li:hover p,
.search_Fluorescence .search_Fluorescence_list li:hover p,
.search_clarity .search_clarity_list li:hover p,
.search_finish .search_finish_list li:hover p,
.search_media .search_media_list li:hover p,
.tr__uploadDiamond_single .uploadDiamond_list li:hover p,
.treatment .treatment_list li:hover p {
  color: var(--white-color);
}

.from_to_range_item li.active p,
.fancy_det_list li.active p,
.search_clarity_list li.active p,
.filter_modal .search_clarity_list li.active p,
.search_finish_list li.active p,
.search_Fluorescence_list li.active p,
.grading_report_list li.active p,
.search_media_list li.active p,
.measure_list_item li.active p,
.Gridle_culet_list li.active p,
.treatment_list li.active p,
.advance_Search_list li.active p,
.buyRequest_modal .measure_list_item li.active p,
.tr__uploadDiamond_single .measure_list_item li.active p {
  color: var(--white-color);
}


/*22 - 08 - 2024  */
.certificate-dow-container {
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
}

.certificate-dow-wrap {
  max-width: 600px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.certificate-dow-title h1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.certificate-dow-search {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search-input {
  flex: 1;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.search-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.certificate-dow-history {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
}

.history-title {
  gap: 10px;
  margin-bottom: 10px;
}

.history-stats {
  display: flex;
  gap: 20px;
}

/*22 - 08 - 2024  */

/* 29-8-24 */
/* for ftppasword style */

/* Container for the password field and buttons */
.generated_password .password_field {
  display: flex;
  align-items: center;
  gap: 8px;
  /* Space between input and buttons */
}

/* Eye and Copy Button styling */
.generated_password .password_toggle,
.generated_password .password_copy {
  background-color: #f0f0f0;
  /* Light background color */
  border: 1px solid #ccc;
  /* Light border */
  border-radius: 4px;
  /* Rounded corners */
  padding: 5px;
  /* Padding for button size */
  cursor: pointer;
  /* Pointer cursor on hover */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  /* Width of buttons */
  height: 30px;
  /* Height of buttons */
  transition: background-color 0.2s;
  /* Smooth transition for hover effect */
}

/* Hover effect for buttons */
.generated_password .password_toggle:hover,
.generated_password .password_copy:hover {
  background-color: #e0e0e0;
  /* Slightly darker on hover */
}

/* Icon size within the buttons */
.generated_password .password_toggle svg,
.generated_password .password_copy svg {
  width: 16px;
  /* Icon width */
  height: 16px;
  /* Icon height */
  color: #333;
  /* Icon color */
}
 /* Chat-Page css Date : 03-10-24  */

   /*--------------------------------------------------------------
  # Main
  --------------------------------------------------------------*/
  #main {
    /* margin-top: 66px; */
    padding: 20px 30px;
    transition: all 0.3s;
  }
  
  @media (max-width: 1199px) {
    #main {
      padding: 20px;
    }
  }
  /* @media (min-width: 1200px) {
  
    #main,
    #footer {
      margin-left: 250px;
    }
  } */

  .fixed-bottom-chatInput {
    position: fixed;
    bottom: 0;
    z-index: 1030;
    width: -webkit-fill-available;
  }


  .tr_aboutus_count_h1 h2 {
    background: #f1f1f1;
    padding: 15px;
    border-radius: 50%;
    border: 1px solid #bc923f;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    top: -13px;
}

.tr_aboutus_counts_main:last-child .tr_aboutus_count_h1::after{
    content: none;
}

/* 03 - 02 - 2025 */
.con_drop.small_hideCon .con_name{
  font-size: 0 !important;
}

.con_drop.dropdown-item{
  padding: 0.625rem;
}
