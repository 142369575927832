.tr__diamondLoader_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  gap: 80px;
}

.tr__diamond {
  display: block;
  width: 200px;
  height: auto;
  position: relative;
}

.tr__diamond li {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  opacity: 0;
  z-index: 100;
  transition: 400ms ease;
}

.tr__diamond li:nth-child(1) {
  border-width: 20px 20px 0 20px;
  border-color: var(--primary-color) transparent transparent transparent;
  left: 0;
  right: 0;
  margin: auto;
  animation: traingle1 200ms 2.2s ease, opacity 2s 2.4s ease infinite;
  animation-fill-mode: forwards;
}

.tr__diamond li:nth-child(2) {
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent var(--primary-color);
  left: 59px;
  animation: traingle2 200ms 1.8s ease, opacity 2s 2.4s ease infinite;
  animation-fill-mode: forwards;
}

.tr__diamond li:nth-child(3) {
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent var(--primary-color);
  right: 59px;
  animation: traingle3 200ms 2s ease, opacity 2s 2.4s ease infinite;
  animation-fill-mode: forwards;
}

.tr__diamond li:nth-child(4) {
  border-width: 0 20px 20px 20px;
  border-width: 20px 20px 0 20px;
  border-color: var(--primary-color) transparent transparent transparent;
  animation: traingle4 200ms 1.6s ease, opacity 2s 2.6s ease infinite;
  animation-fill-mode: forwards;
}

.tr__diamond li:nth-child(5) {
  border-width: 0 20px 20px 20px;
  border-width: 20px 20px 0 20px;
  border-color: var(--primary-color) transparent transparent transparent;
  animation: traingle5 200ms 1.4s ease, opacity 2s 2.6s ease infinite;
  animation-fill-mode: forwards;
}

.tr__diamond li:nth-child(6) {
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent var(--primary-color);
  left: 0;
  right: 0;
  margin: auto;
  animation: traingle6 200ms 1.2s ease, opacity 2s 2.6s ease infinite;
  animation-fill-mode: forwards;
}

.tr__diamond li:nth-child(7) {
  border-width: 20px 20px 0 20px;
  border-color: var(--primary-color) transparent transparent transparent;
  left: 0;
  right: 0;
  margin: auto;
  animation: traingle7 200ms 1s ease, opacity 2s 2.8s ease infinite;
  animation-fill-mode: forwards;
}

.tr__loaderCon {
  /* margin-top: -500px; */
  text-align: center;
  line-height: 3.125;
  font-size: 30px;
  color: var(--primary-color);
  opacity: 0;
  animation: text 500ms 2.4s ease;
  animation-fill-mode: forwards;
  transform: translateY(-110px);

}

@keyframes traingle1 {
  0% {
    top: -50px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes traingle2 {
  0% {
    top: -50px;
    opacity: 0;
  }
  100% {
    top: 1px;
    opacity: 1;
  }
}

@keyframes traingle3 {
  0% {
    top: -50px;
    opacity: 0;
  }
  100% {
    top: 1px;
    opacity: 1;
  }
}

@keyframes traingle4 {
  0% {
    right: 59px;
    top: -33px;
    opacity: 0;
  }
  100% {
    right: 59px;
    top: 22px;
    opacity: 1;
  }
}

@keyframes traingle5 {
  0% {
    left: 59px;
    top: -33px;
    opacity: 0;
  }
  100% {
    left: 59px;
    top: 22px;
    opacity: 1;
  }
}

@keyframes traingle6 {
  0% {
    top: -33px;
    opacity: 0;
  }
  100% {
    top: 23px;
    opacity: 1;
  }
}

@keyframes traingle7 {
  0% {
    top: -10px;
    opacity: 0;
  }
  100% {
    top: 44px;
    opacity: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes text {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    /* margin-top: 0px;s */
    opacity: 1;
    transform: translateY(0);
  }
}
.diamondCon .diamond{
  margin: 0;
  padding: 0;
  list-style: none;
}

